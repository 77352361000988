import React, { useEffect, useState } from "react";
import { Button, Upload, Modal, Alert, Typography, Space, Spin } from "antd";
import {
    InboxOutlined,
    LoadingOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import "./FileUploadModal.less";
import heic2any from "heic2any";
import {
    customerFileTypesToAcceptForExplorer,
    customerFileTypesToAcceptText,
    operatorFileTypesToAcceptForExplorer,
    operatorFileTypesToAcceptText,
} from "@Utilities/UploadFileTypes";
import {
    isValidFileNameLength,
    getSafeLengthFileName,
} from "@Utilities/FileUpload";
import {
    fileUploadDefinitions,
    FileTypeReadBufferLength,
    FileTypeOffset,
} from "@Utilities/FileUploadDefinitions";

const { Title, Text } = Typography;
const { Dragger } = Upload;

interface FileUploadModalProps {
    isVisible: boolean;
    setIsModalVisible: (isVisible: boolean) => void;
    windowWidth: number;
    chatId: string;
    isOperator: boolean;
    sendFileAsUser?: (chatId: string, data: FormData) => Promise<void>;
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({
    isVisible,
    setIsModalVisible,
    windowWidth,
    chatId,
    isOperator,
    sendFileAsUser,
}) => {
    const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);
    const [uploadFileBlobs, setUploadFileBlobs] = useState<Blob[]>([]);
    const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
    const [isModalSubmitting, setIsModalSubmitting] = useState<boolean>(false);

    const fileTypesToAccept = isOperator
        ? operatorFileTypesToAcceptForExplorer
        : customerFileTypesToAcceptForExplorer;
    const fileTypeText = isOperator
        ? `${operatorFileTypesToAcceptText} files,`
        : `${customerFileTypesToAcceptText} files,`;
    const fileRelatedNoun = isOperator ? "File(s)" : "Image(s)";

    const maxConcurrentFileUploads = 10;
    const maxConcurrentFileUploadsErrorMessage = `A maximum of ${maxConcurrentFileUploads} images can be sent at one time`;

    // Accessibility ask - remove title attribute from 'remove file' buttons in default Upload AntD Component
    useEffect(() => {
        const buttons = document.querySelectorAll(
            // eslint-disable-next-line quotes
            'button[title="Remove file"].ant-upload-list-item-card-actions-btn'
        );
        buttons.forEach((button) => {
            button.removeAttribute("title");
        });
    }, [uploadFileList]);

    useEffect(() => {
        const handleKeyDown = (event: any): void => {
            if (event.key === "Space" || event.key === " ") {
                event.preventDefault();
                const input: HTMLInputElement | null = document.querySelector(
                    ".ant-upload.ant-upload-drag.photo-modal__dragger input[type='file']"
                );
                if (input) {
                    input.click();
                }
            }
        };
        let div: Element | undefined | null;
        if (isVisible && !div) {
            div = document.querySelector<HTMLDivElement>(
                ".ant-upload.ant-upload-drag.photo-modal__dragger"
            );
            if (div) {
                div.addEventListener("keydown", handleKeyDown);
            }
        }
        return () => {
            if (div) {
                div.removeEventListener("keydown", handleKeyDown);
            }
        };
    }, [isVisible]);

    const closeAndClearPhotoModal = (): void => {
        setIsModalVisible(false);
        setUploadFileList([]);
        setUploadFileBlobs([]);
    };

    const sendFiles = (files: Blob[]): Promise<void> => {
        if (!sendFileAsUser) {
            console.error(
                "No callback configured to send files, either provide a callback or disable the file upload button"
            );
            return Promise.resolve();
        }

        setIsModalSubmitting(true);
        const data = new FormData();

        files.forEach((file) => data.append("formFile", file));

        return sendFileAsUser(chatId, data);
    };

    const readBuffer = (
        file: RcFile,
        end: number,
        start = 0
    ): Promise<ArrayBuffer> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (): void => {
                resolve(reader.result as ArrayBuffer);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file.slice(start, end));
        });
    };

    const readFileAsText = (file: RcFile): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (): void => {
                resolve(reader.result as string);
            };
            reader.onerror = reject;
            reader.readAsText(file.slice(0, 500));
        });
    };

    const check = (headers: number[]) => {
        return (buffers: Uint8Array, offset = 0) =>
            headers.every(
                (header, index) => header === buffers[offset + index]
            );
    };

    const checkMulti = (headersArray: number[][]) => {
        return (buffers: Uint8Array, offset = 4) =>
            headersArray.some((header) =>
                header.every(
                    (header, index) => header === buffers[offset + index]
                )
            );
    };

    function isValidFileContent(
        magicNumber: any,
        buffers: Uint8Array,
        offset: number
    ): boolean {
        let checkFunction: (buffers: Uint8Array, offset: number) => boolean;

        if (
            Array.isArray(magicNumber) &&
            magicNumber.every((item) => typeof item === "number")
        ) {
            checkFunction = check(magicNumber as number[]);
        } else if (
            Array.isArray(magicNumber) &&
            magicNumber.every(
                (item) =>
                    Array.isArray(item) &&
                    item.every((subItem) => typeof subItem === "number")
            )
        ) {
            checkFunction = checkMulti(magicNumber as number[][]);
        } else {
            console.log("Invalid file upload magic number type:", magicNumber);
            throw new Error("Invalid file upload magic number type."); // Should never happen.
        }

        return checkFunction(buffers, offset);
    }

    const isText = (content: string): boolean => {
        // The regular expression [^\x00-\x7F] looks for any non-ASCII characters.
        // eslint-disable-next-line no-control-regex
        const nonTextRegex = /[^\x00-\x7F]/; //NOSONAR
        return !nonTextRegex.test(content);
    };

    const handleFileContentValidation = async (
        file: RcFile,
        readBufferLength: number,
        magicNumber: any,
        readOffset: number
    ): Promise<boolean> => {
        const buffers = await readBuffer(file, readBufferLength);
        const contentUint8Array = new Uint8Array(buffers);
        return isValidFileContent(magicNumber, contentUint8Array, readOffset);
    };

    const isValidUploadFile = async (
        file: RcFile,
        pendingFileList: RcFile[]
    ): Promise<boolean> => {
        let isFileValid = false;
        let alertMessage = isOperator
            ? fileUploadDefinitions.invalidFileTypeErrorMessageForOperators
            : fileUploadDefinitions.invalidFileTypeErrorMessageForCustomers;
        const allowedFileTypes = isOperator
            ? operatorFileTypesToAcceptForExplorer.split(", ")
            : customerFileTypesToAcceptForExplorer.split(", ");
        const fileName = file.name.toLowerCase();
        const fileExtension = fileName.substring(
            fileName.lastIndexOf("."),
            fileName.length
        );
        // Note the way our logic is set up here, files to upload must match between the file name extension and the content's "magic number".
        // For example, we will reject as invalid a file with a .jpg extension that has a .png magic number in its content, even though we accept png files.
        if (allowedFileTypes.includes(fileExtension)) {
            switch (fileExtension) {
                case ".jpg":
                case ".jpeg": {
                    isFileValid = await handleFileContentValidation(
                        file,
                        FileTypeReadBufferLength.JPG,
                        fileUploadDefinitions.magicNumberJPG,
                        FileTypeOffset.JPG
                    );
                    break;
                }
                case ".png": {
                    isFileValid = await handleFileContentValidation(
                        file,
                        FileTypeReadBufferLength.PNG,
                        fileUploadDefinitions.magicNumberPNG,
                        FileTypeOffset.PNG
                    );
                    break;
                }
                case ".heic": {
                    isFileValid = await handleFileContentValidation(
                        file,
                        FileTypeReadBufferLength.HEIC,
                        fileUploadDefinitions.magicNumbersHEIC,
                        FileTypeOffset.HEIC
                    );
                    break;
                }
                case ".pdf": {
                    isFileValid = await handleFileContentValidation(
                        file,
                        FileTypeReadBufferLength.PDF,
                        fileUploadDefinitions.magicNumberPDF,
                        FileTypeOffset.PDF
                    );
                    break;
                }
                case ".mp4": {
                    isFileValid = await handleFileContentValidation(
                        file,
                        FileTypeReadBufferLength.MP4,
                        fileUploadDefinitions.magicNumbersMP4,
                        FileTypeOffset.MP4
                    );
                    break;
                }
                case ".doc": {
                    isFileValid = await handleFileContentValidation(
                        file,
                        FileTypeReadBufferLength.DOC,
                        fileUploadDefinitions.magicNumberDOC,
                        FileTypeOffset.DOC
                    );
                    break;
                }
                case ".xls": {
                    isFileValid = await handleFileContentValidation(
                        file,
                        FileTypeReadBufferLength.XLS,
                        fileUploadDefinitions.magicNumberXLS,
                        FileTypeOffset.XLS
                    );
                    break;
                }
                case ".docx": {
                    isFileValid = await handleFileContentValidation(
                        file,
                        FileTypeReadBufferLength.DOCX,
                        fileUploadDefinitions.magicNumberDOCX,
                        FileTypeOffset.DOCX
                    );
                    break;
                }
                case ".xlsx": {
                    isFileValid = await handleFileContentValidation(
                        file,
                        FileTypeReadBufferLength.XLSX,
                        fileUploadDefinitions.magicNumberXLSX,
                        FileTypeOffset.XLSX
                    );
                    break;
                }
                case ".txt":
                case ".csv": {
                    const txtContent = await readFileAsText(file);
                    isFileValid = isText(txtContent);
                    break;
                }
                default:
                    break;
            }

            if (isFileValid) {
                // AppSec suggests we limit file size to 20MB.
                if (file.size > fileUploadDefinitions.maximumFileSize) {
                    isFileValid = false;
                    alertMessage =
                        fileUploadDefinitions.maximumFileSizeErrorMessage;
                }

                const fileIndex = pendingFileList.findIndex(
                    (pendingFile) => pendingFile.uid === file.uid
                );

                if (
                    !isOperator &&
                    uploadFileList.length + fileIndex + 1 >
                        maxConcurrentFileUploads
                ) {
                    isFileValid = false;
                    alertMessage = maxConcurrentFileUploadsErrorMessage;
                    // hide the alert message if we have already displayed it once on this attempt
                    if (
                        uploadFileList.length +
                            fileIndex +
                            1 -
                            maxConcurrentFileUploads >
                        1
                    ) {
                        alertMessage = "";
                    }
                }
            }
        }

        if (!isFileValid && alertMessage) {
            alert(alertMessage);
        }
        return isFileValid;
    };

    const createNewFileWithShortenedName = (file: RcFile): File => {
        const newFile = new File(
            [file.slice()],
            getSafeLengthFileName(file.name, uploadFileList),
            { type: file.type }
        );
        return newFile;
    };

    return (
        <Modal
            className="photo-modal"
            open={isVisible}
            width={windowWidth < 900 ? "90%" : 752}
            onCancel={(): void => setIsModalVisible(false)}
            centered={true}
            title={
                <>
                    <Title level={4}>
                        {`Upload and Send ${fileRelatedNoun}`}
                    </Title>
                    {isOperator ? null : (
                        <Alert
                            showIcon
                            type="warning"
                            message="Warning: Please do not upload images of your full credit card number."
                        />
                    )}
                </>
            }
            footer={
                <Space>
                    <Button
                        className="photo-modal-footer__button-outlined"
                        onClick={closeAndClearPhotoModal}
                        disabled={isModalSubmitting}
                    >
                        Cancel
                    </Button>
                    <Button
                        loading={isModalSubmitting}
                        type="primary"
                        disabled={
                            uploadFileList.length < 1 || isModalSubmitting
                        }
                        onClick={(): Promise<void> => {
                            return sendFiles(uploadFileBlobs)
                                .then(() => closeAndClearPhotoModal())
                                .catch((error) => {
                                    let errorMessage = "";

                                    if (error?.response?.data?.detail) {
                                        alert(error.response.data.detail);
                                    } else if (error?.response?.data?.errors) {
                                        const errorDestails =
                                            error?.response?.data.errors ?? {};
                                        const errorDetailKeys = Object.keys(
                                            error.response.data.errors
                                        );
                                        errorDetailKeys.forEach((key) => {
                                            errorMessage += `${errorDestails[
                                                key
                                            ].join("\r\n")}`;
                                        });

                                        alert(errorMessage);
                                    } else {
                                        alert(
                                            "An unexpected error has occurred while uploading your file(s)"
                                        );
                                    }
                                })
                                .finally(() => {
                                    setIsModalSubmitting(false);
                                });
                        }}
                    >
                        {`Send ${fileRelatedNoun}`}
                    </Button>
                </Space>
            }
        >
            <Dragger
                className="photo-modal__dragger"
                beforeUpload={(
                    file,
                    pendingFileList
                ):
                    | boolean
                    | Promise<File>
                    | Promise<RcFile>
                    | string
                    | Promise<boolean>
                    | Promise<string | File>
                    | Promise<string | boolean> => {
                    setIsFileLoading(true);
                    return isValidUploadFile(file, pendingFileList)
                        .then((isValid): any => {
                            setIsFileLoading(false);
                            if (isValid) {
                                if (file.name.indexOf(".heic") >= 0) {
                                    const fileWithValidatedLength =
                                        !isValidFileNameLength(file.name)
                                            ? createNewFileWithShortenedName(
                                                  file
                                              )
                                            : file;
                                    return heic2any({
                                        blob: fileWithValidatedLength,
                                        toType: "image/jpeg",
                                        quality: 0.5,
                                    }).then(
                                        (fileBlobOrArray) => {
                                            const fileBlob =
                                                fileBlobOrArray as Blob;
                                            // If we just tack on .jpg below, the file name will end in .heic.jpg, which will blow chunks on the put
                                            // to min.io, complaining about invalid characters. So we strip off the .heic and replace it with .jpg.
                                            const fileNameWithoutExtension =
                                                file.name.substring(
                                                    0,
                                                    file.name.lastIndexOf(
                                                        ".heic"
                                                    )
                                                );
                                            const newFile = new File(
                                                [fileBlob],
                                                fileNameWithoutExtension +
                                                    ".jpg",
                                                { type: "image/jpeg" }
                                            );
                                            return newFile;
                                        },
                                        (error) => {
                                            alert(
                                                "We encountered a problem processing this .heic/Live Photo file.  Please try again, or choose another file to upload."
                                            );
                                            console.error(
                                                "HEIC conversion error:",
                                                error
                                            );
                                            return Upload.LIST_IGNORE;
                                        }
                                    );
                                }
                                if (!isValidFileNameLength(file.name)) {
                                    const newFile =
                                        createNewFileWithShortenedName(file);
                                    return Promise.resolve(newFile);
                                }
                                return true;
                            } else {
                                return Upload.LIST_IGNORE;
                            }
                        })
                        .catch((error) => {
                            alert(
                                "We encountered a problem validating this image file.  Please try again, or choose another file to upload."
                            );
                            console.error(
                                "Error validating file content:",
                                error
                            );
                            setIsFileLoading(false);
                            return Upload.LIST_IGNORE;
                        });
                }}
                onChange={(info): void => {
                    setUploadFileList([...info.fileList]);
                }}
                onRemove={(file: UploadFile): void => {
                    const blobsAfterRemoval = uploadFileBlobs.filter(
                        (fileBlob) => {
                            return (fileBlob as RcFile).uid != file.uid;
                        }
                    );
                    setUploadFileBlobs(blobsAfterRemoval);
                }}
                customRequest={({
                    file,
                    onSuccess,
                }: RcCustomRequestOptions): void => {
                    if (
                        isOperator ||
                        uploadFileBlobs.length < maxConcurrentFileUploads
                    ) {
                        setUploadFileBlobs([...uploadFileBlobs, file as Blob]);
                        if (onSuccess) {
                            onSuccess("Ok");
                        }
                    }
                }}
                onPreview={(): void => {
                    return;
                }}
                listType="picture"
                showUploadList={{
                    showPreviewIcon: false,
                    removeIcon: <DeleteOutlined aria-label="remove file" />,
                }}
                accept={fileTypesToAccept}
                fileList={uploadFileList}
                multiple={true}
                maxCount={isOperator ? undefined : maxConcurrentFileUploads}
                disabled={isModalSubmitting}
            >
                <InboxOutlined />
                <Text>
                    {`Click or drag  ${fileRelatedNoun.toLowerCase()} to this
                        area to upload.`}
                </Text>
                <Text>
                    {`Support for ${fileTypeText} single or bulk upload.`}
                </Text>
                <Spin
                    tip="Loading file(s)"
                    spinning={isFileLoading}
                    className="photo-modal__file-spinner"
                    indicator={
                        <LoadingOutlined
                            style={{ fontSize: "34px" }}
                            spin={true}
                        />
                    }
                />
            </Dragger>
        </Modal>
    );
};

export default FileUploadModal;
