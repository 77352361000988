import MessageBar from "@Components/shared/MessageBar/MessageBar";
import { PersistedMessage } from "@Src/hubs/chat/dtos/PersistedMessage";
import { TextMessage } from "@Src/hubs/chat/dtos/TextMessage";
import { hooks } from "botframework-webchat";
import { useCallback, useEffect, useRef } from "react";
const { useSendMessage } = hooks;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const useRegisterFocusSendBox = require("botframework-webchat-component/lib/hooks/internal/useRegisterFocusSendBox");
import { TextAreaRef } from "antd/lib/input/TextArea";
import { checkTriggerMessage } from "./WipActivityTriggers";

export interface WebChatMessageBarProps {
    chatId: string;
    sendTypingIndicator: (chatId: string) => void;
    isDisabled: boolean;
    sendStopTypingIndicator: (chatId: string) => void;
    typingNotificationTimeoutTimeInSeconds: number;
    isSoftDisabled: boolean;
    softDisabledErrorMessage: string;
}

export const BotMessageBar = ({
    chatId,
    sendTypingIndicator,
    isDisabled,
    sendStopTypingIndicator,
    typingNotificationTimeoutTimeInSeconds,
    isSoftDisabled,
    softDisabledErrorMessage,
}: WebChatMessageBarProps): JSX.Element => {
    const webchatSendMessage = useSendMessage();

    const messageInputRef = useRef<TextAreaRef>(null);

    // This callback is ripped mostly straight from botframework-webchat-component/src/SendBox/TextBox.tsx
    // https://github.com/microsoft/BotFramework-WebChat/blob/504fb2429d8829395a6ff33a187b83a9bbe60567/packages/component/src/SendBox/TextBox.tsx#L4
    // Because we're not longer using their SendBox component we lose access to some features.

    // It gives webchat the functionality of focusing on the message bar on first render, as well as when the user types with their keyboard
    // while focused on the webchat transcript, as long as they're not inside a particular message (hero card, adaptive card)
    const focusCallback = useCallback<
        (options?: { noKeyboard?: boolean }) => void
    >(({ noKeyboard } = {}) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const textAreaRef = messageInputRef.current as any;

        if (!textAreaRef) {
            return;
        }

        if (!noKeyboard) {
            textAreaRef.focus({ cursor: "end" });
            return;
        }

        if (
            !("getAttribute" in textAreaRef) ||
            !("removeAttribute" in textAreaRef)
        ) {
            console.error(
                "Invalid ref, may be referencing an invalid element: Auto focusing the message bar when focused in the conversation will not work"
            );
            return;
        }

        // To not activate the virtual keyboard while changing focus to an input, we will temporarily set it as read-only and flip it back.
        // https://stackoverflow.com/questions/7610758/prevent-iphone-default-keyboard-when-focusing-an-input/7610923
        const readOnly = textAreaRef.getAttribute("readonly");

        textAreaRef.setAttribute("readonly", "readonly");

        setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { timeoutCurrent } = messageInputRef as any;

            if (timeoutCurrent) {
                timeoutCurrent.focus({ cursor: "end" });
                readOnly
                    ? timeoutCurrent.setAttribute("readonly", readOnly)
                    : timeoutCurrent.removeAttribute("readonly");
            }
        }, 0);
    }, []);

    if (useRegisterFocusSendBox?.default) {
        useRegisterFocusSendBox.default(focusCallback);
    }

    useEffect(() => {
        if (!isDisabled) {
            const timeout = setTimeout(() => {
                focusCallback();
            }, 0);
            return (): void => {
                clearTimeout(timeout);
            };
        }
    }, [focusCallback, isDisabled]);

    const sendMessage = useCallback(
        (message: PersistedMessage): void => {
            // File uploads are disabled
            const textMessage = message as TextMessage;

            if (checkTriggerMessage(textMessage)) {
                return;
            }

            webchatSendMessage(textMessage.text);
        },
        [webchatSendMessage]
    );

    return (
        <MessageBar
            chatId={chatId}
            sendMessageAsUser={sendMessage}
            sendTypingIndicator={sendTypingIndicator}
            isDisabled={isDisabled}
            fileUploadsEnabled={false}
            sendStopTypingIndicator={sendStopTypingIndicator}
            typingNotificationTimeoutTimeInSeconds={
                typingNotificationTimeoutTimeInSeconds
            }
            isOperator={false}
            messageInputOverrideRef={messageInputRef}
            isSoftDisabled={isSoftDisabled}
            softDisabledErrorMessage={softDisabledErrorMessage}
            isBotMessageBar={true}
        />
    );
};
