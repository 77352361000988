export enum ViewState {
    Initializing,
    Error,
    Welcome,
    TalkingToBot,
    TalkingToRep,
    Parked,
    BotFeedback,
    RepFeedback,
    ChatUnavailable,
    TimedOut,
    PreviousChatClosed,
    HasExistingTab,
    PlatinumChatUnavailable,
}
