import "./HelpfulFormItems.less";
import { Form, FormInstance, Radio, Select } from "antd";
import RequiredSymbol from "../RequiredSymbol/RequiredSymbol";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { NotHelpfulReason } from "@Models/chat/NotHelpfulReason";
import { useEffect, useState } from "react";

export interface HelpfulFormItemsProps {
    form: FormInstance;
    notHelpfulReasons: NotHelpfulReason[];
}

const HelpfulFormItems: React.FC<HelpfulFormItemsProps> = ({
    form,
    notHelpfulReasons,
}) => {
    const [positiveFeedbackLength, setPositiveFeedbackLength] = useState(0);
    const [negativeFeedbackLength, setNegativeFeedbackLength] = useState(0);
    const positiveFeedbackMaxLength = 512;
    const negativeFeedbackMaxLength = 512;
    const otherId = notHelpfulReasons.find(
        (reason) => reason.reasonText === "Other"
    )?.id;

    const getIsHelpfulSelected = (): boolean => {
        return form.getFieldValue("wasHelpful") === true;
    };

    const getIsNotHelpfulSelected = (): boolean => {
        return form.getFieldValue("wasHelpful") === false;
    };

    const getIsOtherSelected = (): boolean => {
        const selectedReasonId = form.getFieldValue("notHelpfulReason");
        return parseInt(selectedReasonId) === otherId;
    };

    const getIsFeedbackRequired = (): boolean => {
        return (
            getIsNotHelpfulSelected() &&
            (getIsOtherSelected() || notHelpfulReasons.length === 0)
        );
    };

    const getFeedbackCommentPrompt = (): JSX.Element => {
        const isRequired = getIsFeedbackRequired();

        if (isRequired) {
            return (
                <>
                    <RequiredSymbol />
                    Please help us improve by leaving feedback.
                </>
            );
        }

        return <>You may leave additional feedback.</>;
    };

    const onHelpfulResponse = (wasHelpful: boolean): void => {
        if (wasHelpful) {
            form.setFields([
                {
                    name: "notHelpfulReason",
                    value: undefined,
                    errors: [],
                },
                {
                    name: "negativeFeedback",
                    value: undefined,
                    errors: [],
                },
            ]);

            form.validateFields();
        } else {
            form.setFields([
                {
                    name: "positiveFeedback",
                    value: undefined,
                    errors: [],
                },
            ]);
        }
    };

    const indentedFormItemProps = {
        labelCol: { offset: 1 },
        wrapperCol: { offset: 1 },
    };

    const handlePositiveFeedbackTextAreaChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ): void => {
        setPositiveFeedbackLength(e.target.value.length);
    };

    const handleNegativeFeedbackTextAreaChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ): void => {
        setNegativeFeedbackLength(e.target.value.length);
    };

    const screenReaderOnlySpan = (
        currentLength: number,
        maxLength: number
    ): JSX.Element => {
        return (
            <span className="sr-only" aria-live="polite" aria-atomic="true">
                {currentLength} of {maxLength} characters used
            </span>
        );
    };

    // Accessibility ask - remove aria-label attribute from the icons on the feedback form.
    useEffect(() => {
        const iconArrow = document.querySelector(
            "span.anticon.anticon-right.ant-collapse-arrow"
        );
        if (iconArrow) {
            iconArrow.removeAttribute("aria-label");
            iconArrow.setAttribute("alt", "View Completed Chat icon");
            iconArrow.removeAttribute("role");
        }

        const iconHelpful = document.querySelector(
            "span.anticon.anticon-like.helpful-form-items__radio-icon"
        );
        if (iconHelpful) {
            iconHelpful.removeAttribute("aria-label");
            iconHelpful.removeAttribute("role");
        }

        const iconDislike = document.querySelector(
            "span.anticon.anticon-dislike.helpful-form-items__radio-icon"
        );
        if (iconDislike) {
            iconDislike.removeAttribute("aria-label");
            iconDislike.removeAttribute("role");
        }

        const notHelpfulReasonSelectSearchInput =
            document.querySelector("#notHelpfulReason");
        if (notHelpfulReasonSelectSearchInput) {
            notHelpfulReasonSelectSearchInput.removeAttribute(
                "aria-autocomplete"
            );
        }

        const nextActionDiv = document.querySelector("#nextAction");
        if (nextActionDiv) {
            nextActionDiv.removeAttribute("aria-required");
        }
    }, []);

    return (
        <div className="helpful-form-items">
            <fieldset aria-required="true">
                <legend>
                    <RequiredSymbol />
                    <h2 data-testid="helpful-form-header-text">
                        Was this chat helpful?
                    </h2>
                </legend>
                <Form.Item
                    name="wasHelpful"
                    className="helpful-form-items__primary-question"
                    rules={[
                        {
                            required: true,
                            message:
                                // eslint-disable-next-line quotes
                                'Please select either "Helpful" or "Not Helpful" above.',
                        },
                    ]}
                >
                    <Radio.Group
                        name="wasHelpful"
                        onChange={(e): void =>
                            onHelpfulResponse(e.target.value)
                        }
                    >
                        <Radio value={true}>
                            Helpful
                            <LikeOutlined
                                style={{ color: "#237804" }}
                                className="helpful-form-items__radio-icon"
                                alt="Helpful icon"
                            />
                        </Radio>
                        <Form.Item noStyle shouldUpdate>
                            {(): JSX.Element => (
                                <Form.Item
                                    name="positiveFeedback"
                                    {...indentedFormItemProps}
                                    label={getFeedbackCommentPrompt()}
                                    hidden={!getIsHelpfulSelected()}
                                >
                                    <TextArea
                                        placeholder="Add Feedback"
                                        autoSize={{
                                            minRows: 2,
                                            maxRows: 7,
                                        }}
                                        maxLength={positiveFeedbackMaxLength}
                                        showCount
                                        onBlur={(e): void => {
                                            form.setFieldsValue({
                                                positiveFeedback:
                                                    e.target.value.trim(),
                                            });
                                        }}
                                        onChange={
                                            handlePositiveFeedbackTextAreaChange
                                        }
                                        data-testid="positive-feedback-textarea"
                                    />
                                    {screenReaderOnlySpan(
                                        positiveFeedbackLength,
                                        positiveFeedbackMaxLength
                                    )}
                                </Form.Item>
                            )}
                        </Form.Item>
                        <Radio value={false}>
                            Not Helpful
                            <DislikeOutlined
                                style={{ color: "#D4380D" }}
                                className="helpful-form-items__radio-icon"
                                alt="Not Helpful icon"
                            />
                        </Radio>
                    </Radio.Group>
                </Form.Item>
            </fieldset>
            <Form.Item
                noStyle
                shouldUpdate={(previous, current): boolean =>
                    previous.wasHelpful !== current.wasHelpful
                }
            >
                {(): JSX.Element => (
                    <Form.Item
                        name="notHelpfulReason"
                        {...indentedFormItemProps}
                        label={
                            <>
                                <RequiredSymbol />
                                Please select a reason, so we can improve future
                                chats.
                            </>
                        }
                        hidden={
                            !getIsNotHelpfulSelected() ||
                            notHelpfulReasons.length === 0
                        }
                        rules={[
                            {
                                required:
                                    getIsNotHelpfulSelected() &&
                                    notHelpfulReasons.length > 0,
                                message: "Please select a reason.",
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select Reason"
                            onChange={(): void =>
                                form.setFields([
                                    {
                                        name: "negativeFeedback",
                                        errors: [],
                                    },
                                ])
                            }
                        >
                            {notHelpfulReasons.map((reason) => (
                                <Select.Option
                                    key={reason.id}
                                    value={reason.id}
                                >
                                    {reason.reasonText}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
                {(): JSX.Element => {
                    return (
                        <Form.Item
                            name="negativeFeedback"
                            className="helpful-form-items__textarea-field"
                            {...indentedFormItemProps}
                            label={getFeedbackCommentPrompt()}
                            rules={[
                                {
                                    required: getIsFeedbackRequired(),
                                    message: "You must leave feedback.",
                                },
                            ]}
                            hidden={!getIsNotHelpfulSelected()}
                        >
                            <TextArea
                                placeholder="Add Feedback"
                                autoSize={{
                                    minRows: 2,
                                    maxRows: 7,
                                }}
                                maxLength={negativeFeedbackMaxLength}
                                showCount
                                onBlur={(e): void => {
                                    form.setFieldsValue({
                                        negativeFeedback: e.target.value.trim(),
                                    });
                                }}
                                onChange={handleNegativeFeedbackTextAreaChange}
                                data-testid="negative-feedback-textarea"
                            />
                            {screenReaderOnlySpan(
                                negativeFeedbackLength,
                                negativeFeedbackMaxLength
                            )}
                        </Form.Item>
                    );
                }}
            </Form.Item>
        </div>
    );
};

export default HelpfulFormItems;
