import * as uuid from "uuid";

let tabId = "";

const tabIdSessionKey = "tabId";

const sessionStorage = window.sessionStorage;
if (sessionStorage) {
    tabId = sessionStorage.getItem(tabIdSessionKey) ?? "";
    // Remove it until "unload" so that Chrome's tab Duplicate function doesn't copy it
    sessionStorage.removeItem(tabIdSessionKey);
}

if (!tabId) {
    tabId = uuid.v4();
}

if (sessionStorage) {
    window.addEventListener("unload", () => {
        sessionStorage.setItem(tabIdSessionKey, tabId);
    });
}

export function getTabId(): string {
    return tabId;
}
