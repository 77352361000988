import "./ConversationView.less";
import React, {
    useEffect,
    useState,
    createRef,
    RefObject,
    useCallback,
} from "react";
import classNames from "classnames";
import { Button, Divider, Layout } from "antd";
const { Footer } = Layout;
import { ClockCircleOutlined, ArrowDownOutlined } from "@ant-design/icons";
import MessageBar from "@Components/shared/MessageBar/MessageBar";
import Message from "@Components/shared/Message/Message";
import { BaseMessage } from "@Hubs/chat/dtos/BaseMessage";
import { ClientRole } from "@Hubs/chat/dtos/ClientRole";
import { PersistedMessage } from "@Hubs/chat/dtos/PersistedMessage";
import { isContentType } from "@Hubs/chat/dtos/RenderedMessageType";
import { PreviousChat } from "@Models/chat/PreviousChat";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
import { ChatApiShared } from "@Api/ChatApiShared";
import { MessageApiShared } from "@Api/MessageApiShared";
import { PersistedMessageType } from "@Src/hubs/chat/dtos/PersistedMessageType";
import { UserJoinedChatMessage } from "@Src/hubs/chat/dtos/UserJoinedChatMessage";
import { tenantRoute } from "@Src/tenantConfiguration/SharedTenantConfiguration";
import { TenantRoute } from "@Models/TenantRoute";
import { TypingIndicator } from "@Models/typingIndicator/TypingIndicator";
import TypingIndicatorBubble from "@Components/shared/TypingIndicator/TypingIndicatorBubble";

export interface ConversationViewProps {
    chatId: string;
    messages: Array<BaseMessage>;
    myUserId: string;
    clientRole: ClientRole;
    sendMessageAsUser?(message: PersistedMessage): Promise<string>;
    header?: JSX.Element;
    chatBanner?: JSX.Element;
    previousChats?: Array<PreviousChat>;
    customerUserIndex?: number;
    dboChatId?: number;
    onPreviousChatLoaded?: (chatId: string, previousChat: PreviousChat) => void;
    chatApi: ChatApiShared;
    messageApi: MessageApiShared;
    cannedResponseElement?: JSX.Element;
    smartIncludesEnabled: boolean;
    fileUploadsEnabled: boolean;
    sendFileAsUser(chatId: string, data: FormData): Promise<void>;
    isMessageBarDisabled: boolean;
    isChatHubConnected: boolean;
    isMessageBarHidden?: boolean;
    typingIndicators: TypingIndicator[];
    sendTypingIndicator(chatId: string): void;
    sendStopTypingIndicator(chatId: string): void;
    typingNotificationTimeoutTimeInSeconds: number;
    updateScrollValue?: ScrollUpdateCallback;
    saveUnsentText?(chatId: string, text: string): void;
    getUnsentTextForChat?(chatId: string): string;
    baseImageRoute: string;
    unreadMessageCountWhileScrolledUp: number;
}

export type ScrollUpdateCallback = (
    isManualUpdate: boolean,
    scrollRef: RefObject<HTMLDivElement>,
    forceToBottom: boolean
) => void;

const ConversationView = ({
    chatId,
    messages,
    myUserId,
    clientRole,
    sendMessageAsUser,
    header,
    chatBanner,
    previousChats,
    customerUserIndex,
    dboChatId,
    onPreviousChatLoaded,
    chatApi,
    messageApi,
    cannedResponseElement,
    smartIncludesEnabled,
    fileUploadsEnabled,
    sendFileAsUser,
    isMessageBarDisabled,
    isChatHubConnected,
    isMessageBarHidden,
    typingIndicators,
    sendTypingIndicator,
    sendStopTypingIndicator,
    typingNotificationTimeoutTimeInSeconds,
    updateScrollValue,
    saveUnsentText,
    getUnsentTextForChat,
    baseImageRoute,
    unreadMessageCountWhileScrolledUp,
}: ConversationViewProps): JSX.Element => {
    let isFirstConsecutive = false;
    let isLastConsecutive = false;

    let nextPreviousChatIdToLoad: number | undefined;
    let lastLoadedChatId = dboChatId;

    const hidePreviousChatComponents =
        !customerUserIndex || !dboChatId ? true : false;

    const [isLoadingPreviousChat, setIsLoadingPreviousChat] =
        useState<boolean>(false);

    const previousChatLookup: {
        [dboChatId: number]: PreviousChat;
    } = {};

    const operatorAvatars: { [operatorId: string]: string | null } = {};

    const [, setRerenderToggle] = useState<boolean>(false);

    const rerenderConversation = useCallback(() => {
        const rerenderTimeout = setTimeout(() => {
            // Intended to trigger a re-render after the image has loaded into the UI and
            // the scrollbars have re-calculated
            setRerenderToggle((toggle) => !toggle);
        }, 1);
        return (): void => {
            clearTimeout(rerenderTimeout);
        };
    }, []);

    const getOperatorAvatars = (chatMessages: BaseMessage[]): void => {
        if (tenantRoute !== TenantRoute.wss) {
            return;
        }

        chatMessages.forEach((message) => {
            if (
                message.clientRole === ClientRole.Operator &&
                message.messageType === PersistedMessageType.UserJoinedChat
            ) {
                const currMessage = message as UserJoinedChatMessage;
                operatorAvatars[message.senderUserId] = currMessage.avatar;
            }
        });
    };

    if (previousChats) {
        previousChats.forEach((previousChat, index) => {
            if (!nextPreviousChatIdToLoad && !previousChat.messages) {
                nextPreviousChatIdToLoad = previousChat.dboChatId;
                if (index > 0) {
                    lastLoadedChatId = previousChats[index - 1].dboChatId;
                }
            }
            previousChatLookup[previousChat.dboChatId] = previousChat;
            return previousChat;
        });
        previousChats
            .filter((previousChat) => previousChat.messages)
            .forEach((previousChat) =>
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                getOperatorAvatars(previousChat.messages!)
            );
    }
    getOperatorAvatars(messages);

    const getFirstConsecutive = (
        message: BaseMessage,
        prevMessage: BaseMessage | null
    ): boolean => {
        if (prevMessage === null) {
            return true;
        }

        const differentSenders =
            prevMessage.senderUserId !== message.senderUserId;

        const haveDifferentTypes =
            isContentType(prevMessage.messageType) !==
            isContentType(message.messageType);

        return differentSenders || haveDifferentTypes;
    };

    const getLastConsecutive = (
        message: BaseMessage,
        nextMessage: BaseMessage | null
    ): boolean => {
        if (nextMessage === null) {
            return true;
        }

        const differentSenders =
            nextMessage.senderUserId !== message.senderUserId;

        const haveDifferentTypes =
            isContentType(nextMessage.messageType) !=
            isContentType(message.messageType);

        return differentSenders || haveDifferentTypes;
    };

    const calculateGroupingValues = (
        message: BaseMessage,
        index: number,
        messageArr: BaseMessage[]
    ): void => {
        const isFirstMessage = message === messageArr[0];
        const isLastMessage = message === messageArr[messageArr.length - 1];

        const prevMessage: BaseMessage | null = !isFirstMessage
            ? messageArr[index - 1]
            : null;
        const nextMessage: BaseMessage | null = !isLastMessage
            ? messageArr[index + 1]
            : null;

        if (isFirstMessage) {
            isFirstConsecutive = true;
            isLastConsecutive = getLastConsecutive(message, nextMessage);
        } else if (isLastMessage) {
            isFirstConsecutive = getFirstConsecutive(message, prevMessage);
            isLastConsecutive = true;
        } else {
            isFirstConsecutive = getFirstConsecutive(message, prevMessage);
            isLastConsecutive = getLastConsecutive(message, nextMessage);
        }
    };

    const onLoadPreviousClicked = (): void => {
        if (
            !customerUserIndex ||
            !nextPreviousChatIdToLoad ||
            !onPreviousChatLoaded
        ) {
            return;
        }

        const currentChatIdToLoad = nextPreviousChatIdToLoad;

        console.log("Begin loading dboChatId", currentChatIdToLoad);

        setIsLoadingPreviousChat(true);

        chatApi
            .getPreviousChat(currentChatIdToLoad, customerUserIndex)
            .then((previousChatResponse) => {
                console.log("Previous chat loaded", previousChatResponse);
                setIsLoadingPreviousChat(false);
                onPreviousChatLoaded(chatId, previousChatResponse);
            })
            .catch((error) => {
                console.error(error);
                setIsLoadingPreviousChat(false);
                alert("Unexpected error while loading previous chat.");
            });
    };

    const renderChat = (
        messages: BaseMessage[],
        startDate: string,
        renderDivider: boolean,
        renderLoadPrevious: boolean,
        isPreviousChat: boolean
    ): JSX.Element => {
        const isMineOrOperatorSeeingBot = (message: BaseMessage): boolean => {
            const isMine = myUserId === message.senderUserId;
            const isOperatorSeeingBot =
                message.senderUserId == undefined &&
                clientRole == ClientRole.Operator;
            return !isPreviousChat && (isMine || isOperatorSeeingBot);
        };

        return (
            <>
                {!hidePreviousChatComponents && renderLoadPrevious && (
                    <div className="conversation-view__button-container">
                        <Button
                            className="conversation-view__load-previous__button-outlined"
                            size="small"
                            onClick={onLoadPreviousClicked}
                            disabled={isLoadingPreviousChat}
                        >
                            <ClockCircleOutlined />
                            Load Previous Chat
                        </Button>
                    </div>
                )}
                {!hidePreviousChatComponents &&
                    nextPreviousChatIdToLoad &&
                    renderDivider && <Divider>{startDate}</Divider>}
                {messages.map((message, index, array) => {
                    if (isContentType(message.messageType)) {
                        calculateGroupingValues(message, index, array);
                    }
                    return (
                        <Message
                            key={message.id}
                            message={message}
                            isMineOrOperatorSeeingBot={isMineOrOperatorSeeingBot(
                                message
                            )}
                            isFirstConsecutive={isFirstConsecutive}
                            isLastConsecutive={isLastConsecutive}
                            sendMessageAsUser={
                                sendMessageAsUser as (
                                    message: PersistedMessage
                                ) => Promise<string>
                            }
                            isChatHubConnected={isChatHubConnected}
                            smartIncludesEnabled={smartIncludesEnabled}
                            messageApi={messageApi}
                            avatar={operatorAvatars[message.senderUserId]}
                            baseImageRoute={baseImageRoute}
                            onRerenderNeeded={rerenderConversation}
                        />
                    );
                })}
                {typingIndicators && typingIndicators.length > 0 && (
                    <TypingIndicatorBubble
                        typingIndicators={typingIndicators}
                    />
                )}
            </>
        );
    };

    const renderPreviousChats = (): JSX.Element[] => {
        if (!previousChats) {
            return [];
        }
        const sortedPreviousChatsAscending = [...previousChats].sort(
            (a, b) => a.startDate.getTime() - b.startDate.getTime()
        );

        const numPreviousChats = sortedPreviousChatsAscending.length;
        const lastChatIndex = numPreviousChats - 1;
        return sortedPreviousChatsAscending
            .filter((previousChat) => previousChat.messages)
            .map((previousChat, index) => {
                return renderChat(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    previousChat.messages!,
                    dayjs(previousChat.startDate)
                        .local()
                        .format("MMMM D, YYYY"),
                    index < lastChatIndex,
                    previousChat.dboChatId === lastLoadedChatId,
                    true
                );
            });
    };

    const hasPreviousChats = (previousChats?.length ?? 0) > 0;

    const scrollRef: React.ForwardedRef<HTMLDivElement> = createRef();

    useEffect(() => {
        if (updateScrollValue) {
            updateScrollValue(false, scrollRef, false);
        }
    });

    return (
        <Layout
            className={classNames({
                "conversation-view__container": true,
                operator: clientRole === ClientRole.Operator,
            })}
        >
            {header}
            <div
                className="conversation-view"
                ref={scrollRef}
                onScroll={(event): void => {
                    const dispatchedByViewport =
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (event.detail as any)?.dispatchedByViewport ?? false;
                    if (updateScrollValue) {
                        updateScrollValue(
                            !dispatchedByViewport,
                            scrollRef as RefObject<HTMLDivElement>,
                            false
                        );
                    }
                }}
                role="log"
            >
                {renderPreviousChats()}
                {renderChat(
                    messages,
                    "Now",
                    hasPreviousChats,
                    dboChatId === lastLoadedChatId,
                    false
                )}
                {unreadMessageCountWhileScrolledUp > 0 && (
                    <Button
                        className="conversation-view__new-message-button"
                        onClick={(): void => {
                            if (scrollRef && updateScrollValue) {
                                updateScrollValue(
                                    true,
                                    scrollRef as RefObject<HTMLDivElement>,
                                    true
                                );
                            }
                        }}
                    >
                        <ArrowDownOutlined />
                        {unreadMessageCountWhileScrolledUp > 1
                            ? `${unreadMessageCountWhileScrolledUp} New Messages`
                            : "New Message!"}
                    </Button>
                )}
            </div>
            {chatBanner}
            {!isMessageBarHidden && (
                <Footer className="message-bar__container">
                    <MessageBar
                        chatId={chatId}
                        sendMessageAsUser={
                            sendMessageAsUser as (
                                message: PersistedMessage
                            ) => Promise<string>
                        }
                        sendTypingIndicator={sendTypingIndicator}
                        cannedResponseElement={cannedResponseElement}
                        isDisabled={isMessageBarDisabled}
                        fileUploadsEnabled={fileUploadsEnabled}
                        sendFileAsUser={sendFileAsUser}
                        sendStopTypingIndicator={sendStopTypingIndicator}
                        typingNotificationTimeoutTimeInSeconds={
                            typingNotificationTimeoutTimeInSeconds
                        }
                        saveUnsentText={saveUnsentText}
                        getUnsentTextForChat={getUnsentTextForChat}
                        isOperator={clientRole == ClientRole.Operator}
                        isSoftDisabled={false}
                        softDisabledErrorMessage=""
                        isBotMessageBar={false}
                    />
                </Footer>
            )}
        </Layout>
    );
};

export default ConversationView;
