import { SwitchboardConfig } from "@Models/SwitchboardConfig";
import { AxiosInstance } from "axios";
import { tenantRoute } from "@Src/tenantConfiguration/SharedTenantConfiguration";
import { pathJoin } from "@Utilities/PathJoin";

export abstract class SwitchboardApiBase {
    protected config: SwitchboardConfig;
    protected axios: AxiosInstance;
    constructor(config: SwitchboardConfig, axios: AxiosInstance) {
        this.config = config;
        this.axios = axios;
    }

    protected buildSwitchboardApiUrl(endpointPath: string): string {
        return `${this.config.switchboardUrl}/${pathJoin(
            "api",
            tenantRoute,
            endpointPath
        )}`;
    }
}
