import dayjs from "dayjs";
import { RetryDelays } from "@Utilities/SignalrRetry";
import { DeployType } from "@Models/deploy/DeployType";

const serviceName = "DeployService";

class DeployService {
    private deployBeginReconnectTimeoutId: number | undefined;

    private deployBeginReconnectDurationMilliseconds = 7_000;

    private deployTakingTooLongTimeoutId: number | undefined;

    private deployTakingTooLongDurationMilliseconds = 10_000;

    private appDeployTimeoutId: number | undefined;

    private maxWaitForDeployEndedSeconds = 300;

    private removedLastDeployDataCallback?: () => void;

    private updatedDeployTypeAndLastDeployDateCallback?: (
        deployType: DeployType,
        deployDate: Date
    ) => void;

    onMyServerDeployStarted(
        closeHub: () => Promise<void>,
        startHub: () => Promise<boolean>,
        onReconnectTakingTooLong: () => void
    ): void {
        closeHub();

        console.log(`${serviceName} maintenance beginning, starting timeout`);

        if (this.deployBeginReconnectTimeoutId) {
            window.clearTimeout(this.deployBeginReconnectTimeoutId);
            delete this.deployBeginReconnectTimeoutId;
        }

        const clearTakingTooLongTimeout = (): void => {
            if (this.deployTakingTooLongTimeoutId) {
                window.clearTimeout(this.deployTakingTooLongTimeoutId);
                delete this.deployTakingTooLongTimeoutId;
            }
        };

        this.deployBeginReconnectTimeoutId = window.setTimeout(async () => {
            let isConnected = false;

            const tryReconnect = (): Promise<boolean> => {
                return startHub()
                    .then((success) => {
                        if (success) {
                            clearTakingTooLongTimeout();
                            console.log(
                                `${serviceName} maintenance finished, connection established`
                            );
                        }
                        return success;
                    })
                    .catch(() => {
                        return false;
                    })
                    .finally(() => {
                        delete this.deployBeginReconnectTimeoutId;
                    });
            };

            isConnected = await tryReconnect();

            let retryIndex = 0;
            while (!isConnected && retryIndex < RetryDelays.length) {
                const currentDelay = RetryDelays[retryIndex];
                await new Promise<void>((resolve) => {
                    window.setTimeout(() => resolve(), currentDelay);
                });
                isConnected = await tryReconnect();
                retryIndex++;
            }

            if (!isConnected) {
                console.log(
                    `${serviceName} could not re-establish connection after maintenance.`
                );
                onReconnectTakingTooLong();
            }
        }, this.deployBeginReconnectDurationMilliseconds);

        this.deployTakingTooLongTimeoutId = window.setTimeout(() => {
            delete this.deployTakingTooLongTimeoutId;
            onReconnectTakingTooLong();
        }, this.deployTakingTooLongDurationMilliseconds);
    }

    onAppDeployStarted(startDate: Date, deployType?: DeployType): void {
        const millisecondsSinceDeployStarted = dayjs(Date.now()).diff(
            startDate
        );

        let millisecondsUntilTimeout =
            this.maxWaitForDeployEndedSeconds * 1000 -
            millisecondsSinceDeployStarted;

        if (millisecondsUntilTimeout < 0) {
            millisecondsUntilTimeout = 0;
        }

        if (this.updatedDeployTypeAndLastDeployDateCallback) {
            this.updatedDeployTypeAndLastDeployDateCallback(
                deployType ?? DeployType.Deploy,
                startDate
            );
        }

        if (this.appDeployTimeoutId) {
            window.clearTimeout(this.appDeployTimeoutId);
        }

        if (this.removedLastDeployDataCallback) {
            this.appDeployTimeoutId = window.setTimeout(async () => {
                if (this.removedLastDeployDataCallback) {
                    this.removedLastDeployDataCallback();
                }
            }, millisecondsUntilTimeout);
        }
    }

    onAppDeployEnded(): void {
        if (this.appDeployTimeoutId) {
            window.clearTimeout(this.appDeployTimeoutId);
        }

        if (this.removedLastDeployDataCallback) {
            this.removedLastDeployDataCallback();
        }
    }

    setTimeoutDurations(
        deployBeginReconnectDurationMilliseconds: number,
        deployTakingTooLongDurationMilliseconds: number,
        maxWaitForDeployEndedSeconds?: number
    ): void {
        this.deployBeginReconnectDurationMilliseconds =
            deployBeginReconnectDurationMilliseconds;

        this.deployTakingTooLongDurationMilliseconds =
            deployTakingTooLongDurationMilliseconds;

        this.maxWaitForDeployEndedSeconds = maxWaitForDeployEndedSeconds ?? 300;
    }

    setCallbackFunctions(
        removedLastDeployDataCallback?: () => void,
        updatedDeployTypeAndLastDeployDateCallback?: (
            deployType: DeployType,
            startDate: Date
        ) => void
    ): void {
        this.removedLastDeployDataCallback = removedLastDeployDataCallback;
        this.updatedDeployTypeAndLastDeployDateCallback =
            updatedDeployTypeAndLastDeployDateCallback;
    }
}

let deployServiceSingleton: DeployService | undefined;

export function getInstance(): DeployService {
    if (!deployServiceSingleton) {
        deployServiceSingleton = new DeployService();
    }
    return deployServiceSingleton;
}
