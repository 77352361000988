import { AxiosInstance } from "axios";
import { SwitchboardConfig } from "@Models/SwitchboardConfig";
import { PersistedMessage } from "@Hubs/chat/dtos/PersistedMessage";
import { CreateBotTextMessageRequest } from "@Models/chatBot/CreateBotTextMessageRequest";
import { MessageApiShared } from "@Api/MessageApiShared";

export class MessageApi extends MessageApiShared {
    constructor(config: SwitchboardConfig, axios: AxiosInstance) {
        super(config, axios);
    }

    public sendMessageAsCustomer(message: PersistedMessage): Promise<string> {
        return this.axios
            .post(
                this.buildSwitchboardApiUrl("Message/sendMessageAsCustomer"),
                message
            )
            .then((response) => response.data);
    }

    public sendPhotoAsCustomer(data: FormData): Promise<void> {
        return this.axios
            .post(
                this.buildSwitchboardApiUrl("Message/sendPhotoAsCustomer"),
                data
            )
            .then(() => {
                return;
            })
            .catch((error) => {
                console.error(
                    "Error occurred while sending photo(s):",
                    error?.response?.data
                );
                return Promise.reject(error);
            });
    }

    public recordBotMessages(
        messages: CreateBotTextMessageRequest[]
    ): Promise<void> {
        return this.axios.post(
            this.buildSwitchboardApiUrl("Message/recordBotMessages"),
            messages
        );
    }
}
