import { PersistedMessage } from "./PersistedMessage";

interface IncomingMessage extends Omit<PersistedMessage, "timestamp"> {
    timestamp: string;
}

export class UserTimedOutMessage extends PersistedMessage {
    constructor(message: IncomingMessage) {
        super(message);
    }
}
