import "./ChatClosedView.less";
import { Button, Typography, Space } from "antd";
const { Text } = Typography;
import withSessionReset from "../WithSessionReset/WithSessionReset";
import withChatHubDisconnect from "../WithChatHubDisconnect/WithChatHubDisconnect";
import {
    faqLink,
    helpEmail,
    faqButtonText,
    showHelpEmailButton,
} from "@Src/tenantConfiguration/CustomerTenantConfiguration";
import { customerServiceDeptName } from "@Src/tenantConfiguration/SharedTenantConfiguration";

const ChatClosedView = (): JSX.Element => {
    const onStartNewChatClicked = (): void => {
        window.location.reload();
    };
    return (
        <div className="chat-closed-view">
            <div className="chat-closed-view__content">
                <Text>
                    Sorry, your previous chat was closed. If you still need
                    assistance, choose one of the options below.
                </Text>
                <Space className="chat-closed-view__content__buttons">
                    {faqLink && (
                        <Button type="primary" href={faqLink} target="_blank">
                            {faqButtonText}
                        </Button>
                    )}
                    {showHelpEmailButton && (
                        <Button
                            type="primary"
                            href={`mailto:${helpEmail}`}
                            target="_blank"
                        >
                            Email {customerServiceDeptName}
                        </Button>
                    )}
                    <Button type="primary" onClick={onStartNewChatClicked}>
                        Start a New Chat
                    </Button>
                </Space>
            </div>
        </div>
    );
};

export default withChatHubDisconnect(withSessionReset(ChatClosedView));
