import "./ChatInfo.less";
import { AnchorHTMLAttributes } from "react";

interface ChatInfoProps {
    text: string;
    link?: AnchorHTMLAttributes<HTMLAnchorElement>;
}

const ChatInfo = ({ text, link }: ChatInfoProps): JSX.Element => {
    return (
        <div className="chat-info">
            {text}
            {link}
        </div>
    );
};

export default ChatInfo;
