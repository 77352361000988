/*
  Constants defining the valid file types customers and operators can upload to chat.
*/
import { fileUploadDefinitions } from "./FileUploadDefinitions";

export const customerFileTypesToAcceptForExplorer =
    fileUploadDefinitions.allowedFileExtensionsForCustomers.join(", ");

export const customerFileTypesToAcceptText = ".jpg, .png, or .heic";

export const operatorFileTypesToAcceptForExplorer =
    fileUploadDefinitions.allowedFileExtensionsForOperators.join(", ");

export const operatorFileTypesToAcceptText =
    ".jpg, .png, .heic, .pdf, .doc, .docx, .xls, .xlsx, .mp4, .txt, or .csv";
