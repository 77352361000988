import "../../../components/shared/MessageBar/MessageBar.less";

interface BotMessageBarTextCounterProps {
    messageLength: number;
    messageMaxLength: number;
    thresholdLengthToDisplayCounter: number;
    dataTestId: string;
}

const BotMessageBarTextCounter = ({
    messageLength,
    messageMaxLength,
    thresholdLengthToDisplayCounter,
    dataTestId,
}: BotMessageBarTextCounterProps): JSX.Element => {
    return (
        <div className="message-bar-text-counter" data-testid={dataTestId}>
            {messageLength >= thresholdLengthToDisplayCounter && (
                <div aria-live="polite" aria-atomic="true">
                    <span>{messageLength}</span>
                    <span className="sr-only"> characters used of </span>
                    <span>{messageMaxLength}</span>
                    <span className="sr-only"> characters available</span>
                </div>
            )}
        </div>
    );
};

export default BotMessageBarTextCounter;
