import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import { InfoCircleOutlined, InfoCircleTwoTone } from "@ant-design/icons";

import "./EstimatedTotalToolTip.less";

const EstimatedTotalToolTip = (): React.JSX.Element => {
    const [twoToneColor, setTwoToneColor] = useState<
        [string, string] | undefined
    >(undefined);

    const handleOnFocus = (): void => {
        setTwoToneColor(["#00567f", "#cce5f0"]);
    };

    const handleOnBlur = (): void => {
        setTimeout(() => {
            if (twoToneColor !== undefined) {
                setTwoToneColor(undefined);
            }
        }, 0);
    };

    return (
        <Tooltip
            title="The estimated total is the sum of the item(s) selected. The actual refund may differ as it will include any shipping and/or tax."
            aria-describedby="EstimatedTotalInformation"
            trigger={["hover", "focus"]}
        >
            <Button
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                className="tooltip-button"
                icon={
                    twoToneColor ? (
                        <InfoCircleTwoTone twoToneColor={twoToneColor} />
                    ) : (
                        <InfoCircleOutlined />
                    )
                }
                shape="circle"
                type="text"
                id="EstimatedTotalInformation"
                aria-label="The estimated total is the sum of the item(s) selected. The actual refund may differ as it will include any shipping and/or tax."
            />
        </Tooltip>
    );
};

export default EstimatedTotalToolTip;
