import { Select, Typography } from "antd";
const { Text } = Typography;
export interface DropDownSelectBoxProps {
    "data-testid": string;
    className: string;
    label: string | React.JSX.Element;
    options: { value: string; label: string }[];
    value: string;
    onChange: (
        value: string,
        option:
            | { value: string; label: string }
            | { value: string; label: string }[]
    ) => void;
    placeholder: string;
    isReadOnly: boolean;
    isSubmitting: boolean;
    validationFailedMessage?: string;
    validate?: () => boolean;
}

const DropDownSelectBox = (
    props: DropDownSelectBoxProps
): React.JSX.Element => {
    const {
        "data-testid": dataTestId,
        className,
        label,
        options,
        value,
        onChange,
        placeholder,
        isReadOnly,
        isSubmitting,
        validationFailedMessage,
        validate,
    } = props;

    const isValidationFailed =
        validate && validationFailedMessage && !validate();

    return (
        <div
            className={`dropdown-select-box ${className}`}
            data-testid={`$${dataTestId}-container`}
        >
            <Text
                className={`dropdown-select-box__label ${className}__label`}
                data-testid={`${dataTestId}-label`}
            >
                {label}
            </Text>
            {!isReadOnly && (
                <>
                    <Select
                        data-testid={`${dataTestId}-select`}
                        className={`dropdown-select-box__select ${className}__select`}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        disabled={isSubmitting}
                        status={isValidationFailed ? "error" : ""}
                    >
                        {options.map((option) => (
                            <Select.Option
                                key={option.value}
                                value={option.value}
                            >
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                    {isValidationFailed && (
                        <Text
                            type="danger"
                            style={{ marginBottom: "12px" }}
                            aria-live="polite"
                            data-testid={`${dataTestId}-validation-error`}
                        >
                            {validationFailedMessage}
                        </Text>
                    )}
                </>
            )}
            {isReadOnly && (
                <Text
                    data-testid={`${dataTestId}-readonly`}
                    className={`dropdown-select-box__readonly ${className}__readonly`}
                >
                    {value}
                </Text>
            )}
        </div>
    );
};

export default DropDownSelectBox;
