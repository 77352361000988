import { CloseCircleFilled } from "@ant-design/icons";
import { isOperator } from "@Src/tenantConfiguration/SharedTenantConfiguration";
import { helpEmail } from "@Src/tenantConfiguration/CustomerTenantConfiguration";
import { Button } from "antd";

import "./RenderError.less";

interface Props {
    wrapper: (WrappedComponent: React.ComponentType) => React.ComponentType;
}

const RenderErrorContent = (): JSX.Element => (
    <div className="render-error-container">
        <CloseCircleFilled style={{ fontSize: "50px", color: "#ff0000" }} />
        <strong className="render-error-container__header">
            An error has occurred.
        </strong>
        <div className="render-error-container__text">
            <p>An unexpected error has occurred. Please try to refresh.</p>
            {!isOperator && (
                <p>
                    If the error persists, please{" "}
                    <a
                        href={`mailto:${helpEmail}`}
                        className="render-error-container__email"
                    >
                        contact us
                    </a>
                    .
                </p>
            )}
        </div>
        <Button onClick={(): void => window.location.reload()}>Refresh</Button>
    </div>
);

const RenderError = ({ wrapper }: Props): JSX.Element => {
    const WrappedContent = wrapper(RenderErrorContent);
    return <WrappedContent />;
};

export default RenderError;
