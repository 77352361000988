import { TextOrLinkData } from "@Src/hubs/chat/dtos/TextOrLinkData";
import { Space, Typography, Button, Image } from "antd";
const { Paragraph, Title, Link, Text } = Typography;
import { getTextAndLinks } from "../TextBubbleHelper";
import { ClientRole } from "@Src/hubs/chat/dtos/ClientRole";

interface SmartIncludeChatBubbleProps {
    textOrLinkArray: Array<TextOrLinkData>;
    senderRole: ClientRole;
    url?: string;
    image: string;
    title: string;
    description: string;
}

const SmartIncludeChatBubble = ({
    textOrLinkArray,
    senderRole,
    url,
    image,
    title,
    description,
}: SmartIncludeChatBubbleProps): JSX.Element => {
    const handleViewButtonClick = (): void => {
        window.open(url, "_blank");
    };

    return (
        <Space
            className="chat-bubble chat-bubble--extra-padding"
            direction="vertical"
            size={0}
        >
            <Text className="chat-bubble__smart-include__heading-text-link">
                {getTextAndLinks(senderRole, textOrLinkArray)}
            </Text>
            <Space size={0} className="chat-bubble__smart-include">
                <Image
                    className="chat-bubble__smart-include__image-preview-link"
                    src={image}
                    width={100}
                    tabIndex={0}
                />
                <Space direction="vertical" size={0}>
                    <Link
                        className="chat-bubble__smart-include__body-text-link"
                        underline={false}
                        href={url}
                        target="_blank"
                    >
                        {<Title level={3}>{title}</Title>}
                    </Link>
                    <Paragraph
                        className="chat-bubble__smart-include__description"
                        data-testid="smart-include-description"
                    >
                        <span
                            data-testid="smartIncludeChatBubble-text"
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </Paragraph>
                    <Button type="primary" onClick={handleViewButtonClick}>
                        View Item
                    </Button>
                </Space>
            </Space>
        </Space>
    );
};

export default SmartIncludeChatBubble;
