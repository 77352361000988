import { configureStore } from "@reduxjs/toolkit";

import customerAppReducer from "./customerAppSlice";

// Automatically turns on redux devtools extension, thunk middleware, and development checks
export const store = configureStore({
    reducer: {
        customerApp: customerAppReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;
