import React, { useState } from "react";
import { Space, Typography, Collapse, Card, Radio } from "antd";
import { CustomDialogCommand } from "@Models/chatBot/CustomDialogCommand";
import { individual } from "../Shared/Definitions";
import { ListItemText } from "../Shared/ItemIssuesTypes";
import SelectableListItem from "../Shared/List/ListItem/SelectableListItem";
import CancelledRequestMessage from "../Shared/CancelledRequestMessage";
import SelectableList from "../Shared/List/SelectableList";
import ResolutionList from "../Shared/List/ResolutionList";
import ResolutionListItem from "../Shared/List/ListItem/ResolutionListItem";
import SubmitButtonWithPopconfirm from "../Shared/Buttons/SubmitButtonWithPopconfirm";
import CancelButtonWithPopconfirm from "../Shared/Buttons/CancelButtonWithPopconfirm";
import ClearItemsButtonWithPopconfirm from "../Shared/Buttons/ClearItemsButtonWithPopconfirm";
import EstimatedRefundStoreCredit from "../Shared/Footers/EstimatedRefundStoreCredit";
import ReshipmentAtNoCost from "../Shared/Footers/ReshipmentAtNoCost";
import EstimatedRefundOriginalPayment from "../Shared/Footers/EstimatedRefundOriginalPayment";

import "./ItemRefundReship.less";
import DropDownSelectBox from "../Shared/DropDownSelectBox/DropDownSelectBox";
import TextArea from "antd/lib/input/TextArea";

const { Text } = Typography;
const { Panel } = Collapse;

const ItemSelectedWithZeroQuantityAlert = (): React.JSX.Element => {
    return (
        <Card className="ant-card-error">
            <Text>
                Please enter the quantity of the item above and indicate if the
                quantity applies to individual items or the entire unit sold, if
                applicable
            </Text>
        </Card>
    );
};

const ItemRefundReship = ({
    chatId,
    messageId,
    orderInfo,
    isReadOnly,
    resolutionsInfo,
    isCancelClicked,
    webchatSendMessageBack,
    dboChatId,
}: any): React.JSX.Element => {
    const isDamagedIssue = orderInfo.issue === "Damaged";
    const [resolutionList, setResolutionList] = useState<any[]>(
        resolutionsInfo
            ? [
                  ...resolutionsInfo.items.map((item: any) => ({
                      ...item,
                      returnType: item.isBreakdownItemQuantity
                          ? individual
                          : item.returnType,
                  })),
              ]
            : []
    );

    if (resolutionsInfo?.items?.length > 0 && resolutionList.length === 0) {
        setResolutionList([
            ...resolutionsInfo.items.map((item: any) => ({
                ...item,
                returnType: item.isBreakdownItemQuantity
                    ? individual
                    : item.returnType,
            })),
        ]);
    }

    const eligibleDamageCategories =
        isDamagedIssue && orderInfo.items?.length > 0
            ? [...orderInfo.items[0].eligibleDamageCategoryOptions]
            : [];
    const eligibleDamageLevels =
        isDamagedIssue && orderInfo.items?.length > 0
            ? [...orderInfo.items[0].eligibleDamageLevelOptions]
            : [];

    const [itemList, setItemList] = useState([
        ...orderInfo.items.map((item: any) => ({
            ...item,
            isChecked: false,
            resolutionQuantity: 0,
            returnType: null,
        })),
    ]);
    const [resolutionTypes, setResolutionTypes] = useState<any>({
        refund: false,
        storeCredit: false,
        originalPayment: false,
        reshipment: false,
    });
    const [formButtonClicked, setFormButtonClicked] = useState<string | null>(
        null
    );
    const [resolutionTypeSelected, setResolutionTypeSelected] =
        useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    let totalPrice = 0;
    const costPerItem = (item: any): number =>
        item?.price / item?.unitsPerPackaging;

    resolutionList
        .map((item) => {
            if (
                (item.unitsPerPackaging > 1 &&
                    item.returnType === individual) ||
                item.isBreakdownItemQuantity
            ) {
                return item.resolutionQuantity * costPerItem(item);
            } else {
                return item.resolutionQuantity * item.price;
            }
        })
        .forEach((num) => (totalPrice += num));

    const PaymentCredit = "Payment Credit";
    const StoreCredit = "Store Credit";
    const Reshipment = "Reshipment";

    const CancelCommand =
        CustomDialogCommand.CustomDialogCommandType.RefundReshipCancelCommand;
    const SubmitCommand =
        CustomDialogCommand.CustomDialogCommandType.RefundReshipSubmitCommand;

    const isRequestedResolutionValid = (resolution: string): boolean => {
        return itemList.some(
            (x) =>
                x.resolutions.some((y: string) => y === resolution) &&
                x.quantity > 0
        );
    };

    const isPaymentCreditValid = isRequestedResolutionValid(PaymentCredit);

    const isStoreCreditValid = isRequestedResolutionValid(StoreCredit);

    const isReshipValid = isRequestedResolutionValid(Reshipment);

    const resolutionTypeName = (): string | undefined => {
        switch (true) {
            case resolutionTypes.reshipment:
                return Reshipment;
            case resolutionTypes.storeCredit:
                return StoreCredit;
            case resolutionTypes.originalPayment:
                return PaymentCredit;
            default:
                return undefined;
        }
    };

    if (
        resolutionsInfo &&
        resolutionsInfo.resolutionTypeName !== resolutionTypeName()
    ) {
        switch (resolutionsInfo.resolutionTypeName) {
            case Reshipment:
                setResolutionTypes({
                    refund: false,
                    storeCredit: false,
                    originalPayment: false,
                    reshipment: true,
                });
                break;
            case PaymentCredit:
                setResolutionTypes({
                    refund: true,
                    storeCredit: false,
                    originalPayment: true,
                    reshipment: false,
                });
                break;
            case StoreCredit:
                setResolutionTypes({
                    refund: true,
                    storeCredit: true,
                    originalPayment: false,
                    reshipment: false,
                });
                break;
            default:
                break;
        }
    }

    const readOnlyFooterDetail = (): React.JSX.Element | undefined => {
        if (!isDamagedIssue) {
            switch (resolutionTypeName()) {
                case Reshipment:
                    return <ReshipmentAtNoCost />;
                case PaymentCredit:
                    return (
                        <EstimatedRefundOriginalPayment
                            totalPrice={totalPrice}
                        />
                    );
                case StoreCredit:
                    return (
                        <EstimatedRefundStoreCredit totalPrice={totalPrice} />
                    );
                default:
                    return undefined;
            }
        } else {
            let damageTypeMessage = "";
            let damageSeverityMessage = "";
            const damageCategory = eligibleDamageCategories.find(
                (x) =>
                    x.damageCategoryId === resolutionList[0]?.damageCategoryId
            )?.name;
            const damageSeverity = eligibleDamageLevels.find(
                (x) => x.damageLevelId === resolutionList[0]?.damageLevelId
            )?.name;
            const damageComment = resolutionList[0]?.damageComment;
            if (damageCategory) {
                damageTypeMessage = damageComment
                    ? `${damageCategory} - ${damageComment}.`
                    : `${damageCategory}.`;
            }
            if (damageSeverity) {
                damageSeverityMessage = `${damageSeverity}.`;
            }
            switch (resolutionTypeName()) {
                case Reshipment:
                    return (
                        <div className="refund-reship-dialog-damage-details">
                            <Text
                                className="refund-reship-dialog-damage-details-type-message"
                                data-testid="refund-reship-dialog-damage-details-type-message"
                            >
                                {damageTypeMessage}
                            </Text>
                            <br />
                            <Text
                                className="refund-reship-dialog-damage-details-severity-message"
                                data-testid="refund-reship-dialog-damage-details-severity-message"
                            >
                                {damageSeverityMessage}
                            </Text>
                            <br />
                            <ReshipmentAtNoCost />
                        </div>
                    );
                case PaymentCredit:
                    return (
                        <div className="refund-reship-dialog-damage-details">
                            <Text
                                className="refund-reship-dialog-damage-details-type-message"
                                data-testid="refund-reship-dialog-damage-details-type-message"
                            >
                                {damageTypeMessage}
                            </Text>
                            <br />
                            <Text
                                className="refund-reship-dialog-damage-details-severity-message"
                                data-testid="refund-reship-dialog-damage-details-severity-message"
                            >
                                {damageSeverityMessage}
                            </Text>
                            <br />
                            <EstimatedRefundOriginalPayment
                                totalPrice={totalPrice}
                            />
                        </div>
                    );
                case StoreCredit:
                    return (
                        <div className="refund-reship-dialog-damage-details">
                            <Text
                                className="ant-space-item refund-reship-dialog-damage-details-type-message"
                                data-testid="refund-reship-dialog-damage-details-type-message"
                            >
                                {damageTypeMessage}
                            </Text>
                            <br />
                            <Text
                                className="refund-reship-dialog-damage-details-severity-message"
                                data-testid="refund-reship-dialog-damage-details-severity-message"
                            >
                                {damageSeverityMessage}
                            </Text>
                            <br />
                            <EstimatedRefundStoreCredit
                                totalPrice={totalPrice}
                            />
                        </div>
                    );
                default:
                    return undefined;
            }
        }
    };

    const hasCheckedItemWithZeroQuantity = (): boolean => {
        return itemList.some(
            (item) =>
                item.isChecked &&
                (item.resolutionQuantity === 0 ||
                    (item.unitsPerPackaging > 1 &&
                        (item.returnType === null ||
                            item.returnType === undefined)))
        );
    };

    const hasMissingDamageCategoryOrSeverity = (): boolean => {
        return (
            resolutionList.length > 0 &&
            (!resolutionList[0]?.damageCategoryId ||
                !resolutionList[0]?.damageLevelId ||
                (resolutionList[0]?.damageCategoryId ===
                    eligibleDamageCategories.find(
                        (item: any) => item.name === "Other"
                    )?.damageCategoryId &&
                    (resolutionList[0]?.damageComment === "" ||
                        resolutionList[0]?.damageComment === undefined)))
        );
    };

    const damagedIssueText = "Damaged";
    const missingOrderOrItemsIssueText = "Lost";

    const getListItemText = (issue: string): ListItemText => {
        switch (issue) {
            case damagedIssueText:
                return {
                    QuantityHeader: (
                        <>
                            Quantity Damaged{" "}
                            <span className="parenthetical-text">
                                (Required)
                            </span>
                        </>
                    ),
                    QuantityWarningUnitsPerPackagingEqualOne:
                        "Please enter the quantity of the damaged item.",
                    QuantityWarningUnitsPerPackagingGreaterThanOne:
                        "Please enter the number of damaged items and indicate if the damage applies to individual items or the entire unit sold.",
                };
            case missingOrderOrItemsIssueText:
                return {
                    QuantityHeader: (
                        <>
                            Quantity Missing{" "}
                            <span className="parenthetical-text">
                                (Required)
                            </span>
                        </>
                    ),
                    QuantityWarningUnitsPerPackagingEqualOne:
                        "Please enter the quantity of the missing item.",
                    QuantityWarningUnitsPerPackagingGreaterThanOne:
                        "Please enter the number of missing items and indicate if the missing items are individual items or the entire unit sold.",
                };
            default:
                return {
                    QuantityHeader: (
                        <>
                            Quantity{" "}
                            <span className="parenthetical-text">
                                (Required)
                            </span>
                        </>
                    ),
                    QuantityWarningUnitsPerPackagingEqualOne:
                        "Please enter the quantity of the item.",
                    QuantityWarningUnitsPerPackagingGreaterThanOne:
                        "Please enter the number of items and indicate if the number applies to individual items or the entire unit sold.",
                };
        }
    };

    const listItemText = getListItemText(orderInfo.issue);

    const itemListRenderItem = (item: any): React.JSX.Element => (
        <SelectableListItem
            item={item}
            resolutionList={resolutionList}
            setResolutionList={setResolutionList}
            itemList={itemList}
            setItemList={setItemList}
            setFormButtonClicked={setFormButtonClicked}
            readOnly={isReadOnly}
            isSubmitting={isSubmitting}
            quantityHeader={listItemText.QuantityHeader}
            quantityWarningTextUnitsPerPackagingEqualsOne={
                listItemText.QuantityWarningUnitsPerPackagingEqualOne
            }
            quantityWarningTextUnitsPerPackagingGreaterThanOne={
                listItemText.QuantityWarningUnitsPerPackagingGreaterThanOne
            }
            allowIndividualItems={true}
            test-dataid="refund-reship-dialog-selectable-list-item"
            orderInfo={orderInfo}
        />
    );

    const resolutionListRenderItem = (
        item: any,
        index: number
    ): React.JSX.Element => {
        return (
            <ResolutionListItem
                resolutionList={resolutionList}
                itemList={itemList}
                item={item}
                index={index}
                costPerItem={costPerItem}
                isReadOnly={isReadOnly}
                isSubmitting={isSubmitting}
                setResolutionList={setResolutionList}
                test-dataid="refund-reship-dialog-resolution-list-item"
            />
        );
    };

    const refundOnClickHandler = (): void => {
        setResolutionTypes({
            refund: isPaymentCreditValid || isStoreCreditValid,
            storeCredit: false,
            originalPayment: false,
            reshipment: false,
        });
        setResolutionTypeSelected(true);
    };

    const storeCreditOnClickHandler = (): void => {
        setResolutionTypes({
            refund: isPaymentCreditValid || isStoreCreditValid,
            storeCredit: isStoreCreditValid,
            originalPayment: false,
            reshipment: false,
        });
        setResolutionTypeSelected(true);
    };

    const originalPaymentOnClickHandler = (): void => {
        setResolutionTypes({
            refund: isPaymentCreditValid || isStoreCreditValid,
            storeCredit: false,
            originalPayment: isPaymentCreditValid,
            reshipment: false,
        });
        setResolutionTypeSelected(true);
    };

    const reshipmentOnClickHandler = (): void => {
        setResolutionTypes({
            refund: false,
            storeCredit: false,
            originalPayment: false,
            reshipment: isReshipValid,
        });
        setResolutionTypeSelected(true);
    };

    const reshipRadioButton = (
        <Radio
            value="reshipment"
            onClick={reshipmentOnClickHandler}
            checked={resolutionTypes.reshipment}
            disabled={isSubmitting}
        >
            Reshipment
        </Radio>
    );

    const originalPaymentRadioButton = (
        <Radio
            value="originalPayment"
            onClick={originalPaymentOnClickHandler}
            checked={resolutionTypes.originalPayment}
            disabled={isSubmitting}
        >
            Original Payment
        </Radio>
    );

    const storeCreditRadioButton = (
        <Radio
            value="storeCredit"
            onClick={storeCreditOnClickHandler}
            checked={resolutionTypes.storeCredit}
            disabled={isSubmitting}
        >
            Store Credit
        </Radio>
    );

    const refundRadioButton = (
        <Radio
            value="refund"
            onClick={refundOnClickHandler}
            checked={resolutionTypes.refund}
            disabled={isSubmitting}
        >
            Refund
        </Radio>
    );

    const submitButtonOnClickHandler = (): void => {
        if (
            resolutionList.length === 0 ||
            !resolutionTypeSelected ||
            (resolutionTypes.refund === true &&
                resolutionTypes.storeCredit === false &&
                resolutionTypes.originalPayment === false) ||
            eligibleDamageCategories.find(
                (x) =>
                    x.damageCategoryId === resolutionList[0]?.damageCategoryId
            ) === undefined ||
            ((resolutionList[0]?.damageComment === "" ||
                resolutionList[0]?.damageComment === undefined) &&
                resolutionList[0]?.damageCategoryId ===
                    eligibleDamageCategories.find(
                        (item: any) => item.name === "Other"
                    )?.damageCategoryId) ||
            eligibleDamageLevels.find(
                (x) => x.damageLevelId === resolutionList[0]?.damageLevelId
            ) === undefined ||
            hasCheckedItemWithZeroQuantity() ||
            (isDamagedIssue && hasMissingDamageCategoryOrSeverity())
        ) {
            setFormButtonClicked("submit");
        }
    };

    const clearItemsButtonPopconfirmOnConfirmHandler = (): void => {
        const updatedList = itemList.map((listItem) => {
            return {
                ...listItem,
                isChecked: false,
                resolutionQuantity: 0,
                damageComment: undefined,
                damageCategoryId: undefined,
                damageLevelId: undefined,
            };
        });

        setResolutionTypes({
            refund: false,
            storeCredit: false,
            originalPayment: false,
            reshipment: false,
        });
        setResolutionTypeSelected(false);
        setFormButtonClicked(null);

        setItemList([...updatedList]);
        setResolutionList([]);
    };

    const clearItemsButtonOnClickHandler = (): void => {
        if (
            resolutionList.length === 0 &&
            !itemList.find((item) => item.isChecked === true)
        ) {
            setFormButtonClicked("clear");
        }
    };

    const cancelButtonPopconfirmOnConfirmHandler = (): void => {
        webchatSendMessageBack(
            {
                command: CancelCommand,
                payload: {
                    messageId: messageId,
                    chatId: chatId,
                    dboChatId: dboChatId,
                },
            },
            ""
        );
    };

    const submitButtonPopconfirmOnConfirmHandler = (): void => {
        const damageLevelId = resolutionList[0]?.damageLevelId;
        const damageCategoryId = resolutionList[0]?.damageCategoryId;
        const damageComment = resolutionList[0]?.damageComment;
        setIsSubmitting(true);
        setFormButtonClicked(null);
        webchatSendMessageBack(
            {
                command: SubmitCommand,
                payload: {
                    messageId: messageId,
                    chatId: chatId,
                    resolution: {
                        orderNumber: orderInfo.orderNumber,
                        itemIssueName: orderInfo.issue,
                        resolutionTypeName: resolutionTypeName(),
                        resolutionItems: resolutionList.map((item: any) => ({
                            clarkitbizItemNumberId: item.clarkitbizItemNumberId,
                            quantity: item.resolutionQuantity,
                            clarkitbizOrderItemId: item.clarkitbizOrderItemId,
                            resolutionPrice: item.price,
                            isBreakdownItemQuantity:
                                item.unitsPerPackaging > 1 &&
                                item.returnType === individual,
                            damageLevelId: damageLevelId,
                            damageCategoryId: damageCategoryId,
                            damageComment: damageComment,
                        })),
                        relatedChatId: dboChatId,
                    },
                    dboChatId: dboChatId,
                },
            },
            ""
        );
    };

    const isSubmitButtonPopconfirmDisabled =
        isSubmitting ||
        resolutionList.length === 0 ||
        !resolutionTypeSelected ||
        (resolutionTypes.refund &&
            !resolutionTypes.storeCredit &&
            !resolutionTypes.originalPayment) ||
        hasCheckedItemWithZeroQuantity() ||
        (isDamagedIssue && hasMissingDamageCategoryOrSeverity());

    const shouldShowRefundSelectionMessage =
        !isReadOnly &&
        formButtonClicked === "submit" &&
        resolutionList.length > 0 &&
        resolutionTypes.refund === true &&
        resolutionTypes.storeCredit === false &&
        resolutionTypes.originalPayment === false;

    const shouldShowZeroQuantityAlert =
        !isReadOnly &&
        formButtonClicked === "submit" &&
        hasCheckedItemWithZeroQuantity() &&
        resolutionList.length > 0;

    const showRefundTypeRadioButtons = (
        <Space direction="vertical" size={4} style={{ marginLeft: "16px" }}>
            {isStoreCreditValid && storeCreditRadioButton}
            {isPaymentCreditValid && originalPaymentRadioButton}
        </Space>
    );

    const showSelectResolutionItemsErrorCard = (
        <Card
            className={`${
                resolutionList.length === 0 &&
                formButtonClicked !== null &&
                "ant-card-error"
            }`}
        >
            {!isReadOnly && (
                <Text>Please select items above to enter a resolution.</Text>
            )}
        </Card>
    );

    const showResolutionRadioButtons = (
        <Space direction="vertical" size={4} style={{ margin: "8px 0 12px" }}>
            {(isPaymentCreditValid || isStoreCreditValid) && refundRadioButton}
            {resolutionTypes.refund === true && showRefundTypeRadioButtons}
            {isReshipValid && reshipRadioButton}
        </Space>
    );

    const commentMaxLength = 100;

    const screenReaderOnlySpan = (
        currentLength: number,
        maxLength: number
    ): JSX.Element => {
        return (
            <span className="sr-only" aria-live="polite" aria-atomic="true">
                {currentLength} of {maxLength} characters used
            </span>
        );
    };

    const shouldShowResolutionSelectionMessage =
        !isReadOnly &&
        !resolutionTypeSelected &&
        formButtonClicked === "submit" &&
        resolutionList.length > 0;

    const refundReshipUI = (
        <Space size={0} className="item-refund">
            {!isReadOnly && (
                <Text>
                    Below is the list of items from your order. Please check the
                    item(s) below, review, and submit your resolution.
                </Text>
            )}
            <Text strong style={{ margin: "16px 0", display: "inline-block" }}>
                Order # {orderInfo.orderNumber}
            </Text>
            {!isReadOnly && (
                <Collapse
                    defaultActiveKey={["1"]}
                    className="item-refund__collapse"
                    aria-hidden="true"
                >
                    <Panel
                        header={`${itemList.length} Item(s) in Order`}
                        key="1"
                    >
                        <SelectableList
                            itemList={itemList}
                            itemListRenderItem={itemListRenderItem}
                        />
                    </Panel>
                </Collapse>
            )}
            {(!isReadOnly || resolutionList.length > 0) && (
                <Card className="selected-items">
                    <Text strong>Items & Preferred Resolutions</Text>
                    {resolutionList.length < 1 ? (
                        !isReadOnly && showSelectResolutionItemsErrorCard
                    ) : (
                        <ResolutionList
                            resolutionList={resolutionList}
                            totalPrice={totalPrice}
                            isReadOnly={isReadOnly}
                            readOnlyFooterDetail={readOnlyFooterDetail()}
                            resolutionListRenderItem={resolutionListRenderItem}
                        />
                    )}
                    {shouldShowZeroQuantityAlert && (
                        <ItemSelectedWithZeroQuantityAlert />
                    )}
                    {isDamagedIssue &&
                        !isReadOnly &&
                        resolutionList.length > 0 && (
                            <div className="refund-reship-dialog-damage">
                                <DropDownSelectBox
                                    className="refund-reship-dialog-damage-type"
                                    label={
                                        <>
                                            Type of Damage{" "}
                                            <span className="parenthetical-text">
                                                (Required)
                                            </span>
                                        </>
                                    }
                                    options={eligibleDamageCategories.map(
                                        (item: any) => ({
                                            value: item.damageCategoryId,
                                            label: item.name,
                                        })
                                    )}
                                    value={resolutionList[0]?.damageCategoryId}
                                    onChange={(value: string): void => {
                                        const updatedList = [...resolutionList];
                                        updatedList.map((item) => {
                                            item.damageCategoryId = value;
                                        });
                                        setResolutionList(updatedList);
                                    }}
                                    placeholder="Select the type of damage"
                                    data-testid="refund-reship-dialog-damage-type-dropdown"
                                    isReadOnly={isReadOnly}
                                    isSubmitting={isSubmitting}
                                    validate={(): boolean => {
                                        return !(
                                            resolutionList.length > 0 &&
                                            eligibleDamageCategories.find(
                                                (x) =>
                                                    x.damageCategoryId ===
                                                    resolutionList[0]
                                                        ?.damageCategoryId
                                            ) === undefined &&
                                            formButtonClicked === "submit"
                                        );
                                    }}
                                    validationFailedMessage="Please select the damage type."
                                />
                                <div
                                    data-testid="refund-reship-dialog-damage-comment-container"
                                    className="refund-reship-dialog-damage-comment-container"
                                >
                                    <Text data-testid="refund-reship-dialog-damage-comment-text">
                                        Details of the damage.{" "}
                                        <span className="parenthetical-text">
                                            (
                                            {resolutionList[0]
                                                ?.damageCategoryId ===
                                            eligibleDamageCategories.find(
                                                (item: any) =>
                                                    item.name === "Other"
                                            )?.damageCategoryId
                                                ? "Required"
                                                : "Optional"}
                                            )
                                        </span>
                                    </Text>
                                    <TextArea
                                        placeholder="Add details of the damage."
                                        maxLength={commentMaxLength}
                                        data-testid="refund-reship-dialog-damage-comment-input"
                                        showCount
                                        value={resolutionList[0]?.damageComment}
                                        onChange={(e): void => {
                                            const updatedList = [
                                                ...resolutionList,
                                            ];
                                            updatedList.map((item) => {
                                                item.damageComment =
                                                    e.target.value;
                                            });
                                            setResolutionList(updatedList);
                                        }}
                                        disabled={isSubmitting}
                                    />
                                    {screenReaderOnlySpan(
                                        resolutionList[0]?.damageComment
                                            ?.length,
                                        commentMaxLength
                                    )}
                                    {(resolutionList[0]?.damageComment === "" ||
                                        resolutionList[0]?.damageComment ===
                                            undefined) &&
                                        resolutionList[0]?.damageCategoryId ===
                                            eligibleDamageCategories.find(
                                                (item: any) =>
                                                    item.name === "Other"
                                            )?.damageCategoryId &&
                                        formButtonClicked === "submit" && (
                                            <Text
                                                type="danger"
                                                style={{ marginBottom: "12px" }}
                                                aria-live="polite"
                                                data-testid="refund-reship-dialog-damage-comment-missing-error"
                                            >
                                                Please add details of the
                                                damage.
                                            </Text>
                                        )}
                                    {resolutionList[0]?.damageComment?.length >=
                                        commentMaxLength && (
                                        <Text
                                            type="danger"
                                            style={{ marginBottom: "12px" }}
                                            aria-live="assertive"
                                            data-testid="refund-reship-dialog-damage-comment-length-error"
                                        >
                                            Please limit character count to{" "}
                                            {commentMaxLength}.
                                        </Text>
                                    )}
                                </div>
                                <DropDownSelectBox
                                    className="refund-reship-dialog-damage-severity"
                                    label={
                                        <>
                                            Severity of Damage{" "}
                                            <span className="parenthetical-text">
                                                (Required)
                                            </span>
                                        </>
                                    }
                                    options={eligibleDamageLevels.map(
                                        (item: any) => ({
                                            value: item.damageLevelId,
                                            label: item.name,
                                        })
                                    )}
                                    value={resolutionList[0]?.damageLevelId}
                                    onChange={(value: string): void => {
                                        const updatedList = [...resolutionList];
                                        updatedList.map((item) => {
                                            item.damageLevelId = value;
                                        });
                                        setResolutionList(updatedList);
                                    }}
                                    placeholder="Select the severity of damage"
                                    data-testid="refund-reship-dialog-damage-severity-dropdown"
                                    isReadOnly={isReadOnly}
                                    isSubmitting={isSubmitting}
                                    validationFailedMessage="Please select the severity of the damage."
                                    validate={(): boolean => {
                                        return !(
                                            resolutionList.length > 0 &&
                                            eligibleDamageLevels.find(
                                                (x) =>
                                                    x.damageLevelId ===
                                                    resolutionList[0]
                                                        ?.damageLevelId
                                            ) === undefined &&
                                            formButtonClicked === "submit"
                                        );
                                    }}
                                />
                            </div>
                        )}
                    {!isReadOnly && (
                        <Text style={{ display: "block" }}>
                            Select your resolution preference. (Required)
                        </Text>
                    )}
                    {!isReadOnly && showResolutionRadioButtons}
                    {shouldShowResolutionSelectionMessage && (
                        <Text type="danger" style={{ marginBottom: "12px" }}>
                            Please select your resolution preference.
                        </Text>
                    )}
                    {shouldShowRefundSelectionMessage && (
                        <Text
                            type="danger"
                            style={{ marginBottom: "12px" }}
                            aria-live="polite"
                        >
                            Please select Store Credit or Original payment for
                            your Refund.
                        </Text>
                    )}
                    {!isReadOnly && (
                        <ClearItemsButtonWithPopconfirm
                            isSubmitting={isSubmitting}
                            clearItemsButtonPopconfirmOnConfirmHandler={
                                clearItemsButtonPopconfirmOnConfirmHandler
                            }
                            clearItemsButtonOnClickHandler={
                                clearItemsButtonOnClickHandler
                            }
                            itemList={itemList}
                            isReadOnly={isReadOnly}
                            data-testId="refund-reship-dialog-clear-items-button"
                        />
                    )}
                    {!isReadOnly && (
                        <CancelButtonWithPopconfirm
                            isSubmitting={isSubmitting}
                            cancelButtonPopconfirmOnConfirmHandler={
                                cancelButtonPopconfirmOnConfirmHandler
                            }
                            data-testId="refund-reship-dialog-cancel-button"
                        />
                    )}
                    {!isReadOnly && (
                        <SubmitButtonWithPopconfirm
                            isSubmitting={isSubmitting}
                            isSubmitButtonPopconfirmDisabled={
                                isSubmitButtonPopconfirmDisabled
                            }
                            submitButtonPopconfirmOnConfirmHandler={
                                submitButtonPopconfirmOnConfirmHandler
                            }
                            submitButtonOnClickHandler={
                                submitButtonOnClickHandler
                            }
                            data-testId="refund-reship-dialog-submit-button"
                        />
                    )}
                </Card>
            )}
        </Space>
    );

    return (
        <div
            className="item-issue-refund-reship item-issue-dialog"
            data-testid="refund-reship-dialog"
        >
            {isCancelClicked && (
                <CancelledRequestMessage data-testid="refund-reship-dialog-cancelled-request-message" />
            )}
            {!isCancelClicked && refundReshipUI}
        </div>
    );
};

export default ItemRefundReship;
