import { SwitchboardConfig } from "@Models/SwitchboardConfig";
import { AxiosInstance } from "axios";
import { SwitchboardApiBase } from "./SwitchboardApiBase";
import { CustomerModel } from "@Models/customer/CustomerModel";
import { CustomerWelcomeResponse } from "./dtos/customer/CustomerWelcomeResponse";

export class CustomerApi extends SwitchboardApiBase {
    constructor(config: SwitchboardConfig, axios: AxiosInstance) {
        super(config, axios);
    }

    public getPlatinumStatusByUserIndexForCustomer(
        encryptedUserIndex: string
    ): Promise<boolean> {
        return this.axios
            .get(
                this.buildSwitchboardApiUrl("Customer/platinumStatusByIndex"),
                {
                    params: {
                        encryptedUserIndex,
                    },
                }
            )
            .then((response) => response.data);
    }

    public getCustomerByUserIndexForCustomer(
        encryptedUserIndex: string
    ): Promise<CustomerWelcomeResponse> {
        return this.axios
            .get(this.buildSwitchboardApiUrl("Customer/byIndex"), {
                params: {
                    encryptedUserIndex,
                },
            })
            .then((response) => response.data);
    }

    public getCustomerByEmail(
        emailAddress: string,
        chatId: string
    ): Promise<CustomerModel> {
        return this.axios
            .get(this.buildSwitchboardApiUrl("Customer/byEmail"), {
                params: { emailAddress, chatId },
            })
            .then((response) => response.data);
    }

    public getCustomerByOrderId(
        orderId: number,
        chatId: string
    ): Promise<CustomerModel> {
        return this.axios
            .get(this.buildSwitchboardApiUrl("Customer/byOrderId"), {
                params: { orderId, chatId },
            })
            .then((response) => response.data);
    }

    public async setConnection(tabId: string): Promise<boolean> {
        try {
            const response = await this.axios.post(
                this.buildSwitchboardApiUrl("Customer/setConnection"),
                {
                    tabId: tabId,
                }
            );
            return response.data;
        } catch (error) {
            console.error("Error setting customer connection.", error);
            throw Error("Error setting customer connection");
        }
    }

    public heartbeat(tabId: string): Promise<boolean> {
        return this.axios
            .post(this.buildSwitchboardApiUrl("Customer/heartbeat"), {
                tabId: tabId,
            })
            .then(() => true)
            .catch((error) => {
                console.warn(
                    "Heartbeat failed - potential extra connection was detected.",
                    error
                );
                return false;
            });
    }
}
