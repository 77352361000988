import "@Styles/App.less";
import "./CustomerApp.less";
import trsLogo from "@Images/trs-full.svg";
import marketplaceLogo from "@Images/marketplace-logo-full.png";
import qsLogo from "@Images/qs-logo.svg";
import wssLogo from "@Images/wss-logo.svg";
import rkwLogo from "@Images/rkw-logo-full.svg";
import { useCallback, useEffect, useRef } from "react";
import { Button, Layout, Spin, Image } from "antd";
const { Content, Header } = Layout;
import WelcomeView from "@Components/CustomerApp/WelcomeView/WelcomeView";
import UnavailableView from "@Components/CustomerApp/UnavailableView/UnavailableView";
import ConversationView from "@Components/shared/ConversationView/ConversationView";
import ChatFeedback from "@Components/CustomerApp/Feedback/LiveChatFeedback/LiveChatFeedback";
import ParkedView from "@Components/CustomerApp/ParkedView/ParkedView";
import { isScrolledToBottom } from "@Src/utilities/ScrollHelper";
import { CreateChatResponse } from "@Api/dtos/chat/CreateChatResponse";
import { PersistedMessage } from "@Hubs/chat/dtos/PersistedMessage";
import { TextMessage } from "@Hubs/chat/dtos/TextMessage";
import { FailedTextMessage } from "@Models/chat/FailedTextMessage";
import { ClientRole } from "@Hubs/chat/dtos/ClientRole";
import { QueryParams } from "@Utilities/QueryParams";
import { getQueryParam } from "@Utilities/QueryStringHelper";
import { IssueInformationModel } from "../../models/chat/IssueInformationModel";
import {
    textMessageFailed,
    endedChat,
    viewStateChanged,
    applicationSettingsGathered,
    retrievedFeedbackReasons,
    connectivityMessageDisplayed,
    connectivityMessageRemoved,
    receivedMessageToDelete,
    updateIsScrolledToBottom,
    chatReceived,
} from "./customerAppSlice";
import { useAppDispatch, useAppSelector } from "./hooks";
import {
    setChatHubClosedHandler,
    startChatHub,
    sendChatHubRequest,
} from "@Src/hubs/chat/ChatHub";
import { ChatConnectionState } from "@Src/hubs/chat/ChatConnectionState";
import BotConversationView from "@Components/CustomerApp/BotConversationView/BotConversationView";
import ChatClosedView from "@Components/CustomerApp/ChatClosedView/ChatClosedView";
import "./hubHandlers";
import { ViewState } from "./viewState";
import BotChatFeedback from "./Feedback/BotChatFeedback/BotChatFeedback";
import { setMessageDelay } from "@Src/services/customerApp/OperatorLeftDelayService";
import { NoChatNextView } from "@Models/chat/NoChatNextView";
import ChatTimedOutView from "./ChatTimedOutView/ChatTimedOutView";
import { getTabId } from "@Utilities/Startup";
import {
    clearHeartbeatTickInterval,
    startHeartbeatTicking,
} from "@Utilities/Heartbeat";
import { getInstance } from "@Src/services/shared/DeployService";
import ExistingTabView from "./ExistingTabView/ExistingTabView";
import { ChatHubRequestTypes } from "@Src/hubs/chat/ChatHubRequestTypes";
import { ScrollValues } from "@Models/chat/ScrollValues";
import {
    handleToolbarMargin,
    onVisualViewportChange,
    onWindowChange,
    setFractionalHeightToDefault,
} from "@Utilities/ViewportHelper";
import UAParser from "ua-parser-js";
import { baseImageRoute } from "./CustomerImageRoute";
import { OpenChatResponse } from "@Api/dtos/chat/OpenChatResponse";
import RenderError from "@Components/shared/RenderError/RenderError";
import withChatHubDisconnect from "./WithChatHubDisconnect/WithChatHubDisconnect";
import { documentTitle } from "@Src/tenantConfiguration/SharedTenantConfiguration";
import { type } from "@Src/tenantConfiguration/CustomerTenantConfiguration";
import { loadNotificationSoundFiles } from "@Utilities/SoundNotifications";
import PlatinumUnavailableView from "./PlatinumUnavailableView/PlatinumUnavailableView";

document.title = documentTitle;

const encryptedUserIndexParam = getQueryParam(QueryParams.userIndex);
const cartCodeParam = getQueryParam(QueryParams.cartCode);
const forceChatParam = getQueryParam(QueryParams.forceChat) == "1";

const heartbeatViews = [
    ViewState.TalkingToRep,
    ViewState.Parked,
    ViewState.TalkingToBot,
];

const referringUrlParam = getQueryParam(QueryParams.referringUrl);
const trsStoreNumberParam = getQueryParam(QueryParams.storeNumber);

const App = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const customerApi = useAppSelector(
        (state) => state.customerApp.customerApi
    );
    const chatApi = useAppSelector((state) => state.customerApp.chatApi);
    const messageApi = useAppSelector((state) => state.customerApp.messageApi);
    const feedbackReasonsApi = useAppSelector(
        (state) => state.customerApp.feedbackReasonsApi
    );
    const messages = useAppSelector((state) => state.customerApp.messages);
    const typingIndicators = useAppSelector(
        (state) => state.customerApp.typingIndicators
    );
    const chat = useAppSelector((state) => state.customerApp.chat);
    const chatUser = useAppSelector((state) => state.customerApp.chatUser);
    const viewState = useAppSelector((state) => state.customerApp.viewState);
    const applicationSettingsApi = useAppSelector(
        (state) => state.customerApp.applicationSettingsApi
    );
    const fileUploadsEnabled = useAppSelector(
        (state) =>
            state.customerApp.applicationSettings?.fileUploadsEnabled ?? false
    );
    const chatHubConnectionState = useAppSelector(
        (state) => state.customerApp.chatHubConnectionState
    );
    const isMobile = useAppSelector(
        (state) => state.customerApp?.chat?.isMobile ?? false
    );

    const unreadMessageCountWhileScrolledUp = useAppSelector(
        (state) => state.customerApp?.unreadMessageCountWhileScrolledUp
    );

    const previousScrollValuesRef = useRef<ScrollValues>({
        scrollTop: 0,
        clientHeight: 0,
        scrollHeight: 0,
    });

    useEffect(() => {
        setFractionalHeightToDefault();

        const uaParser = new UAParser(window.navigator.userAgent);
        const uaInfo = {
            osName: uaParser.getOS().name,
            browserName: uaParser.getBrowser().name,
            deviceModel: uaParser.getDevice().model,
        };

        // Based on https://github.com/faisalman/ua-parser-js/issues/417
        // Some Apple phones and tablets have a user agent with 'Mac OS' as the operating system.
        // The motivation for this flag is to treat iPads the same as any other mobile device for viewport handling.
        const isAppleOS =
            uaInfo?.osName?.toLowerCase() === "ios" ||
            uaInfo?.osName?.toLowerCase() === "mac os";

        if (isMobile || isAppleOS) {
            const visualViewport = window?.visualViewport;

            if (visualViewport) {
                visualViewport.addEventListener(
                    "resize",
                    onVisualViewportChange
                );
                visualViewport.addEventListener(
                    "scroll",
                    onVisualViewportChange
                );
                onVisualViewportChange();
            } else {
                window.addEventListener("scroll", onWindowChange);
                onWindowChange();
            }

            // Handling this edge case: https://github.com/GoogleChrome/web.dev/issues/4132#issuecomment-736745282
            // Chrome on iPad is accurately detected by user agent parsing. In Chrome the iPad renders a toolbar
            // above the on-screen keyboard which covers & obscures some webpage content (the message input bar)
            if (
                uaInfo?.deviceModel?.toLowerCase() === "ipad" &&
                uaInfo?.browserName?.toLowerCase() === "chrome"
            ) {
                visualViewport?.addEventListener("resize", handleToolbarMargin);
                visualViewport?.addEventListener("scroll", handleToolbarMargin);
                window.addEventListener("scroll", handleToolbarMargin);
            }
        }
    }, [isMobile]);

    useEffect(() => {
        clearHeartbeatTickInterval();
        if (heartbeatViews.includes(viewState)) {
            startHeartbeatTicking(customerApi);
        }
    }, [viewState, customerApi]);

    const connectToChat = useCallback(
        async (
            chatResponse: CreateChatResponse | OpenChatResponse
        ): Promise<void> => {
            dispatch(chatReceived(chatResponse));

            console.log("Connecting to chat hub:", chatResponse);

            await startChatHub(false);
        },
        [dispatch]
    );

    useEffect(() => {
        const customerStart = async (): Promise<void> => {
            loadNotificationSoundFiles();
            const isChatOnlinePromise = chatApi
                .getChatOnlineStatus(forceChatParam)
                .catch((error) => {
                    console.error(
                        "Error occurred while checking if chat is online",
                        error
                    );
                    return Promise.reject(error);
                });
            const isPlatinumCustomerPromise = encryptedUserIndexParam
                ? customerApi
                      .getPlatinumStatusByUserIndexForCustomer(
                          encryptedUserIndexParam
                      )
                      .catch((error: Error) => {
                          console.error(
                              "Error occurred while checking if customer is platinum",
                              error
                          );
                          return Promise.reject(error);
                      })
                : Promise.resolve(false);
            const areOperatorsOnlinePromise = chatApi
                .getOperatorsOnlineStatus()
                .catch((error: Error) => {
                    console.error(
                        "Error occurred while checking if operators are online",
                        error
                    );
                    return Promise.reject(error);
                });
            const [
                isChatOnline,
                openChatResponse,
                applicationSettings,
                feedbackReasons,
                isCustomerPlatinum,
                areOperatorsOnline,
            ] = await Promise.all([
                isChatOnlinePromise,
                chatApi.getOpenChat(),
                applicationSettingsApi.getCustomerApplicationSettings(),
                feedbackReasonsApi.getFeedbackReasons(),
                isPlatinumCustomerPromise,
                areOperatorsOnlinePromise,
            ]).catch((error) => {
                console.error("Error while starting up.", error);
                return Promise.reject(error);
            });

            dispatch(applicationSettingsGathered(applicationSettings));

            dispatch(retrievedFeedbackReasons(feedbackReasons));

            getInstance().setTimeoutDurations(
                applicationSettings.deployBeginReconnectDurationMilliseconds,
                applicationSettings.deployTakingTooLongDurationMilliseconds
            );

            setMessageDelay(applicationSettings.operatorJoinLeftDelaySeconds);

            switch (openChatResponse.nextView) {
                case NoChatNextView.Error:
                    dispatch(viewStateChanged(ViewState.Error));
                    break;
                case NoChatNextView.PreviousChatClosed:
                    dispatch(viewStateChanged(ViewState.PreviousChatClosed));
                    break;
                case NoChatNextView.BotFeedback:
                    dispatch(chatReceived(openChatResponse));
                    dispatch(viewStateChanged(ViewState.BotFeedback));
                    break;
                case NoChatNextView.LiveFeedback: {
                    dispatch(chatReceived(openChatResponse));
                    dispatch(viewStateChanged(ViewState.RepFeedback));
                    break;
                }
                default:
                    // If the user previously had a chat that is not unjoinable, we need to re-auth to generate a new user id
                    if (openChatResponse.shouldReauthenticate) {
                        await chatApi.resetSession();
                    }

                    if (openChatResponse.hasOpenChat) {
                        const isValid = await customerApi
                            .setConnection(getTabId())
                            .catch((error) => {
                                console.error(
                                    "Error occurred while checking for customer connection",
                                    error
                                );
                                return Promise.reject();
                            });
                        if (!isValid) {
                            dispatch(
                                viewStateChanged(ViewState.HasExistingTab)
                            );
                            return;
                        }
                        await connectToChat(openChatResponse);
                    } else if (isCustomerPlatinum && !areOperatorsOnline) {
                        dispatch(
                            viewStateChanged(ViewState.PlatinumChatUnavailable)
                        );
                    } else if (!isChatOnline) {
                        dispatch(viewStateChanged(ViewState.ChatUnavailable));
                    } else {
                        dispatch(viewStateChanged(ViewState.Welcome));
                    }
                    break;
            }
        };
        customerStart().catch(() =>
            dispatch(viewStateChanged(ViewState.Error))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createGuestChat = (
        name: string,
        email: string,
        issueInformation: IssueInformationModel
    ): Promise<CreateChatResponse> => {
        return chatApi
            .createChat({
                customerName: name.trim(),
                cartCode: cartCodeParam,
                trsStoreNumber: trsStoreNumberParam,
                referringUrl: referringUrlParam,
                email: email.trim(),
                issueInformation: issueInformation,
            })
            .then((createChatResponse: CreateChatResponse) => {
                return connectToChat(createChatResponse).then(
                    () => createChatResponse
                );
            })
            .catch((error) => {
                console.error("Error while starting guest chat:", error);
                dispatch(viewStateChanged(ViewState.Error));
                throw error;
            });
    };

    const createLoggedInChat = (
        name: string,
        email: string,
        issueInformation: IssueInformationModel
    ): Promise<CreateChatResponse> => {
        if (!encryptedUserIndexParam) {
            const error = new Error(
                "Cannot create logged in chat without user index"
            );
            console.error("Error before creating logged in chat:", error);
            dispatch(viewStateChanged(ViewState.Error));
            throw error;
        }
        return chatApi
            .createLoggedInChat({
                encryptedUserIndex: encryptedUserIndexParam,
                cartCode: cartCodeParam,
                trsStoreNumber: trsStoreNumberParam,
                referringUrl: referringUrlParam,
                name: name,
                email: email,
                issueInformation: issueInformation,
            })
            .then((createChatResponse: CreateChatResponse) => {
                return connectToChat(createChatResponse).then(
                    () => createChatResponse
                );
            })
            .catch((error) => {
                console.error("Error while starting logged in chat:", error);
                dispatch(viewStateChanged(ViewState.Error));
                throw error;
            });
    };

    const sendMessageAsCustomer = (
        message: PersistedMessage
    ): Promise<string> =>
        messageApi
            .sendMessageAsCustomer(message)
            .then((response) => {
                if (message.id) {
                    dispatch(
                        receivedMessageToDelete({
                            chatId: message.chatId,
                            messageId: message.id,
                        })
                    );
                }
                return Promise.resolve(response);
            })
            .catch(() => {
                // prevent duplicates - message id assigned on first failure
                if (chat && message.id === undefined) {
                    dispatch(
                        textMessageFailed(
                            new FailedTextMessage(
                                (message as TextMessage).text,
                                message.chatId,
                                chat.customerName,
                                chat.customerUserId,
                                ClientRole.Customer
                            )
                        )
                    );
                }
                return Promise.reject();
            });

    useEffect(() => {
        setChatHubClosedHandler("index", () => {
            dispatch(viewStateChanged(ViewState.Error));
        });
    }, [dispatch]);

    useEffect(() => {
        if (chatHubConnectionState === ChatConnectionState.Reconnecting) {
            dispatch(connectivityMessageDisplayed());
        } else {
            dispatch(connectivityMessageRemoved());
        }
    }, [chatHubConnectionState, dispatch]);

    const onEndChatClicked = useCallback(async (): Promise<void> => {
        // Transition the UI immediately so user doesn't see ended chat message
        dispatch(endedChat());

        // Call the server which writes the ended chat message
        chatApi.endChat().catch(() => {
            dispatch(viewStateChanged(ViewState.Error));
        });
    }, [chatApi, dispatch]);

    const updateScrollValue = useCallback(
        (
            isManualUpdate: boolean,
            scrollRef: React.RefObject<HTMLDivElement>,
            forceToBottom: boolean
        ): void => {
            const previousScrollValues = previousScrollValuesRef.current;

            const getNewScrollTop = (): number => {
                if (!scrollRef || !scrollRef.current) {
                    return 0;
                }
                const shouldScrollToBottom =
                    !previousScrollValues.scrollHeight ||
                    isScrolledToBottom(previousScrollValues, 100);

                return shouldScrollToBottom
                    ? scrollRef.current.scrollHeight -
                          scrollRef.current.clientHeight
                    : previousScrollValues.scrollTop;
            };

            if (scrollRef.current != null) {
                if (isManualUpdate) {
                    dispatch(
                        updateIsScrolledToBottom(
                            isScrolledToBottom(scrollRef.current, 100)
                        )
                    );

                    if (forceToBottom) {
                        scrollRef.current.scrollTop =
                            scrollRef.current.scrollHeight -
                            scrollRef.current.clientHeight;
                    }
                    previousScrollValues.scrollTop =
                        scrollRef.current.scrollTop;
                } else {
                    const newScrollTop = getNewScrollTop();
                    scrollRef.current.scrollTop = newScrollTop;
                    previousScrollValues.scrollTop = newScrollTop;
                    previousScrollValues.clientHeight =
                        scrollRef.current.clientHeight;
                    previousScrollValues.scrollHeight =
                        scrollRef.current.scrollHeight;
                }
            }
        },
        [dispatch]
    );

    function renderMainContent(): JSX.Element | undefined {
        switch (viewState) {
            case ViewState.Welcome:
                return (
                    <WelcomeView
                        createGuestChat={createGuestChat}
                        createLoggedInChat={createLoggedInChat}
                        forceChatParam={forceChatParam}
                    />
                );
            case ViewState.PlatinumChatUnavailable:
                return <PlatinumUnavailableView />;
            case ViewState.ChatUnavailable:
                return <UnavailableView />;
            case ViewState.RepFeedback:
                return <ChatFeedback />;
            case ViewState.BotFeedback:
                return (
                    <BotChatFeedback
                        connectToChat={connectToChat}
                        chat={chat}
                    />
                );
            case ViewState.TalkingToRep:
                return chat && chatUser ? (
                    <ConversationView
                        chatId={chat.id}
                        messages={messages}
                        myUserId={chatUser.userId}
                        clientRole={ClientRole.Customer}
                        sendMessageAsUser={sendMessageAsCustomer}
                        chatApi={chatApi}
                        messageApi={messageApi}
                        smartIncludesEnabled={false}
                        fileUploadsEnabled={fileUploadsEnabled}
                        sendFileAsUser={(chatId, fileData): Promise<void> =>
                            messageApi.sendPhotoAsCustomer(fileData)
                        }
                        isMessageBarDisabled={
                            chatHubConnectionState ===
                            ChatConnectionState.Reconnecting
                        }
                        isChatHubConnected={
                            chatHubConnectionState ===
                            ChatConnectionState.Connected
                        }
                        typingIndicators={typingIndicators}
                        sendTypingIndicator={(chatId): void =>
                            sendChatHubRequest({
                                event: ChatHubRequestTypes.SendStartTypingIndicatorAsCustomer,
                                payload: chatId,
                            })
                        }
                        sendStopTypingIndicator={(chatId): void =>
                            sendChatHubRequest({
                                event: ChatHubRequestTypes.SendStopTypingIndicatorAsCustomer,
                                payload: chatId,
                            })
                        }
                        typingNotificationTimeoutTimeInSeconds={5}
                        updateScrollValue={updateScrollValue}
                        baseImageRoute={baseImageRoute}
                        unreadMessageCountWhileScrolledUp={
                            unreadMessageCountWhileScrolledUp
                        }
                    />
                ) : undefined;
            case ViewState.Parked:
                return <ParkedView fileUploadsEnabled={fileUploadsEnabled} />;
            case ViewState.TalkingToBot:
                return (
                    <BotConversationView
                        sendTypingIndicator={(chatId): void =>
                            sendChatHubRequest({
                                event: ChatHubRequestTypes.SendStartTypingIndicatorAsCustomer,
                                payload: chatId,
                            })
                        }
                        sendStopTypingIndicator={(chatId): void =>
                            sendChatHubRequest({
                                event: ChatHubRequestTypes.SendStopTypingIndicatorAsCustomer,
                                payload: chatId,
                            })
                        }
                        typingNotificationTimeoutTimeInSeconds={5}
                    />
                );
            case ViewState.PreviousChatClosed:
                return <ChatClosedView />;
            case ViewState.TimedOut:
                return <ChatTimedOutView />;
            case ViewState.HasExistingTab:
                return <ExistingTabView />;
            default:
                return <Spin className="main-content__spinner" size="large" />;
        }
    }

    const shouldShowEndChatButton =
        viewState == ViewState.Parked ||
        viewState == ViewState.TalkingToRep ||
        viewState == ViewState.TalkingToBot;

    return viewState == ViewState.Error ? (
        <RenderError wrapper={withChatHubDisconnect} />
    ) : (
        <Layout className="switchboard">
            <Header className="customer-header">
                {type === "wss" && (
                    <Image
                        src={wssLogo}
                        preview={false}
                        alt="WebstaurantStore logo"
                        data-testid="wss-logo"
                    />
                )}
                {type === "trs" && (
                    <Image
                        src={trsLogo}
                        preview={false}
                        alt="The Restaurant Store logo"
                        data-testid="trs-logo"
                    />
                )}
                {type === "clark-pro" && (
                    <Image
                        src={marketplaceLogo}
                        width={262}
                        preview={false}
                        alt="Clark Pro logo"
                        data-testid="cp-logo"
                    />
                )}
                {type === "cna" && (
                    <Image
                        src={qsLogo}
                        width={112}
                        preview={false}
                        alt="Clark National Accounts logo"
                        data-testid="cna-logo"
                    />
                )}
                {type === "rkw" && (
                    <Image
                        src={rkwLogo}
                        preview={false}
                        alt="Ready Kitchen Warranty logo"
                        data-testid="rkw-logo"
                    />
                )}
                {shouldShowEndChatButton && (
                    <Button
                        className="customer-header__button-outlined"
                        size="small"
                        onClick={onEndChatClicked}
                    >
                        End Chat
                    </Button>
                )}
            </Header>
            <hr />
            <Content className="main-content">{renderMainContent()}</Content>
        </Layout>
    );
};

export default App;
