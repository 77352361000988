import "./TaxFormBubble.less";
import { useState, ReactElement, useMemo } from "react";
import { Table, Space, Typography, Collapse, Button } from "antd";
import dayjs from "dayjs";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { TaxExemption } from "@Models/account/TaxExemption";

const { Text } = Typography;
const { Panel } = Collapse;

interface StatusConfig {
    sortOrder: number;
}

const statusConfigs: Record<string, StatusConfig> = {
    Approved: {
        sortOrder: 1,
    },
    Pending: {
        sortOrder: 2,
    },
    Denied: {
        sortOrder: 3,
    },
    Expired: {
        sortOrder: 4,
    },
    "More Information Needed": {
        sortOrder: 5,
    },
};
interface TaxFormBubbleProps {
    id: string;
    isForScreenReader: boolean;
    taxData: TaxExemption[] | [];
}

const TaxFormBubble = ({
    id,
    isForScreenReader,
    taxData,
}: TaxFormBubbleProps): JSX.Element => {
    const [activePanels, setActivePanels] = useState<string[] | string>([]);

    const uniqueTaxStatuses = useMemo(() => {
        // Get unique tax status values as an array sorted by the sortOrder mapped from statusConfigs[exemption.status]
        const uniqueTaxStatuses = new Set<string>();
        taxData.forEach((exemption: TaxExemption) => {
            uniqueTaxStatuses.add(exemption.Status);
        });
        return Array.from(uniqueTaxStatuses).sort(
            (aStatus: string, bStatus: string): number => {
                return (
                    statusConfigs[aStatus].sortOrder -
                    statusConfigs[bStatus].sortOrder
                );
            }
        );
    }, [taxData]);

    const columns = [
        {
            title: "State",
            dataIndex: "State",
            key: "State",
        },
        {
            title: "Exemption Type",
            dataIndex: "ExemptionType",
            key: "ExemptionType",
        },
        {
            title: "Date Uploaded",
            dataIndex: "DateUploaded",
            key: "DateUploaded",
            render: (date: Date): ReactElement => {
                if (!date) {
                    return <Text>N/A</Text>;
                } else {
                    return <Text>{dayjs(date).format("MM-DD-YYYY")}</Text>;
                }
            },
        },
        {
            title: "Expiration Date",
            dataIndex: "ExpirationDate",
            key: "ExpirationDate",
            render: (date: Date): ReactElement => {
                if (!date) {
                    return <Text>N/A</Text>;
                } else {
                    return <Text>{dayjs(date).format("MM-DD-YYYY")}</Text>;
                }
            },
        },
    ];

    return (
        <Space size={0} className="tax-form-bubble">
            {uniqueTaxStatuses.length > 1 && (
                <Space size={0} className="tax-form-bubble__info">
                    <Text
                        style={{ color: "#595959" }}
                        className="tax-form-bubble__message"
                    >
                        You have a few forms on your account. Below you will
                        find the status of each form.
                    </Text>
                    <Button
                        type="link"
                        onClick={(): void => {
                            if (activePanels.length > 0) {
                                setActivePanels([]);
                            } else {
                                setActivePanels(uniqueTaxStatuses);
                            }
                        }}
                    >
                        {`${isForScreenReader ? "button " : ""}${
                            activePanels.length > 0
                                ? "Collapse All Statuses"
                                : "Expand All Statuses"
                        }${isForScreenReader ? "." : ""}`}
                    </Button>
                </Space>
            )}
            {uniqueTaxStatuses.length > 1 ? (
                <Space
                    id={id}
                    size={0}
                    className="tax-form-bubble__collapse-wrapper"
                >
                    <div id={id}>
                        <Collapse
                            className="tax-form-bubble__collapse"
                            activeKey={activePanels}
                            onChange={(keys): void => {
                                setActivePanels(keys);
                            }}
                            expandIcon={({ isActive }): JSX.Element =>
                                isActive ? (
                                    <DownOutlined aria-hidden="true" />
                                ) : (
                                    <RightOutlined aria-hidden="true" />
                                )
                            }
                        >
                            {uniqueTaxStatuses.map((status: string) => {
                                const config = statusConfigs[status];
                                if (!config) {
                                    return <></>;
                                }
                                const matchingExemptions = taxData.filter(
                                    (taxExemption: TaxExemption) =>
                                        taxExemption.Status == status
                                );
                                return (
                                    <Panel
                                        header={
                                            <Text
                                                strong
                                                className="taxFormBubble-collapsible-label-text"
                                            >
                                                {`${
                                                    isForScreenReader
                                                        ? "button collapsed "
                                                        : ""
                                                }${
                                                    uniqueTaxStatuses.length > 1
                                                        ? `(${matchingExemptions.length}) ${status}`
                                                        : ""
                                                }${
                                                    isForScreenReader
                                                        ? ", "
                                                        : ""
                                                }`}
                                            </Text>
                                        }
                                        key={status} // Props will never change, doesn't matter
                                        aria-labelledby={id}
                                    >
                                        <Space
                                            size={0}
                                            style={{
                                                backgroundColor: "#fafafa",
                                                padding: "8px",
                                                borderBottom:
                                                    "1px solid #f0f0f0",
                                                width: "100%",
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    color: "#595959",
                                                    width: "10%",
                                                }}
                                                className="taxFormBubble-collapsible-body-text"
                                            >
                                                {matchingExemptions[0].Message}
                                            </Text>
                                        </Space>

                                        <Table
                                            className="tax-form-bubble__table"
                                            dataSource={matchingExemptions}
                                            columns={columns}
                                            pagination={false}
                                        />
                                    </Panel>
                                );
                            })}
                        </Collapse>
                    </div>
                </Space>
            ) : (
                <>
                    <Text
                        style={{ color: "#595959" }}
                        className="tax-form-bubble__message"
                    >
                        {taxData[0].Message}
                    </Text>
                    <Space size={0} className="tax-form-bubble__table-wrapper">
                        <Space
                            size={8}
                            className="tax-form-bubble__table-header"
                        >
                            <Text strong>Status:</Text>
                            <Text>{taxData[0].Status}</Text>
                        </Space>
                        <Table
                            className="tax-form-bubble__table"
                            dataSource={taxData}
                            columns={columns}
                            pagination={false}
                            rowKey={(record): string =>
                                `${
                                    record.State +
                                    record.ExemptionType +
                                    record.DateUploaded +
                                    record.ExpirationDate
                                }`
                            }
                        />
                    </Space>
                </>
            )}
        </Space>
    );
};

export default TaxFormBubble;
