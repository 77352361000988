import { AxiosInstance } from "axios";
import { SwitchboardApiBase } from "./SwitchboardApiBase";
import { SwitchboardConfig } from "@Models/SwitchboardConfig";
import { MessageToDelete } from "./dtos/message/MessageToDelete";

export class MessageApiShared extends SwitchboardApiBase {
    constructor(config: SwitchboardConfig, axios: AxiosInstance) {
        super(config, axios);
    }

    public deleteMessage(message: MessageToDelete): Promise<void> {
        return this.axios
            .post(this.buildSwitchboardApiUrl("Message/deleteMessage"), message)
            .then((response) => response.data);
    }
}
