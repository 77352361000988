import React, { Component, ErrorInfo, ReactNode } from "react";
import * as Sentry from "@sentry/browser";
import RenderError from "./RenderError";

interface Props {
    wrapper: (WrappedComponent: React.ComponentType) => React.ComponentType;
}

interface State {
    hasError: boolean;
}

class ErrorBoundaryForApp extends Component<Props, State> {
    public state: State = { hasError: false };

    constructor(props: Props) {
        super(props);
    }

    public static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // Log the error to Sentry
        Sentry.withScope((scope) => {
            scope.setExtras(this.serializeErrorInfo(errorInfo));
            Sentry.captureException(error);
        });
    }

    // Serialize the ErrorInfo object into a plain JavaScript object
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    serializeErrorInfo(errorInfo: ErrorInfo | null): Record<string, any> {
        if (!errorInfo) {
            return {};
        }

        return {
            componentStack: errorInfo.componentStack,
        };
    }

    public render(): ReactNode {
        if (this.state.hasError) {
            return <RenderError wrapper={this.props.wrapper} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundaryForApp;
