import React from "react";
import { Space, Typography } from "antd";
import "./CancelledRequestMessage.less";
const { Text } = Typography;

const CancelledRequestMessage = ({
    "data-testid": dataTestId,
}: {
    "data-testid": string;
}): React.JSX.Element => {
    return (
        <Space
            size={0}
            className="item-issue"
            data-testid={`${dataTestId}-space`}
        >
            <Text data-testid={`${dataTestId}-text`}>
                This request was canceled.
            </Text>
        </Space>
    );
};

export default CancelledRequestMessage;
