import { AxiosInstance } from "axios";
import { SwitchboardConfig } from "@Models/SwitchboardConfig";
import { AddMessageFeedbackRequest } from "../../hubs/chat/dtos/AddMessageFeedbackRequest";
import { RemoveMessageFeedbackRequest } from "../../hubs/chat/dtos/RemoveMessageFeedbackRequest";
import { GetMessageFeedbackRequest } from "../../hubs/chat/dtos/GetMessageFeedbackRequest";
import { ChatBotApiShared } from "@Api/ChatBotApiShared";

export class ChatBotApi extends ChatBotApiShared {
    constructor(config: SwitchboardConfig, axios: AxiosInstance) {
        super(config, axios);
    }

    public getMessageFeedback(
        request: GetMessageFeedbackRequest
    ): Promise<boolean | null> {
        return this.axios
            .get(
                this.buildSwitchboardApiUrl(
                    `ChatBot/getMessageFeedback?chatId=${request.chatId}&messageId=${request.messageId}`
                ),
                {}
            )
            .then((response) => {
                if (response.data === "") {
                    return null;
                }
                return response.data;
            });
    }

    public addMessageFeedback(
        request: AddMessageFeedbackRequest
    ): Promise<boolean> {
        return this.axios
            .post(
                this.buildSwitchboardApiUrl("ChatBot/addMessageFeedback"),
                request
            )
            .then(
                () => true,
                () => false
            );
    }

    public removeMessageFeedback(
        request: RemoveMessageFeedbackRequest
    ): Promise<boolean> {
        return this.axios
            .delete(
                this.buildSwitchboardApiUrl(
                    `ChatBot/removeMessageFeedback?chatId=${request.chatId}&messageId=${request.messageId}`
                )
            )
            .then(
                () => true,
                () => false
            );
    }
}
