export enum ActivityName {
    JoinChat = "WEBCHAT/JOIN",
    ReassureInQueue = "reassure",
    Resend = "resend",
    DoNotNavigateAway = "noNavigate",
    InitiateHandoff = "handoff.initiate",
    ReplaceActivityWithMessage = "Activity.ReplaceWithMessage",
    DeleteActivity = "Activity.Delete",
    SendTextMessage = "Activity.SendTextMessage",
    SendAttachmentMessage = "Activity.SendAttachmentMessage",
    SendBeginSoftDisabledMessage = "Activity.SendBeginSoftDisabledMessage",
    SendEndSoftDisabledMessage = "Activity.SendEndSoftDisabledMessage",
}
