import { SwitchboardConfig } from "@Models/SwitchboardConfig";
import { AxiosInstance } from "axios";
import { BriefcaseTagValidationResponseModel } from "../models/case/BriefcaseTagValidationResponseModel";
import { SwitchboardApiBase } from "./SwitchboardApiBase";

export class ValidationApi extends SwitchboardApiBase {
    constructor(config: SwitchboardConfig, axios: AxiosInstance) {
        super(config, axios);
    }

    public async validateTagAsync(
        tagValue: string,
        tagTypeInternalName: string
    ): Promise<BriefcaseTagValidationResponseModel> {
        return this.axios
            .get(this.buildSwitchboardApiUrl("Validation/validateTag"), {
                params: {
                    tagValue,
                    tagTypeInternalName,
                },
            })
            .then((response) => response.data);
    }

    public validateCaseNumberAsync(caseNumber: number): Promise<string> {
        return this.axios
            .get(this.buildSwitchboardApiUrl("Validation/validateCaseNumber"), {
                params: {
                    caseNumber: caseNumber,
                },
            })
            .then((response) => response.data);
    }
}
