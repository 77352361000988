import "./FeedbackResults.less";
import { Space, Divider } from "antd";
import FeedbackResultElement, {
    FeedbackResultElementProps,
} from "./FeedbackResultElement";

export interface FeedbackResultsProps {
    results: FeedbackResultElementProps[];
}

const FeedbackResults: React.FC<FeedbackResultsProps> = ({ results }) => {
    return (
        <Space className={"feedback-results"} direction="vertical" size={0}>
            {results.map((result, index) => (
                <>
                    <FeedbackResultElement {...result} />
                    {index < results.length - 1 && <Divider dashed={true} />}
                </>
            ))}
        </Space>
    );
};

export default FeedbackResults;
