import "./ParentheticalText.less";

interface ParentheticalTextProps {
    text: string;
}

const ParentheticalText: React.FC<ParentheticalTextProps> = ({
    text,
}: ParentheticalTextProps) => {
    return <span className="parenthetical-text">({text})</span>;
};

export default ParentheticalText;
