import "./BotHelpButtonDecorator.less";
import React from "react";
import Help from "@Images/help-icon.png";
import Cart from "@Images/cart-icon.svg";
import { Button } from "antd";

interface BotHelpButtonDecoratorProps {
    helpButtonText: string;
    haveNotPlacedOrderButtonText: string;
    onHelpButtonClick: () => void;
    onHaveNotPlacedOrderButtonClick: () => void;
    shouldShowHelpButton: boolean;
    shouldShowHaveNotPlacedOrderButton: boolean;
}

const BotHelpButtonDecorator: React.FC<BotHelpButtonDecoratorProps> = (
    props
) => {
    return (
        <div className="bot-help-button-decorator">
            <div className="bot-help-button-decorator__content">
                {props.children}
            </div>
            {props.shouldShowHaveNotPlacedOrderButton && (
                <Button
                    className="bot-help-button-decorator__button-outlined"
                    onClick={props.onHaveNotPlacedOrderButtonClick}
                >
                    <img
                        src={Cart}
                        alt="cart-icon"
                        className="bot-help-button-decorator__button-outlined__img"
                    />
                    {props.haveNotPlacedOrderButtonText}
                </Button>
            )}
            {props.shouldShowHelpButton && (
                <Button
                    className="bot-help-button-decorator__button-outlined"
                    onClick={props.onHelpButtonClick}
                >
                    <img
                        src={Help}
                        alt="help-icon"
                        className="bot-help-button-decorator__button-outlined__img"
                    />
                    {props.helpButtonText}
                </Button>
            )}
        </div>
    );
};

export default BotHelpButtonDecorator;
