export enum PersistedMessageType {
    Text = "Text",
    BotText = "BotText",
    Image = "Image",
    UserJoinedChat = "UserJoinedChat",
    UserLeftChat = "UserLeftChat",
    UserTransferredFromBotToRep = "UserTransferredFromBotToRep",
    UserTimedOut = "UserTimedOut",
    SmartInclude = "SmartInclude",
    OrderTracking = "OrderTracking",
    TaxExemptionStatus = "TaxExemptionStatus",
    RefundReship = "RefundReship",
    Returns = "Returns",
    FoodQualityIssue = "FoodQualityIssue",
    UserSelectedNotHelpfulAnswerChatWithSpecialist = "UserSelectedNotHelpfulAnswerChatWithSpecialist",
}

export const isBotCustomActivity = (value: string): boolean => {
    return (
        value == PersistedMessageType.OrderTracking ||
        value == PersistedMessageType.TaxExemptionStatus ||
        value == PersistedMessageType.RefundReship ||
        value == PersistedMessageType.Returns ||
        value == PersistedMessageType.FoodQualityIssue
    );
};
