import { StrictMode } from "react";
import { store } from "@Components/CustomerApp/store";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";

const CustomerAppWrapper: React.FC = (props) => {
    return (
        <StrictMode>
            <Provider store={store}>
                <ConfigProvider
                    // Fixes the anchor of the AntD Select/Dropdown when scrolling is available
                    getPopupContainer={(
                        triggerNode: HTMLElement | undefined
                    ): HTMLElement => triggerNode?.parentNode as HTMLElement}
                >
                    {props.children}
                </ConfigProvider>
            </Provider>
        </StrictMode>
    );
};

export default CustomerAppWrapper;
