import React from "react";
import { useAppSelector } from "@Components/CustomerApp/hooks";
import ItemReturns from "./ItemReturns";
import { CustomActivityMessage } from "@Src/hubs/chat/dtos/CustomActivityMessage";
import { hooks } from "botframework-webchat";
const { useSendMessageBack } = hooks;

interface CustomerItemReturnsProps {
    chatId: string | undefined;
    orderInfo: any;
    messageId: string | undefined;
    resolutionsInfo: any;
    isCanceled: boolean;
    itemNotListed: boolean;
    validReturnReasons: any;
    restockingFeePercentage: number;
    dboChatId: number | undefined;
}

const CustomerItemReturns = ({
    chatId,
    orderInfo,
    messageId,
    resolutionsInfo,
    isCanceled,
    itemNotListed,
    validReturnReasons,
    restockingFeePercentage,
    dboChatId,
}: CustomerItemReturnsProps): React.JSX.Element => {
    const customerApp = useAppSelector((state) => state.customerApp);

    const message = customerApp.messages.find(
        (x) => x.id === messageId && x.chatId === chatId
    ) as CustomActivityMessage;

    const webchatSendMessageBack = useSendMessageBack();

    // check if message exists in message state array and if so, use it since we can update it and cannot update the activities array in botframework-webchat-core
    if (!message) {
        return (
            <ItemReturns
                chatId={chatId}
                orderInfo={orderInfo}
                isReadOnly={false}
                resolutionsInfo={resolutionsInfo}
                messageId={messageId}
                isCancelClicked={isCanceled as boolean}
                isItemNotListedClicked={itemNotListed}
                webchatSendMessageBack={webchatSendMessageBack}
                validReturnReasons={validReturnReasons}
                restockingFeePercentage={restockingFeePercentage}
                dboChatId={dboChatId}
            />
        );
    } else {
        const json = JSON.parse(message.jsonData);
        return (
            <ItemReturns
                chatId={chatId}
                orderInfo={json.itemReturns}
                isReadOnly={json.itemReturnsResolutions?.some(
                    (x: { items: any[] }) => x.items?.length > 0
                )}
                resolutionsInfo={json.itemReturnsResolutions}
                messageId={messageId}
                isCancelClicked={json.isCanceled as boolean}
                isItemNotListedClicked={json.itemNotListed as boolean}
                webchatSendMessageBack={webchatSendMessageBack}
                validReturnReasons={json.validReturnReasons}
                restockingFeePercentage={json.restockingFeePercentage}
                dboChatId={dboChatId}
            />
        );
    }
};

export default CustomerItemReturns;
