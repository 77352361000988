import "./SelectIssue.less";

import { useAppSelector } from "../../hooks";
import { WelcomeIssue } from "@Models/chat/WelcomeIssue";
import { SelectIssueAbstract } from "@Models/welcomeView/SelectIssueAbstract";
import { forwardRef, useImperativeHandle, useRef } from "react";
import {
    Form,
    FormInstance,
    Input,
    InputRef,
    Select,
    Space,
    Typography,
} from "antd";
import { RkwIssues } from "../../issues";
import { IssueInformationModel } from "@Models/chat/IssueInformationModel";
import { FormItemLabel } from "../../Form/FormItemLabel";
import { BriefcaseTagValidationResponseModel } from "../../../../models/case/BriefcaseTagValidationResponseModel";
import { BriefcaseCaseTagNames } from "@Models/tag/BriefcaseCaseTagNames";
const { Text } = Typography;
const { Option } = Select;

interface SelectIssueProps {
    issueSelected: WelcomeIssue | "";
    setIssueSelected: React.Dispatch<React.SetStateAction<WelcomeIssue | "">>;
    isFormDisabled: boolean;
    formInstance: FormInstance<unknown>;
}

const SelectIssueRkw = forwardRef<SelectIssueAbstract, SelectIssueProps>(
    (
        {
            issueSelected,
            setIssueSelected,
            isFormDisabled,
            formInstance,
        }: SelectIssueProps,
        ref
    ) => {
        const validationApi = useAppSelector(
            (state) => state.customerApp.validationApi
        );

        const orderNumberRef = useRef<InputRef>(null);
        const warrantyClaimNumberRef = useRef<InputRef>(null);
        const modelNumberRef = useRef<InputRef>(null);
        const serialNumberRef = useRef<InputRef>(null);

        const handleOrderValidation = (
            orderNumber: string
        ): Promise<BriefcaseTagValidationResponseModel> => {
            return validationApi
                .validateTagAsync(
                    orderNumber,
                    BriefcaseCaseTagNames.InternalNames.Order
                )
                .then((data) => {
                    if (!data.isValid) {
                        alert("Order Number is invalid.");
                    }
                    return data;
                });
        };

        // Allows the parent function to call validation on the child when submitting
        useImperativeHandle(ref, () => ({
            async handleValidation(): Promise<BriefcaseTagValidationResponseModel> {
                // RKW does no external validation of claim/model fields
                // The order number is validated through briefcase
                const orderNumber = orderNumberRef.current?.input?.value;
                if (orderNumber) {
                    return handleOrderValidation(orderNumber);
                }

                return { isValid: true } as BriefcaseTagValidationResponseModel;
            },
            getIssueInformation(): IssueInformationModel {
                const orderNumber = orderNumberRef.current?.input?.value;
                const warrantyClaimNumber =
                    warrantyClaimNumberRef.current?.input?.value;
                const modelNumber = modelNumberRef.current?.input?.value;
                const serialNumber = serialNumberRef.current?.input?.value;

                return {
                    issue: issueSelected,
                    warrantyClaimNumber: warrantyClaimNumber,
                    modelNumber: modelNumber,
                    serialNumber: serialNumber,
                    orderNumber: orderNumber,
                } as IssueInformationModel;
            },
            isFormInvalid(): boolean {
                return (
                    formInstance.getFieldError("claimNumber").length > 0 ||
                    formInstance.getFieldError("modelNumber").length > 0 ||
                    formInstance.getFieldError("serialNumber").length > 0 ||
                    formInstance.getFieldError("orderNumber").length > 0 ||
                    (issueSelected === WelcomeIssue.UpdateOnClaimStatus &&
                        !formInstance.getFieldValue("warrantyClaimNumber")) ||
                    (issueSelected === WelcomeIssue.VerifyWarrantyStatus &&
                        (!formInstance.getFieldValue("modelNumber") ||
                            !formInstance.getFieldValue("serialNumber")))
                );
            },
        }));

        const SelectedIssue = (): JSX.Element => {
            switch (issueSelected) {
                case WelcomeIssue.UpdateOnClaimStatus:
                    return (
                        <Form.Item
                            name="warrantyClaimNumber"
                            label={FormItemLabel(
                                "Warranty Claim Number",
                                "numeric-hide-spinner"
                            )}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please enter a warranty claim number.",
                                },
                                {
                                    type: "string",
                                    pattern: new RegExp("^[0-9]+$"),
                                    message: "Please use only numbers.",
                                },
                            ]}
                        >
                            <Input
                                ref={warrantyClaimNumberRef}
                                maxLength={50}
                                disabled={isFormDisabled}
                                type="number"
                                className="numeric-hide-spinner"
                                onChange={function (): void {
                                    formInstance.setFields([
                                        {
                                            name: "warrantyClaimNumber",
                                            errors: [],
                                        },
                                    ]);
                                }}
                            />
                        </Form.Item>
                    );
                case WelcomeIssue.VerifyWarrantyStatus:
                    return (
                        <Space.Compact direction="vertical">
                            <Form.Item
                                name="modelNumber"
                                label={FormItemLabel(
                                    "Model Number",
                                    "model-number"
                                )}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter a model number.",
                                    },
                                    {
                                        type: "string",
                                        pattern: new RegExp("^[0-9a-zA-Z]+$"),
                                        message:
                                            "Please use only letters and numbers.",
                                    },
                                ]}
                            >
                                <Input
                                    ref={modelNumberRef}
                                    disabled={isFormDisabled}
                                    maxLength={50}
                                    id="model-number"
                                    onChange={function (): void {
                                        formInstance.setFields([
                                            {
                                                name: "modelNumber",
                                                errors: [],
                                            },
                                        ]);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="serialNumber"
                                label={FormItemLabel(
                                    "Serial Number",
                                    "serial-number"
                                )}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please enter a serial number.",
                                    },
                                    {
                                        type: "string",
                                        pattern: new RegExp("^[0-9a-zA-Z]+$"),
                                        message:
                                            "Please use only letters and numbers.",
                                    },
                                ]}
                            >
                                <Input
                                    ref={serialNumberRef}
                                    disabled={isFormDisabled}
                                    maxLength={50}
                                    id="serial-number"
                                    onChange={function (): void {
                                        formInstance.setFields([
                                            {
                                                name: "serialNumber",
                                                errors: [],
                                            },
                                        ]);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="orderNumber"
                                label={FormItemLabel(
                                    "Order Number",
                                    "order-number"
                                )}
                                rules={[
                                    {
                                        required: false,
                                    },
                                    {
                                        type: "string",
                                        pattern: /^[0-9a-zA-Z]+$/,
                                        message:
                                            "Please use only letters and numbers.",
                                    },
                                ]}
                            >
                                <Input
                                    ref={orderNumberRef}
                                    disabled={isFormDisabled}
                                    id="order-number"
                                    onChange={function (): void {
                                        formInstance.setFields([
                                            {
                                                name: "orderNumber",
                                                errors: [],
                                            },
                                        ]);
                                    }}
                                />
                            </Form.Item>
                        </Space.Compact>
                    );
                default:
                    return (
                        <Text data-testid="selected-issue-rkw-default-text">
                            Select an Issue, some options require more
                            information.
                        </Text>
                    );
            }
        };

        return (
            <>
                <Form.Item
                    name="issue"
                    label={FormItemLabel(
                        "Select Issue",
                        "welcome-view__content__form__issue-select"
                    )}
                    rules={[
                        {
                            required: true,
                            message: "Please select an issue.",
                        },
                    ]}
                    // Validates onChange so the Start Chat button becomes enabled immediately and tab nav won't skip it
                    validateTrigger={"onChange"}
                >
                    <Select
                        className="welcome-view__content__form__issue-select"
                        onChange={(value): void => {
                            setIssueSelected(value as unknown as WelcomeIssue);
                        }}
                        disabled={isFormDisabled}
                        placeholder="Select Issue"
                        id="welcome-view__content__form__issue-select"
                    >
                        {RkwIssues.map((issue, index) => (
                            <Option key={index} value={issue.value}>
                                {issue.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Space
                    direction="vertical"
                    className="welcome-view__content__form__issues"
                >
                    {SelectedIssue()}
                </Space>
            </>
        );
    }
);

SelectIssueRkw.displayName = "SelectIssueRkw";

export default SelectIssueRkw;
