import React from "react";
import { useAppSelector } from "@Components/CustomerApp/hooks";
import ItemRefundReship from "./ItemRefundReship";
import { CustomActivityMessage } from "@Src/hubs/chat/dtos/CustomActivityMessage";
import { hooks } from "botframework-webchat";
const { useSendMessageBack } = hooks;

const CustomerItemRefundReship = ({
    chatId,
    orderInfo,
    messageId,
    resolutionsInfo,
    isCanceled,
    dboChatId,
}: any): React.JSX.Element => {
    const customerApp = useAppSelector((state) => state.customerApp);

    const message = customerApp.messages.find(
        (x) => x.id === messageId && x.chatId === chatId
    ) as CustomActivityMessage;

    const webchatSendMessageBack = useSendMessageBack();

    // check if message exists in message state array and if so, use it since we can update it and cannot update the activities array in botframework-webchat-core
    if (!message) {
        return (
            <ItemRefundReship
                chatId={chatId}
                orderInfo={orderInfo}
                isReadOnly={false}
                resolutionsInfo={resolutionsInfo}
                messageId={messageId}
                isCancelClicked={isCanceled as boolean}
                webchatSendMessageBack={webchatSendMessageBack}
                dboChatId={dboChatId}
            />
        );
    } else {
        const json = JSON.parse(message.jsonData);
        return (
            <ItemRefundReship
                chatId={chatId}
                orderInfo={json.itemRefundReship}
                isReadOnly={json.itemRefundReshipResolution?.items?.length > 0}
                resolutionsInfo={json.itemRefundReshipResolution}
                messageId={messageId}
                isCancelClicked={json.isCanceled as boolean}
                webchatSendMessageBack={webchatSendMessageBack}
                dboChatId={dboChatId}
            />
        );
    }
};

export default CustomerItemRefundReship;
