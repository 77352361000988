import { tenantRoute } from "@Src/tenantConfiguration/SharedTenantConfiguration";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { ChatHubEvents } from "@Hubs/chat/ChatHubEvents";
import { ChatHubRequestTypes } from "@Hubs/chat/ChatHubRequestTypes";
import { QueryParams } from "@Utilities/QueryParams";
import { RetryDelays } from "@Utilities/SignalrRetry";
import { getTabId } from "@Utilities/Startup";

const chatHubConnection = new HubConnectionBuilder()
    .withUrl(
        `/hubs/chat?${
            QueryParams.tenantRoute
        }=${tenantRoute}&tabId=${getTabId()}`
    )
    .configureLogging(LogLevel.Warning)
    .withAutomaticReconnect(RetryDelays)
    .build();

export interface ChatHubEventHandler {
    event: ChatHubEvents;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handler: (data: any) => void;
}

export interface ChatHubEventRequest {
    event: ChatHubRequestTypes;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any;
}

const handlers: { [event: string]: ChatHubEventHandler } = {};

export function setChatHubEventHandler(
    eventHandler: ChatHubEventHandler
): void {
    const oldHandler = handlers[ChatHubEvents[eventHandler.event]];
    if (oldHandler === eventHandler) {
        return;
    }
    if (handlers[ChatHubEvents[eventHandler.event]]) {
        chatHubConnection.off(eventHandler.event);
    }
    handlers[ChatHubEvents[eventHandler.event]] = eventHandler;
    chatHubConnection.on(eventHandler.event, (data) => {
        console.log("ChatHub %O message received:", eventHandler.event, data);
        eventHandler.handler(data);
    });
}

export function sendChatHubRequest(eventRequest: ChatHubEventRequest): void {
    chatHubConnection.invoke(
        ChatHubRequestTypes[eventRequest.event],
        eventRequest.payload
    );
}

const onConnectBufferDurationMs = 50;

export async function startChatHub(
    isRefreshCausedByDeploy: boolean
): Promise<string> {
    wasClosedGracefully = false;
    return chatHubConnection
        .start()
        .then(() => {
            console.log("Chat hub connected.");
            const connectionId = chatHubConnection.connectionId;

            if (!connectionId) {
                const message = "Chat hub connectionId was null or not valid.";
                console.error(message);
                throw Error(message);
            }

            return new Promise<string>((resolve, reject) => {
                setTimeout(() => {
                    console.log("On connect buffer complete.");

                    if (!chatHubConnection.connectionId) {
                        const message =
                            "Chat hub connectionId was null or not valid after on connect buffer.";
                        console.error(message);
                        reject(new Error(message));
                    }

                    const keys = Object.keys(onHubConnectedHandlers);
                    if (keys.length <= 0) {
                        console.log(
                            "Chat hub - no connected handlers configured."
                        );
                    }

                    const promises = keys.map((key) =>
                        onHubConnectedHandlers[key](
                            connectionId,
                            isRefreshCausedByDeploy
                        )
                    );

                    Promise.all(promises)
                        .then(() => resolve(connectionId))
                        .catch((error) => reject(error));
                }, onConnectBufferDurationMs);
            });
        })
        .catch((error) => {
            console.error("Chat hub could not start:", error);
            throw error;
        });
}

export const onHubConnectedHandlers: {
    [name: string]: (
        hubConnectionId: string,
        isRefreshCausedByDeploy: boolean
    ) => Promise<void>;
} = {};

export function setChatHubConnectedHandler(
    handlerName: string,
    handler: (
        hubConnectionId: string,
        isRefreshCausedByDeploy: boolean
    ) => Promise<void>
): void {
    onHubConnectedHandlers[handlerName] = handler;
}

const onHubReconnectingHandlers: {
    [name: string]: () => void;
} = {};

export function setChatHubReconnectingHandler(
    handlerName: string,
    handler: () => void
): void {
    onHubReconnectingHandlers[handlerName] = handler;
}

chatHubConnection.onreconnecting(() => {
    console.log("Chat hub reconnecting.");

    const keys = Object.keys(onHubReconnectingHandlers);
    if (keys.length <= 0) {
        console.log("Chat hub - no reconnecting handlers configured.");
    }
    keys.forEach((key): void => onHubReconnectingHandlers[key]());
});

let onHubReconnectedHandler:
    | ((hubConnectionId: string, isRefreshCausedByDeploy: boolean) => void)
    | undefined;

export function setChatHubReconnectedHandler(
    handler: (hubConnectionId: string, isRefreshCausedByDeploy: boolean) => void
): void {
    onHubReconnectedHandler = handler;
}

chatHubConnection.onreconnected(() => {
    console.log("Chat hub reconnected.");
    if (!chatHubConnection.connectionId) {
        console.error("Chat hub connectionId was undefined on reconnected.");
        return;
    }
    setTimeout(() => {
        if (!chatHubConnection.connectionId) {
            console.error(
                "Chat hub connectionId was undefined after on connect buffer."
            );
            return;
        }

        console.log("On connect buffer complete.");

        if (onHubReconnectedHandler) {
            onHubReconnectedHandler(chatHubConnection.connectionId, false);
        } else {
            console.log("Chat hub - no reconnected handlers configured.");
        }
    }, onConnectBufferDurationMs);
});

const onHubClosedHandlers: {
    [name: string]: (error: Error | undefined) => void;
} = {};

let wasClosedGracefully = false;

export function setChatHubClosedHandler(
    handlerName: string,
    handler: (error: Error | undefined) => void
): void {
    onHubClosedHandlers[handlerName] = handler;
}

chatHubConnection.onclose((error) => {
    if (wasClosedGracefully) {
        console.log("Chat hub closed gracefully.");
        return;
    }

    const handlerKeys = Object.keys(onHubClosedHandlers);
    if (handlerKeys.length > 0) {
        handlerKeys.forEach((key): void => onHubClosedHandlers[key](error));
    } else {
        console.log("Chat hub closed - no close handlers configured.");
    }
});

export function closeChatHubConnection(): Promise<void> {
    wasClosedGracefully = true;
    if (chatHubConnection && chatHubConnection.connectionId) {
        console.log("Chat hub closing.");
        return chatHubConnection.stop();
    }
    return Promise.resolve();
}
