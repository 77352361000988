import PepperAvatarIcon from "@Images/pepper-avatar.svg";
import { StyleOptions } from "botframework-webchat";
import AdaptiveCardsStyleOptions from "botframework-webchat/lib/adaptiveCards/AdaptiveCardsStyleOptions";

export const WebChatCustomStyles: StyleOptions & AdaptiveCardsStyleOptions = {
    //base styles
    backgroundColor: "#f2f9fb",

    //text bubble styles
    bubbleBackground: "white",
    bubbleBorderColor: "#d9d9d9",
    bubbleTextColor: "#262626",
    bubbleFromUserBackground: "#007bb6",
    bubbleFromUserTextColor: "white",
    bubbleFromUserBorderColor: "#006fa4",

    // avatar styles
    botAvatarInitials: "Pepper",
    botAvatarImage: PepperAvatarIcon,
    avatarSize: 80,

    // text area styles
    sendBoxBackground: "white",
    hideUploadButton: true,
    sendBoxHeight: 48,
    hideSendBox: true,
};
