const maximumFileSize = 20971520; // 20 * 1024 * 1024 = 20MB
const maximumFileSizeErrorMessage = "Maximum file size is 20MB";
const allowedFileExtensionsForCustomers = [".jpg", ".png", ".heic", ".jpeg"];
const allowedFileExtensionsForOperators = [
    ".jpg",
    ".png",
    ".heic",
    ".jpeg",
    ".pdf",
    ".doc",
    ".docx",
    ".xls",
    ".xlsx",
    ".mp4",
    ".txt",
    ".csv",
];
const invalidFileTypeErrorMessageForCustomers =
    "File is not the correct file type. Please choose a JPG, PNG or Live Photo file.";
const invalidFileTypeErrorMessageForOperators =
    "File is not the correct file type. Please choose a JPG, PNG, Live Photo, DOC, DOCX, XLS, XLSX, MP4, TXT, or CSV file.";
const magicNumberPDF = [0x25, 0x50, 0x44, 0x46];
const magicNumberPNG = [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a];
const magicNumberJPG = [0xff, 0xd8, 0xff];
const magicNumbersHEIC = [
    [0x66, 0x74, 0x79, 0x70, 0x4d, 0x34, 0x56, 0x31], // ftypM4V1
    [0x66, 0x74, 0x79, 0x70, 0x6d, 0x69, 0x66, 0x31], // ftypmif1
    [0x66, 0x74, 0x79, 0x70, 0x6d, 0x73, 0x66, 0x31], // ftypmsf1
    [0x66, 0x74, 0x79, 0x70, 0x68, 0x65, 0x69, 0x63], // ftypheic
    [0x66, 0x74, 0x79, 0x70, 0x68, 0x65, 0x69, 0x78], // ftypheix
    [0x66, 0x74, 0x79, 0x70, 0x68, 0x65, 0x76, 0x73], // ftyphevc
    [0x66, 0x74, 0x79, 0x70, 0x68, 0x65, 0x69, 0x6d], // ftypheim
    [0x66, 0x74, 0x79, 0x70, 0x68, 0x65, 0x69, 0x73], // ftypheis
    [0x66, 0x74, 0x79, 0x70, 0x68, 0x65, 0x76, 0x6d], // ftyphevm
    [0x66, 0x74, 0x79, 0x70, 0x68, 0x65, 0x76, 0x73], // ftyphevs
];
const magicNumbersMP4 = [
    [0x66, 0x74, 0x79, 0x70, 0x4d, 0x50, 0x34, 0x30], // ftypMP40 - 4 byte offset
    [0x66, 0x74, 0x79, 0x70, 0x6d, 0x70, 0x34, 0x32], // ftypmp42 - 4 byte offset
    [0x66, 0x74, 0x79, 0x70, 0x4d, 0x53, 0x4e, 0x56], // ftypMSNV - 4 byte offset
    [0x66, 0x74, 0x79, 0x70, 0x69, 0x73, 0x6f, 0x6d], // ftypisom - 4 byte offset
];
const magicNumberDOC = [0xd0, 0xcf, 0x11, 0xe0, 0xa1, 0xb1, 0x1a, 0xe1]; // MS Office 97-2003
const magicNumberXLS = [0xd0, 0xcf, 0x11, 0xe0, 0xa1, 0xb1, 0x1a, 0xe1]; // MS Office 97-2003
const magicNumberDOCX = [0x50, 0x4b, 0x03, 0x04]; // MS Office Open XML
const magicNumberXLSX = [0x50, 0x4b, 0x03, 0x04]; // MS Office Open XML

export interface FileUploadDefinitions {
    maximumFileSize: number;
    maximumFileSizeErrorMessage: string;
    allowedFileExtensionsForCustomers: string[];
    allowedFileExtensionsForOperators: string[];
    invalidFileTypeErrorMessageForCustomers: string;
    invalidFileTypeErrorMessageForOperators: string;
    magicNumberPDF: number[];
    magicNumberPNG: number[];
    magicNumberJPG: number[];
    magicNumbersHEIC: number[][];
    magicNumbersMP4: number[][];
    magicNumberDOC: number[];
    magicNumberXLS: number[];
    magicNumberDOCX: number[];
    magicNumberXLSX: number[];
}

export const fileUploadDefinitions: FileUploadDefinitions = {
    maximumFileSize: maximumFileSize,
    maximumFileSizeErrorMessage: maximumFileSizeErrorMessage,
    allowedFileExtensionsForCustomers: allowedFileExtensionsForCustomers,
    allowedFileExtensionsForOperators: allowedFileExtensionsForOperators,
    invalidFileTypeErrorMessageForCustomers:
        invalidFileTypeErrorMessageForCustomers,
    invalidFileTypeErrorMessageForOperators:
        invalidFileTypeErrorMessageForOperators,
    magicNumberPDF: magicNumberPDF,
    magicNumberPNG: magicNumberPNG,
    magicNumberJPG: magicNumberJPG,
    magicNumbersHEIC: magicNumbersHEIC,
    magicNumbersMP4: magicNumbersMP4,
    magicNumberDOC: magicNumberDOC,
    magicNumberXLS: magicNumberXLS,
    magicNumberDOCX: magicNumberDOCX,
    magicNumberXLSX: magicNumberXLSX,
};

export class FileTypeOffset {
    static readonly PNG = 0;
    static readonly JPG = 0;
    static readonly HEIC = 4;
    static readonly MP4 = 4;
    static readonly DOC = 0;
    static readonly XLS = 0;
    static readonly DOCX = 0;
    static readonly XLSX = 0;
    static readonly PDF = 0;
}

export class FileTypeReadBufferLength {
    static readonly PNG = 8;
    static readonly JPG = 3;
    static readonly HEIC = 12;
    static readonly MP4 = 12;
    static readonly DOC = 8;
    static readonly XLS = 8;
    static readonly DOCX = 4;
    static readonly XLSX = 4;
    static readonly PDF = 4;
    static readonly TXT = 500;
}
