import { BaseMessage } from "@Hubs/chat/dtos/BaseMessage";
import { ClientMessageType } from "@Hubs/chat/dtos/ClientMessageType";
import { ClientRole } from "@Hubs/chat/dtos/ClientRole";
import * as uuid from "uuid";

export class ClientMessage extends BaseMessage {
    constructor(
        chatId: string,
        senderName: string,
        senderUserId: string,
        clientRole: ClientRole,
        messageType: ClientMessageType,
        timestamp?: Date
    ) {
        super(
            uuid.v4(),
            chatId,
            timestamp ?? new Date(),
            senderName,
            senderUserId,
            clientRole,
            messageType
        );
    }
}
