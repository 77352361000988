import { TextMessage } from "./TextMessage";

interface IncomingMessage extends Omit<BotTextMessage, "timestamp"> {
    timestamp: string;
}

export class BotTextMessage extends TextMessage {
    isBotChoice: boolean;

    constructor(message: IncomingMessage) {
        super(message);
        this.isBotChoice = message.isBotChoice;
    }
}
