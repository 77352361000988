export function pathJoin(...paths: string[]): string {
    return paths.reduce((accumulator: string, currentPath: string) => {
        const trimmedPath = trimPath(currentPath);

        // Append the current path to the accumulator
        accumulator = accumulator
            ? `${accumulator}/${trimmedPath}`
            : trimmedPath;

        return accumulator;
    }, "");
}

// Trim any leading or trailing slashes from the current path
function trimPath(currentPath: string): string {
    const trimmedPath = currentPath.trim();

    let startIndex = 0;
    let foundStart = false;

    let endIndex = 0;
    let endSequenceStarted = false;
    for (let i = 0; i < trimmedPath.length; i++) {
        const isCharContent = trimmedPath.charAt(i) !== "/";

        if (isCharContent) {
            endSequenceStarted = false;
        }

        if (isCharContent && !foundStart) {
            startIndex = i;
            foundStart = true;
        }

        if (!isCharContent && !endSequenceStarted) {
            endSequenceStarted = true;
            endIndex = i;
        }
    }

    return trimmedPath.substring(
        startIndex,
        endSequenceStarted ? endIndex : trimmedPath.length
    );
}
