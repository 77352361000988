import { SwitchboardConfig } from "@Models/SwitchboardConfig";
import { AxiosInstance, AxiosResponse } from "axios";
import { SwitchboardApiBase } from "../SwitchboardApiBase";
import { FeedbackReasonsModel } from "../dtos/feedbackReasons/FeedbackReasonsModel";

export class FeedbackReasonsApi extends SwitchboardApiBase {
    constructor(config: SwitchboardConfig, axios: AxiosInstance) {
        super(config, axios);
    }

    public async getFeedbackReasons(): Promise<FeedbackReasonsModel> {
        return this.axios
            .get(this.buildSwitchboardApiUrl("feedbackReasons"))
            .then(
                (response: AxiosResponse<FeedbackReasonsModel>) => response.data
            );
    }
}
