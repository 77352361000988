import { QueryParams } from "./QueryParams";

// Get it once only when the page loads
const queryParamString = window.location.search.substring(1);

export function getQueryParam(key: QueryParams): string | undefined {
    const vars = queryParamString.split("&");
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (decodeURIComponent(pair[0]) === key) {
            return decodeURIComponent(
                pair.reduce((previousValue, currentValue, index) => {
                    if (index <= 0) {
                        return "";
                    }
                    // Correct for split removing an '=' sign if one is present from a non-URL-encoded value
                    // (TRS does this with user index)
                    if (currentValue === "") {
                        return previousValue + "=";
                    }
                    return previousValue + currentValue;
                }, "")
            );
        }
    }
    return undefined;
}
