import React from "react";
import { Space, Typography } from "antd";
import EstimatedTotalToolTip from "../ToolTips/EstimatedTotalToolTip";
const { Text } = Typography;

import "./EstimatedRefundStoreCredit.less";

const EstimatedRefundStoreCredit = (props: any): React.JSX.Element => {
    const { totalPrice } = props;
    return (
        <Space size={0}>
            <EstimatedTotalToolTip />
            <Text className="estimated-header-padding">
                Estimated Refund - Store Credit
            </Text>
            <Text strong>${totalPrice.toFixed(2)}</Text>
        </Space>
    );
};

export default EstimatedRefundStoreCredit;
