import React from "react";
import ItemReturns from "./ItemReturns";
import { LiveChatViewCustomActivityBubbleWrapper } from "../../LiveChatViewCustomActivityBubbleWrapper";

import "./LiveChatItemReturns.less";

interface LiveChatItemReturnsProps {
    chatId: string | undefined;
    orderInfo: any;
    messageId: string | undefined;
    resolutionsInfo: any;
    isCanceled: boolean;
    itemNotListed: boolean;
    validReturnReasons: any;
    restockingFeePercentage: number;
}

const LiveChatItemReturns = ({
    chatId,
    orderInfo,
    messageId,
    resolutionsInfo,
    isCanceled,
    itemNotListed,
    validReturnReasons,
    restockingFeePercentage,
}: any): React.JSX.Element => {
    return (
        <LiveChatViewCustomActivityBubbleWrapper>
            <ItemReturns
                resolutionsInfo={resolutionsInfo}
                isReadOnly={true}
                chatId={chatId}
                orderInfo={orderInfo}
                messageId={messageId}
                isCancelClicked={isCanceled}
                isItemNotListedClicked={itemNotListed}
                validReturnReasons={validReturnReasons}
                restockingFeePercentage={restockingFeePercentage}
                dboChatId={undefined}
                webchatSendMessageBack={undefined}
            />
        </LiveChatViewCustomActivityBubbleWrapper>
    );
};

export default LiveChatItemReturns;
