import React, { useEffect, useState, useRef, ReactElement } from "react";
import { Button, Form, Input, Tooltip } from "antd";
import { MessageFilled, PictureOutlined } from "@ant-design/icons";
import { PersistedMessage } from "@Hubs/chat/dtos/PersistedMessage";
import { PersistedMessageType } from "@Hubs/chat/dtos/PersistedMessageType";
import { TextMessage } from "@Src/hubs/chat/dtos/TextMessage";
import { clearTimeout, setTimeout } from "timers";
import "./MessageBar.less";
import { CustomEvents } from "@Utilities/CustomEvents";
import { ElementIds } from "@Utilities/ElementIds";
import { onWindowChange } from "@Utilities/ViewportHelper";
import { TextAreaRef } from "antd/lib/input/TextArea";
import { shouldCheckForCreditCardNumbers } from "@Src/tenantConfiguration/SharedTenantConfiguration";
import FileUploadModal from "./FileUploadModal";
import BotMessageBarTextCounter from "../../CustomerApp/Shared/BotMessageBarTextCounter";
import { RuleObject } from "antd/lib/form";

const { TextArea } = Input;

interface MessageBarProps {
    chatId: string;
    sendMessageAsUser(message: PersistedMessage): void;
    cannedResponseElement?: JSX.Element;
    isDisabled: boolean;
    fileUploadsEnabled: boolean;
    sendFileAsUser?(chatId: string, data: FormData): Promise<void>;
    sendTypingIndicator(chatId: string): void;
    sendStopTypingIndicator(chatId: string): void;
    typingNotificationTimeoutTimeInSeconds: number;
    saveUnsentText?(chatId: string, text: string): void;
    getUnsentTextForChat?(chatId: string): string;
    isOperator: boolean;
    messageInputOverrideRef?: React.RefObject<TextAreaRef>;
    isSoftDisabled: boolean;
    softDisabledErrorMessage?: string;
    isBotMessageBar: boolean;
}

const MessageBar = ({
    chatId,
    sendMessageAsUser,
    cannedResponseElement,
    isDisabled,
    fileUploadsEnabled,
    sendFileAsUser,
    sendTypingIndicator,
    sendStopTypingIndicator,
    typingNotificationTimeoutTimeInSeconds,
    saveUnsentText,
    getUnsentTextForChat,
    isOperator,
    messageInputOverrideRef,
    isSoftDisabled,
    softDisabledErrorMessage,
    isBotMessageBar,
}: MessageBarProps): JSX.Element => {
    const messageInputRef = useRef<TextAreaRef>(
        messageInputOverrideRef?.current ?? null
    );
    const [isPhotoModalVisible, setIsPhotoModalVisible] =
        useState<boolean>(false);
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const [shouldAlignBottom, setShouldAlignBottom] = useState<boolean>(false);
    const [hasEmptyMessageError, setHasEmptyMessageError] =
        useState<boolean>(false);
    const [hasMaxLengthError, setHasMaxLengthError] = useState<boolean>(false);
    const messageMaxLength = 500;
    const thresholdLengthToDisplayCounter = 400;
    const emptyMessageErrorText = "Please enter a message.";
    const maxLengthErrorText = `Please limit character count to under ${messageMaxLength}.`;
    const [shouldForceSendIfClickedAgain, setShouldForceSendIfClickedAgain] =
        useState<boolean>(false);
    const [form] = Form.useForm();
    const clearTypingTimeoutHandle = useRef<NodeJS.Timeout | null>(null);
    const [cursorPosition, setCursorPosition] = useState(-1);
    const [isLongErrorMessageVisible, setIsLongErrorMessageVisible] =
        useState<boolean>(false);

    const fileRelatedNoun = isOperator ? "File(s)" : "Image(s)";

    useEffect(() => {
        const handleCannedResponseClicked = (
            event: CustomEventInit<string>
        ): void => {
            const currentText = form.getFieldValue("inputText");
            const textToUse =
                (currentText?.length ?? 0) > 0 &&
                currentText[currentText.length - 1] !== " "
                    ? `${currentText ?? ""} ${event.detail}`
                    : `${currentText ?? ""}${event.detail}`;
            form.setFields([
                {
                    name: "inputText",
                    value: textToUse,
                    errors: [],
                },
            ]);
            messageInputRef.current?.focus({ cursor: "end" });

            if (saveUnsentText) {
                saveUnsentText(chatId, textToUse);
            }
        };

        if (isOperator && !isDisabled && getUnsentTextForChat) {
            form.setFieldsValue({
                inputText: getUnsentTextForChat(chatId),
            });
        }

        document
            .getElementById(ElementIds.messageInput)
            ?.addEventListener(
                CustomEvents.cannedResponseClicked,
                handleCannedResponseClicked
            );

        return (): void => {
            document
                .getElementById(ElementIds.messageInput)
                ?.removeEventListener(
                    CustomEvents.cannedResponseClicked,
                    handleCannedResponseClicked
                );
        };
    });

    useEffect(() => {
        window.onresize = (): void => {
            setWindowWidth(window.innerWidth);
        };
    });

    useEffect(() => {
        if (!isDisabled) {
            messageInputRef.current?.focus({ cursor: "end" });
        }
    }, [isDisabled]);

    useEffect(() => {
        const ref = document.getElementById("inputText") as HTMLInputElement;
        if (ref != undefined) {
            ref.setSelectionRange(cursorPosition, cursorPosition);
        }
    }, [shouldForceSendIfClickedAgain, cursorPosition]);

    const submitForm = (inputText: string): void => {
        if (isSoftDisabled) {
            form.setFields([
                {
                    name: "inputText",
                    errors: [
                        softDisabledErrorMessage ??
                            "You are not allowed to send messages at this time.",
                    ],
                },
            ]);
            messageInputRef.current?.focus();
            return;
        }
        setShouldForceSendIfClickedAgain(false);
        sendMessageAsUser({
            text: inputText,
            messageType: PersistedMessageType.Text,
            // Required for operator only
            chatId: chatId,
        } as TextMessage);

        form.setFieldsValue({ [ElementIds.messageInput]: "" });
        if (saveUnsentText) {
            saveUnsentText(chatId, "");
        }

        messageInputRef.current?.focus();
    };

    const checkFieldErrorByMessage = (
        fieldName: string,
        message: string
    ): boolean =>
        form
            .getFieldError(fieldName)
            .some(
                (e) =>
                    (e as unknown as ReactElement)?.props?.children === message
            );

    const matchesExactCreditCardPattern = (s: string): boolean => {
        const textWithUrlsRemoved = s
            .replaceAll(/(https?:\/\/[^\s]+)/g, "")
            .trim();

        return (
            checkCardFormatAndSeparators(
                textWithUrlsRemoved,
                /^[0-9]{4}(\s|,|\.|-|\*){0,1}[0-9]{6}(\s|,|\.|-|\*){0,1}[0-9]{5,6}$/, // AMEX
                2
            ) ||
            checkCardFormatAndSeparators(
                textWithUrlsRemoved,
                /^((30[0-5]{1}[0-9]{1})|((36|38|39)[0-9]{2}))(\s|,|\.|-|\*){0,1}[0-9]{6}(\s|,|\.|-|\*){0,1}[0-9]{4}$/, // Diners Club
                2
            ) ||
            checkCardFormatAndSeparators(
                textWithUrlsRemoved,
                /^[0-9]{4}(\s|,|\.|-|\*){0,1}[0-9]{4}(\s|,|\.|-|\*){0,1}[0-9]{4}(\s|,|\.|-|\*){0,1}([0-9]{4}|[0-9]{1})$/, // General / Other
                3
            )
        );
    };

    const checkCardFormatAndSeparators = (
        textToTest: string,
        ccPatternRegExp: RegExp,
        numberOfExpectedSeparators: number
    ): boolean => {
        if (!ccPatternRegExp.test(textToTest)) {
            return false;
        }

        const separators = textToTest.match(new RegExp(/(\s|,|\.|-|\*)/g));

        if (!separators) {
            return true;
        }

        if (separators.length == numberOfExpectedSeparators) {
            return separators.every((entry) => entry == separators[0]);
        }

        return false;
    };

    const matchesCreditCardPattern = (s: string): boolean => {
        const urlsRemoved = s.replaceAll(/(https?:\/\/[^\s]+)/g, "");
        return new RegExp(
            /(?:\D|^)([0-9]{4})[\W]*([0-9]{4})\W*([0-9]{4})\W*([0-9]{4}|\d{1})|3[47]\d{1,2}\W*\d{6}\W*\d{6}|((30[0-5]{1}\d{1})|((36|38|39)\d{2}))\W*\d{6}\W*\d{4}/
        ).test(urlsRemoved);
    };

    const handleChangingForceSendVariableToFalse = (): void => {
        const refToGetCursor = document.getElementById(
            "inputText"
        ) as HTMLInputElement;
        setCursorPosition(refToGetCursor?.selectionStart ?? -1);
        setShouldForceSendIfClickedAgain(false);
        setIsLongErrorMessageVisible(false);
    };

    const getAriaDescribedBy = (): string | undefined => {
        if (hasEmptyMessageError) {
            return "emptyMessageError";
        } else if (hasMaxLengthError) {
            return "maxLengthError";
        } else {
            return undefined;
        }
    };

    const isCounterShowing = (): boolean => {
        return (
            form.getFieldValue("inputText")?.length >=
                thresholdLengthToDisplayCounter && isBotMessageBar
        );
    };

    const generateFormClassName = (): string => {
        let className = "message-bar";

        if (isCounterShowing()) {
            if (isLongErrorMessageVisible) {
                className +=
                    " message-bar__AdjustForCounterAndLongErrorMessage";
            }
            className += " message-bar__AdjustForCounterShowing";
        }

        return className;
    };

    return (
        <Form
            form={form}
            className={generateFormClassName()}
            layout="horizontal"
            onFieldsChange={(): void => {
                setHasEmptyMessageError(
                    checkFieldErrorByMessage("inputText", emptyMessageErrorText)
                );
                setHasMaxLengthError(
                    checkFieldErrorByMessage("inputText", maxLengthErrorText)
                );
            }}
            onFinish={(fieldValues): void => {
                if (clearTypingTimeoutHandle.current) {
                    clearTimeout(clearTypingTimeoutHandle.current);
                    try {
                        sendStopTypingIndicator(chatId);
                    } catch (error) {
                        console.log(
                            "Error sending stop typing indicator: %O",
                            error
                        );
                    }
                    clearTypingTimeoutHandle.current = null;
                }
                if (!isOperator && shouldCheckForCreditCardNumbers) {
                    if (matchesExactCreditCardPattern(fieldValues.inputText)) {
                        setShouldAlignBottom(false);
                        setShouldForceSendIfClickedAgain(true);
                        setIsLongErrorMessageVisible(false);
                        form.setFields([
                            {
                                name: "inputText",
                                errors: [
                                    "Credit card number detected! Please do not send credit card numbers in chat.",
                                ],
                                value: [""],
                            },
                        ]);
                    } else if (
                        matchesCreditCardPattern(fieldValues.inputText) &&
                        !shouldForceSendIfClickedAgain
                    ) {
                        setShouldAlignBottom(false);
                        setShouldForceSendIfClickedAgain(true);
                        setIsLongErrorMessageVisible(true);
                        form.setFields([
                            {
                                name: "inputText",
                                errors: [
                                    "Credit card number may have been detected! Please clear and do not send a credit card number. If not a credit card number, click Send.",
                                ],
                            },
                        ]);
                    } else {
                        submitForm(fieldValues.inputText);
                    }
                } else {
                    submitForm(fieldValues.inputText);
                }
            }}
        >
            <Form.Item
                className={`message-bar-item ${
                    shouldAlignBottom ? "message-bar-item--align-bottom" : ""
                }`}
            >
                {cannedResponseElement}
                <Form.Item
                    className="message-bar-item"
                    validateFirst
                    name="inputText"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: (
                                <span
                                    id="emptyMessageError"
                                    aria-live="assertive"
                                >
                                    {emptyMessageErrorText}
                                </span>
                            ),
                        },
                        ...(isBotMessageBar
                            ? [
                                  {
                                      validator: (
                                          _: RuleObject,
                                          value: string
                                      ) =>
                                          value &&
                                          value.length < messageMaxLength
                                              ? Promise.resolve()
                                              : Promise.reject({
                                                    message: (
                                                        <span
                                                            id="maxLengthError"
                                                            aria-live="assertive"
                                                        >
                                                            {maxLengthErrorText}
                                                        </span>
                                                    ),
                                                }),
                                  },
                              ]
                            : []),
                    ]}
                >
                    <TextArea
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                        showCount={isCounterShowing() ? true : undefined}
                        className="message-bar-textarea"
                        allowClear={shouldForceSendIfClickedAgain}
                        autoSize={{ minRows: 1, maxRows: 8 }}
                        placeholder="Enter message..."
                        // Note that antd has some default aria-describedby behavior that we can't fully control. It wants to set the value to the form.item name with "_help" appended.
                        // So in our case for the TextArea message input, we will have aria-describedby="inputText_help" when there is an error. The code we have for setting aria-describedby on the
                        // TextArea is currently being completely ignored, as antd is fully controlling the dynamic adding and removing of aria-describedby as validation errors are added and removed.
                        // Note sure if this logic was here because it used to work in earlier versions of antd, but will keep it for now in case it starts working again in future versions.
                        // Note that the parent div of the validation error message element will be given the cross-referenced id of "inputText_help" by antd so we should be good for accessibility.
                        aria-describedby={getAriaDescribedBy()}
                        onPressEnter={(event): void => {
                            if (!event.shiftKey) {
                                event.preventDefault();
                                form.submit();
                            }
                        }}
                        onBlur={(): void => {
                            if (window?.visualViewport === undefined) {
                                onWindowChange();
                            }
                        }}
                        onChange={(): void => {
                            const text = form.getFieldValue("inputText");
                            const isEmpty = text?.length == 0;
                            setShouldAlignBottom(!isEmpty);
                            if (!clearTypingTimeoutHandle.current && !isEmpty) {
                                sendTypingIndicator(chatId);
                                clearTypingTimeoutHandle.current = setTimeout(
                                    () => {
                                        sendStopTypingIndicator(chatId);
                                        clearTypingTimeoutHandle.current = null;
                                    },
                                    typingNotificationTimeoutTimeInSeconds *
                                        1000
                                );
                            } else if (clearTypingTimeoutHandle.current) {
                                clearTimeout(clearTypingTimeoutHandle.current);
                                clearTypingTimeoutHandle.current = setTimeout(
                                    () => {
                                        sendStopTypingIndicator(chatId);
                                        clearTypingTimeoutHandle.current = null;
                                    },
                                    typingNotificationTimeoutTimeInSeconds *
                                        1000
                                );
                            }

                            if (isEmpty) {
                                setIsLongErrorMessageVisible(false);
                                sendStopTypingIndicator(chatId);
                                if (clearTypingTimeoutHandle.current) {
                                    clearTimeout(
                                        clearTypingTimeoutHandle.current
                                    );
                                    clearTypingTimeoutHandle.current = null;
                                }

                                // If the input updated to empty string when we found a soft match
                                // reset form validation to not show empty message error since we expected them to clear
                                if (shouldForceSendIfClickedAgain) {
                                    form.resetFields();
                                }
                            }

                            // Clear credit card soft match message
                            handleChangingForceSendVariableToFalse();

                            if (saveUnsentText) {
                                saveUnsentText(chatId, text ?? "");
                            }

                            const textAreaRef =
                                document.getElementById("inputText");
                            if (
                                shouldAlignBottom &&
                                textAreaRef &&
                                textAreaRef.scrollTop &&
                                textAreaRef.scrollHeight
                            ) {
                                textAreaRef.scrollTop =
                                    textAreaRef.scrollHeight;
                            }
                        }}
                        disabled={isDisabled}
                        ref={messageInputRef}
                        aria-label="Enter message"
                        maxLength={
                            isBotMessageBar ? messageMaxLength : undefined
                        }
                    />
                </Form.Item>
                <Form.Item hidden={!fileUploadsEnabled}>
                    <Tooltip placement="top" title={`Send ${fileRelatedNoun}`}>
                        <Button
                            className="image__button-outlined"
                            size="large"
                            onClick={(): void =>
                                setIsPhotoModalVisible(!isPhotoModalVisible)
                            }
                            disabled={isDisabled}
                        >
                            <PictureOutlined aria-label="upload file" />
                        </Button>
                    </Tooltip>
                </Form.Item>
                <Form.Item>
                    <Button
                        className="send-button"
                        htmlType="submit"
                        type="primary"
                        size="large"
                        icon={<MessageFilled />}
                        disabled={isDisabled}
                    >
                        <span className="hide-on-small">Send</span>
                    </Button>
                </Form.Item>
                <Form.Item className="sr-only">
                    {isBotMessageBar && (
                        <BotMessageBarTextCounter
                            messageLength={
                                form.getFieldValue("inputText")?.length ?? 0
                            }
                            messageMaxLength={messageMaxLength}
                            thresholdLengthToDisplayCounter={
                                thresholdLengthToDisplayCounter
                            }
                            dataTestId="messageBar-CustomAccessibleTextCounter"
                        />
                    )}
                </Form.Item>
            </Form.Item>
            <FileUploadModal
                isVisible={isPhotoModalVisible}
                setIsModalVisible={setIsPhotoModalVisible}
                windowWidth={windowWidth}
                chatId={chatId}
                isOperator={isOperator}
                sendFileAsUser={sendFileAsUser}
            />
        </Form>
    );
};

export default MessageBar;
