import { IsApprovedDomainRegex } from "@Utilities/ApprovedDomainRegex";
import { Space, Typography } from "antd";
import { marked } from "marked";

const { Text } = Typography;

interface BotChatBubbleProps {
    text: string;
    isBotChoice: boolean;
}

const BotChatBubble = ({
    text,
    isBotChoice,
}: BotChatBubbleProps): JSX.Element => {
    const bubbleClassName = "chat-bubble" + (isBotChoice ? " bot-choice" : "");

    const processText = (): string => {
        // First, let's convert markdown to HTML.
        let htmlText = marked.parse(text) as string;

        // Now clean up any extra newline characters following specific HTML tags.
        // This is done to improve the formatting of the message since we style with "white-space: pre-wrap".
        htmlText = htmlText.replace(/<\/p>\n/g, "");
        htmlText = htmlText.replace(/<\/ul>\n/g, "");

        // Next, we need to process each HTML link - If the link is not an approved domain, it will be converted back the markdown format.
        // Why? Not sure but this was the original implementation - business users have some reason for this.
        const htmlLinkRegex = /<a href="(https?:\/\/[^\s"]+)"[^>]*>(.*?)<\/a>/g;
        htmlText = htmlText.replace(htmlLinkRegex, (match, url, linkText) => {
            if (IsApprovedDomainRegex.test(url)) {
                return match;
            } else {
                return `[${linkText}](${url})`;
            }
        });

        return htmlText;
    };

    return (
        <Space className={bubbleClassName} direction="vertical" size={0}>
            <Text>
                <span
                    className="chat-bubble__botchat-text"
                    data-testid="botchat-text"
                    dangerouslySetInnerHTML={{ __html: processText() }}
                />
            </Text>
        </Space>
    );
};

export default BotChatBubble;
