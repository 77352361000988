import { useAppSelector } from "../../hooks";
import { TenantRoute } from "@Models/TenantRoute";
import { WelcomeIssue } from "@Models/chat/WelcomeIssue";
import { SelectIssueAbstract } from "@Models/welcomeView/SelectIssueAbstract";
import { tenantRoute } from "@Src/tenantConfiguration/SharedTenantConfiguration";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import {
    Form,
    FormInstance,
    Input,
    InputRef,
    Select,
    Space,
    Typography,
} from "antd";
import { IssueInformationModel } from "@Models/chat/IssueInformationModel";
import { FormItemLabel } from "../../Form/FormItemLabel";
import { BriefcaseTagValidationResponseModel } from "../../../../models/case/BriefcaseTagValidationResponseModel";
import { BriefcaseCaseTagNames } from "@Models/tag/BriefcaseCaseTagNames";
const { Text } = Typography;
const { Option } = Select;

interface SelectIssueProps {
    issueSelected: WelcomeIssue | "";
    setIssueSelected: React.Dispatch<React.SetStateAction<WelcomeIssue | "">>;
    isFormDisabled: boolean;
    formInstance: FormInstance<unknown>;
    issues: Array<{ value: WelcomeIssue; qnaKey?: string }>;
}

interface IdentifiedBriefcaseTagValidationResponseModel
    extends BriefcaseTagValidationResponseModel {
    tagType: string;
}

const SelectIssue = forwardRef<SelectIssueAbstract, SelectIssueProps>(
    (
        {
            issueSelected,
            setIssueSelected,
            isFormDisabled,
            formInstance,
            issues,
        }: SelectIssueProps,
        ref
    ) => {
        const validationApi = useAppSelector(
            (state) => state.customerApp.validationApi
        );

        const orderNumberRef = useRef<InputRef>(null);
        const itemNumberRef = useRef<InputRef>(null);

        const handleItemValidation = (
            itemNumber: string
        ): Promise<IdentifiedBriefcaseTagValidationResponseModel> => {
            if (itemNumber.toLowerCase() == "none") {
                alert("Item Number is invalid.");
                return Promise.resolve({
                    isValid: false,
                } as IdentifiedBriefcaseTagValidationResponseModel);
            }

            return validationApi
                .validateTagAsync(
                    itemNumber,
                    BriefcaseCaseTagNames.InternalNames.Item
                )
                .then((data) => {
                    if (!data.isValid) {
                        alert("Item Number is invalid.");
                    }
                    return {
                        ...data,
                        tagType: BriefcaseCaseTagNames.InternalNames.Item,
                    } as IdentifiedBriefcaseTagValidationResponseModel;
                });
        };

        const handleOrderValidation = (
            orderNumber: string
        ): Promise<IdentifiedBriefcaseTagValidationResponseModel> => {
            if (tenantRoute != TenantRoute.wss) {
                return Promise.resolve({
                    isValid: true,
                } as IdentifiedBriefcaseTagValidationResponseModel);
            }

            return validationApi
                .validateTagAsync(
                    orderNumber,
                    BriefcaseCaseTagNames.InternalNames.Order
                )
                .then((data) => {
                    if (!data.isValid) {
                        alert("Order Number is invalid.");
                    }
                    return {
                        ...data,
                        tagType: BriefcaseCaseTagNames.InternalNames.Order,
                    } as IdentifiedBriefcaseTagValidationResponseModel;
                });
        };

        // Allows the parent function to call validation on the child when submitting
        useImperativeHandle(ref, () => ({
            async handleValidation(): Promise<BriefcaseTagValidationResponseModel> {
                const orderNumber = orderNumberRef.current?.input?.value;
                const itemNumber = itemNumberRef.current?.input?.value;
                const promiseArray = [];

                switch (issueSelected) {
                    case WelcomeIssue.Order:
                    case WelcomeIssue.Returns: {
                        if (orderNumber) {
                            promiseArray.push(
                                handleOrderValidation(orderNumber)
                            );
                        }
                        break;
                    }
                    case WelcomeIssue.Item: {
                        if (itemNumber) {
                            promiseArray.push(handleItemValidation(itemNumber));
                        }
                        if (orderNumber) {
                            promiseArray.push(
                                handleOrderValidation(orderNumber)
                            );
                        }
                        break;
                    }
                }

                const resultArray = await Promise.all(promiseArray);
                return resultArray.reduce<IdentifiedBriefcaseTagValidationResponseModel>(
                    (accumulator, current) => {
                        if (!current.isValid) {
                            // If any of the objects have isValid: false, set isValid to false in the accumulator
                            return {
                                isValid: false,
                                validatedValue: "",
                                url: "",
                                tagType: "",
                            };
                        }
                        // If all objects are valid so far, keep the accumulator's isValid as true
                        accumulator.tagType = "";
                        accumulator.url = `${accumulator.url}${
                            accumulator.url === "" ? "" : "|"
                        }${current.tagType}|${current.url}`;
                        return accumulator;
                    },
                    {
                        isValid: true,
                        validatedValue: "",
                        url: "",
                        tagType: "",
                    }
                );
            },
            getIssueInformation(): IssueInformationModel {
                const orderNumber = orderNumberRef.current?.input?.value;
                const itemNumber = itemNumberRef.current?.input?.value;

                return {
                    issue: issueSelected,
                    itemNumber: itemNumber,
                    orderNumber: orderNumber,
                } as IssueInformationModel;
            },
            isFormInvalid(): boolean {
                return (
                    formInstance.getFieldError("itemNumber").length > 0 ||
                    formInstance.getFieldError("orderNumber").length > 0 ||
                    (issueSelected === WelcomeIssue.Item &&
                        !formInstance.getFieldValue("itemNumber"))
                );
            },
        }));

        const OrderFormItem = (
            <Form.Item
                name="orderNumber"
                label={FormItemLabel(
                    "Order Number",
                    "order-form__order-number"
                )}
                rules={[
                    {
                        required: false,
                    },
                    {
                        type: "string",
                        pattern: /^[0-9a-zA-Z]+$/,
                        message: "Please use only letters and numbers.",
                    },
                ]}
            >
                <Input
                    ref={orderNumberRef}
                    disabled={isFormDisabled}
                    id="order-form__order-number"
                    onChange={function (): void {
                        formInstance.setFields([
                            {
                                name: "orderNumber",
                                errors: [],
                            },
                        ]);
                    }}
                />
            </Form.Item>
        );

        const ItemNumberFormItem = (
            <Form.Item
                name="itemNumber"
                label={FormItemLabel("Item Number", "item-form__item-number")}
                rules={[
                    {
                        required: true,
                        message: "Please enter an item number.",
                    },
                ]}
            >
                <Input
                    ref={itemNumberRef}
                    disabled={isFormDisabled}
                    maxLength={20}
                    id="item-form__item-number"
                    onChange={function (): void {
                        formInstance.setFields([
                            {
                                name: "itemNumber",
                                errors: [],
                            },
                        ]);
                    }}
                />
            </Form.Item>
        );

        const SelectedIssue = (): React.JSX.Element => {
            switch (issueSelected) {
                case WelcomeIssue.Order:
                case WelcomeIssue.Returns:
                    return OrderFormItem;
                case WelcomeIssue.Item:
                    return (
                        <Space.Compact direction="vertical">
                            {ItemNumberFormItem}
                            {OrderFormItem}
                        </Space.Compact>
                    );
                default:
                    return (
                        <Text data-testid="selected-issue-default-text">
                            Select an Issue, some options require more
                            information.
                        </Text>
                    );
            }
        };

        return (
            <>
                <Form.Item
                    name="issue"
                    label={FormItemLabel(
                        "Select Issue",
                        "welcome-view__content__form__issue-select"
                    )}
                    rules={[
                        {
                            required: true,
                            message: "Please select an issue.",
                        },
                    ]}
                    // Validates onChange so the Start Chat button becomes enabled immediately and tab nav won't skip it
                    validateTrigger={"onChange"}
                >
                    <Select
                        className="welcome-view__content__form__issue-select"
                        onChange={(value): void => {
                            setIssueSelected(value as unknown as WelcomeIssue);
                        }}
                        disabled={isFormDisabled}
                        placeholder="Select Issue"
                        id="welcome-view__content__form__issue-select"
                    >
                        {issues.map((issue, index) => (
                            <Option key={index} value={issue.value}>
                                {issue.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Space
                    direction="vertical"
                    className="welcome-view__content__form__issues"
                >
                    {SelectedIssue()}
                </Space>
            </>
        );
    }
);

SelectIssue.displayName = "SelectIssue";

export default SelectIssue;
