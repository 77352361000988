import { TenantRoute } from "../models/TenantRoute";
import jsCookie from "js-cookie";
import { CookieKeys } from "@Utilities/CookieKeys";

export interface TenantConfiguration {
    documentTitle: string;
    customerServiceDeptName: string;
    shouldCheckForCreditCardNumbers: boolean;
}

export const tenantRoute = jsCookie.get(CookieKeys.tenantRoute) ?? "";

export const isOperator = jsCookie.get(CookieKeys.isOperator) ? true : false;

export const isStatsDashboard = jsCookie.get(CookieKeys.isStatsDashboard)
    ? true
    : false;

export const isBriefcaseFeedback = jsCookie.get(CookieKeys.isBriefcaseFeedback);

function getTenantConfiguration(): TenantConfiguration {
    const defaultSwitchboardTitle = "Switchboard";
    switch (tenantRoute) {
        case TenantRoute.trs:
            return {
                documentTitle: isOperator
                    ? defaultSwitchboardTitle
                    : "The Restaurant Store Online Chat",
                customerServiceDeptName: "Customer Solutions",
                shouldCheckForCreditCardNumbers: true,
            };
        case TenantRoute.cna:
            return {
                documentTitle: isOperator
                    ? defaultSwitchboardTitle
                    : "CNA Online Chat",
                customerServiceDeptName: "Account Support",
                shouldCheckForCreditCardNumbers: true,
            };
        case TenantRoute.clarkPro:
            return {
                documentTitle: isOperator
                    ? defaultSwitchboardTitle
                    : "ClarkPro Online Chat",
                customerServiceDeptName: "Customer Solutions",
                shouldCheckForCreditCardNumbers: true,
            };
        case TenantRoute.rkw:
            return {
                documentTitle: isOperator
                    ? defaultSwitchboardTitle
                    : "Ready Kitchen Warranty Online Chat",
                customerServiceDeptName: "Warranty Service",
                shouldCheckForCreditCardNumbers: false,
            };
        case TenantRoute.tax:
            return {
                documentTitle: isOperator
                    ? defaultSwitchboardTitle
                    : "WebstaurantStore Online Chat",
                customerServiceDeptName: "Tax Exemption",
                shouldCheckForCreditCardNumbers: true,
            };
        case TenantRoute.risk:
            return {
                documentTitle: isOperator
                    ? defaultSwitchboardTitle
                    : "WebstaurantStore Online Chat",
                customerServiceDeptName: "Risk",
                shouldCheckForCreditCardNumbers: true,
            };
        default:
            return {
                documentTitle: isOperator
                    ? defaultSwitchboardTitle
                    : "WebstaurantStore Online Chat",
                customerServiceDeptName: "Customer Solutions",
                shouldCheckForCreditCardNumbers: true,
            };
    }
}

const tenantConfiguration = getTenantConfiguration();

export const documentTitle = tenantConfiguration.documentTitle;
export const customerServiceDeptName =
    tenantConfiguration.customerServiceDeptName;
export const shouldCheckForCreditCardNumbers =
    tenantConfiguration.shouldCheckForCreditCardNumbers;
