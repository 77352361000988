import { SwitchboardConfig } from "@Models/SwitchboardConfig";
import { AxiosInstance, AxiosResponse } from "axios";
import { CreateGuestChatRequest } from "./../dtos/chat/CreateGuestChatRequest";
import { CreateChatResponse } from "./../dtos/chat/CreateChatResponse";
import { CreateLoggedInChatRequest } from "./../dtos/chat/CreateLoggedInChatRequest";
import { JoinChatRequest } from "@Hubs/chat/dtos/JoinChatRequest";
import { JoinChatAsCustomerResponse } from "./../dtos/chat/JoinChatAsCustomerResponse";
import { OpenChatResponse } from "./../dtos/chat/OpenChatResponse";
import { AddFeedbackRequest } from "./../dtos/chat/AddFeedbackRequest";
import { SendTranscriptRequest } from "./../dtos/chat/SendTranscriptRequest";
import { ChangeFromBotToRepRequest } from "./../dtos/chat/ChangeFromBotToRepRequest";
import { ChatState } from "@Models/chat/ChatState";
import { SubmitChatBotFeedbackRequest } from "./../dtos/chat/SubmitChatBotFeedbackRequest";
import { SubmitChatBotFeedbackResponse } from "./../dtos/chat/SubmitChatBotFeedbackResponse";
import { ChatApiShared } from "@Api/ChatApiShared";
import { InsertCustomActivityMessageRequest } from "@Src/hubs/chat/dtos/InsertCustomActivityMessageRequest";
import { RecordChatEventRequest } from "@Src/hubs/chat/dtos/RecordChatEventRequest";
import { ConvertToConcreteMessages } from "@Utilities/MessageHelper";

export class ChatApi extends ChatApiShared {
    constructor(config: SwitchboardConfig, axios: AxiosInstance) {
        super(config, axios);
    }

    public createChat(
        request: CreateGuestChatRequest
    ): Promise<CreateChatResponse> {
        return this.axios
            .post(this.buildSwitchboardApiUrl("Chat"), request)
            .then((response) => response.data);
    }

    public createLoggedInChat(
        request: CreateLoggedInChatRequest
    ): Promise<CreateChatResponse> {
        return this.axios
            .post(this.buildSwitchboardApiUrl("Chat/loggedIn"), request)
            .then((response) => response.data);
    }

    public joinChatAsCustomer(
        request: JoinChatRequest
    ): Promise<JoinChatAsCustomerResponse> {
        return this.axios
            .post(
                this.buildSwitchboardApiUrl("Chat/joinChatAsCustomer"),
                request
            )
            .then((response) => {
                const joinResponse =
                    response.data as JoinChatAsCustomerResponse;

                if (joinResponse) {
                    joinResponse.messages = ConvertToConcreteMessages(
                        joinResponse?.messages
                    );
                }

                if (joinResponse.lastBotActivityReceivedDate) {
                    joinResponse.lastBotActivityReceivedDate = new Date(
                        joinResponse.lastBotActivityReceivedDate as unknown as string
                    );
                }

                if (joinResponse.lastJoinableDate) {
                    joinResponse.lastJoinableDate = new Date(
                        joinResponse.lastJoinableDate
                    );
                }

                return joinResponse;
            })
            .catch((error) => {
                console.error(
                    "Error occurred while joining chat as customer, error:%O",
                    error
                );
                return Promise.reject(error);
            });
    }

    public async getChatOnlineStatus(forceChat?: boolean): Promise<boolean> {
        let callPath = "Chat/onlineStatus";
        callPath += forceChat ? "?forceChat=true" : "";
        const response = await this.axios.get(
            this.buildSwitchboardApiUrl(callPath)
        );
        return response.data;
    }

    public async getOperatorsOnlineStatus(): Promise<boolean> {
        const response = await this.axios.get(
            this.buildSwitchboardApiUrl("Operator/areOperatorsOnline")
        );
        return response.data;
    }

    public getOpenChat(): Promise<OpenChatResponse> {
        return this.axios
            .get(this.buildSwitchboardApiUrl("Chat/openChat"))
            .then((response) => {
                const data = response.data as OpenChatResponse;
                if (data.lastBotActivityReceivedDate) {
                    data.lastBotActivityReceivedDate = new Date(
                        data.lastBotActivityReceivedDate
                    );
                }
                if (data.lastJoinableDate) {
                    data.lastJoinableDate = new Date(data.lastJoinableDate);
                }
                return data;
            });
    }

    public addFeedback(request: AddFeedbackRequest): Promise<void> {
        return this.axios
            .post(this.buildSwitchboardApiUrl("Chat/addFeedback"), request)
            .then(
                () => {
                    return;
                },
                (error) => {
                    console.error(
                        "Error occurred while adding feedback:",
                        error
                    );
                    return Promise.reject(error);
                }
            );
    }

    public sendTranscript(request: SendTranscriptRequest): Promise<void> {
        return this.axios
            .post(this.buildSwitchboardApiUrl("Chat/sendTranscript"), request)
            .then(
                () => {
                    return;
                },
                (error) => {
                    console.error(
                        "Error occurred while requesting transcript:",
                        error
                    );
                    return Promise.reject(error);
                }
            );
    }

    public endChat(): Promise<void> {
        return this.axios
            .post(this.buildSwitchboardApiUrl("Chat/endChat"))
            .then(
                () => {
                    return;
                },
                (error) => {
                    console.error("Error occurred while ending chat:", error);
                    return Promise.reject(error);
                }
            );
    }

    public getPlaceInQueue(chatId: string): Promise<number | null> {
        return this.axios
            .get(
                this.buildSwitchboardApiUrl(
                    `Chat/getPlaceInQueue?chatId=${chatId}`
                )
            )
            .then((response) => {
                return response.data;
            });
    }

    public async changeFromBotToRep(
        request: ChangeFromBotToRepRequest
    ): Promise<ChatState> {
        return this.axios
            .post(
                this.buildSwitchboardApiUrl("Chat/changeFromBotToRep"),
                request
            )
            .then((response) => {
                return response.data as ChatState;
            })
            .catch((error) => {
                console.error("Error occurred while transferring chat:", error);
                return Promise.reject(error);
            });
    }

    public submitChatBotFeedback(
        request: SubmitChatBotFeedbackRequest
    ): Promise<SubmitChatBotFeedbackResponse> {
        return this.axios
            .post(
                this.buildSwitchboardApiUrl("Chat/submitChatBotFeedback"),
                request
            )
            .then(
                (response: AxiosResponse<SubmitChatBotFeedbackResponse>) =>
                    response.data,
                (error) => {
                    console.error(
                        "Error occurred while submitting ChatBot feedback:",
                        error
                    );
                    // 500 means server exception - so we can't be sure enough of
                    // the backend's state at this point to allow resubmission.
                    if (error.response?.status === 500) {
                        return Promise.reject(error);
                    }
                    return Promise.resolve({
                        success: false,
                        isRecoverableStatusCode: true,
                    } as SubmitChatBotFeedbackResponse);
                }
            );
    }

    public resetSession(): Promise<void> {
        return this.axios.post(
            this.buildSwitchboardApiUrl("Chat/resetSession")
        );
    }

    public timeOut(): Promise<void> {
        return this.axios.post(this.buildSwitchboardApiUrl("Chat/timeOut"));
    }

    public updateSawFeedbackScreen(): Promise<void> {
        return this.axios.post(
            this.buildSwitchboardApiUrl("Chat/sawFeedbackScreen")
        );
    }

    public async insertCustomActivityMessage(
        request: InsertCustomActivityMessageRequest
    ): Promise<void> {
        return await this.axios.post(
            this.buildSwitchboardApiUrl("Chat/insertCustomActivityMessage"),
            request
        );
    }

    public async recordChatEvent(
        request: RecordChatEventRequest
    ): Promise<void> {
        return await this.axios.post(
            this.buildSwitchboardApiUrl("Chat/recordChatEvent"),
            request
        );
    }
}
