import React, { useEffect } from "react";
import { useAppSelector } from "../hooks";

const withSessionReset = <PropType,>(
    WrappedComponent: React.ComponentType<PropType>
): React.FC<PropType> => {
    const SessionReset: React.FC<PropType> = (props) => {
        const chatApi = useAppSelector((state) => state.customerApp.chatApi);
        useEffect(() => {
            chatApi
                .resetSession()
                .then(() => {
                    console.log("Chat session reset successfully");
                })
                .catch(() => {
                    console.log("Chat session failed to be reset");
                });
        }, [chatApi]);
        return <WrappedComponent {...props} />;
    };
    return SessionReset;
};

export default withSessionReset;
