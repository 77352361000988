import React, { Component, ReactNode } from "react";
import { FailureType } from "@Models/customer/FailureType";

import WebChatErrorViewSwitcher from "./WebChatErrorViewSwitcher";

interface ErrorBoundaryForWebChatProps {
    fallbackToRep: (chat: string | undefined, failureType: FailureType) => void;
}

interface State {
    hasError: boolean;
}

class ErrorBoundaryForWebChat extends Component<
    ErrorBoundaryForWebChatProps,
    State
> {
    public state: State = { hasError: false };

    public static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    public render(): ReactNode {
        if (this.state.hasError) {
            return (
                <WebChatErrorViewSwitcher
                    fallbackToRep={this.props.fallbackToRep}
                ></WebChatErrorViewSwitcher>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundaryForWebChat;
