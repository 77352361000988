import React from "react";
import { Button, Popconfirm, Space, Typography } from "antd";
import "./MyItemIsNotListedButtonWithPopconfirm.less";
const { Text } = Typography;

interface MyItemIsNotListedButtonWithPopconfirmProps {
    isSubmitting: boolean;
    myItemIsNotListedButtonPopconfirmOnConfirmHandler: any;
    "data-testid": string;
    styleMargin?: string;
}

const MyItemIsNotListedButtonWithPopconfirm = (
    props: MyItemIsNotListedButtonWithPopconfirmProps
): React.JSX.Element => {
    const {
        isSubmitting,
        myItemIsNotListedButtonPopconfirmOnConfirmHandler,
        "data-testid": dataTestId,
        styleMargin = "12px 0",
    } = props;

    return (
        <Popconfirm
            title={
                <Space
                    size={0}
                    data-testid={`${dataTestId}-popconfirm-title-container`}
                >
                    <Text data-testid={`${dataTestId}-popconfirm-title-text`}>
                        Are you sure you want to report that your item is not
                        listed?
                    </Text>
                </Space>
            }
            okText="Yes"
            cancelText="No"
            onConfirm={myItemIsNotListedButtonPopconfirmOnConfirmHandler}
            disabled={isSubmitting}
            data-testid={`${dataTestId}-popconfirm`}
        >
            <Button
                style={{ margin: styleMargin }}
                disabled={isSubmitting}
                data-testid={`${dataTestId}-button`}
            >
                My Item Is Not Listed
            </Button>
        </Popconfirm>
    );
};

export default MyItemIsNotListedButtonWithPopconfirm;
