import { OperatorApi } from "@Api/OperatorApi";
import { CustomerApi } from "@Api/CustomerApi";
import { getTabId } from "./Startup";
import { WorkerInterval } from "./WorkInterval";

let heartbeatIntervalWorker: WorkerInterval | undefined;

export function startHeartbeatTicking(api: CustomerApi | OperatorApi): void {
    async function tick(): Promise<void> {
        const heartbeatSuccess = await api.heartbeat(getTabId());
        if (heartbeatSuccess) {
            console.log("Heartbeat ticked.");
        } else {
            console.warn("Heartbeat failed.");
        }
    }
    tick();

    heartbeatIntervalWorker = new WorkerInterval(async () => {
        tick();
    }, 15000);
    console.log("Started heartbeat interval.");
}

export function clearHeartbeatTickInterval(): void {
    if (heartbeatIntervalWorker) {
        console.log("Stopped heartbeat interval.");
        heartbeatIntervalWorker.stop();
    }
}
