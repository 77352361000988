import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Typography, Space, InputRef } from "antd";
import { getQueryParam } from "@Utilities/QueryStringHelper";
import { QueryParams } from "@Utilities/QueryParams";
import { CreateChatResponse } from "@Api/dtos/chat/CreateChatResponse";
import { useAppDispatch, useAppSelector } from "../hooks";
import { ValidEmailAddressRegex } from "@Utilities/EmailRegex";
import { IssueInformationModel } from "@Models/chat/IssueInformationModel";

import "./WelcomeView.less";
import { WelcomeIssue } from "@Models/chat/WelcomeIssue";
import { viewStateChanged } from "../customerAppSlice";
import { ViewState } from "../viewState";
import { getTabId } from "@Utilities/Startup";
import { SelectIssueAbstract } from "@Models/welcomeView/SelectIssueAbstract";
import SelectIssue from "./SelectIssue/SelectIssue";
import { TenantRoute } from "@Models/TenantRoute";
import { tenantRoute } from "@Src/tenantConfiguration/SharedTenantConfiguration";
import SelectIssueRkw from "./SelectIssue/SelectIssue-RKW";
import { Issues, WssIssues } from "../issues";
import { FormItemLabel } from "../Form/FormItemLabel";
import {
    getEnvironmentPrefix,
    showLegalDisclaimerOnWelcomePage,
} from "@Src/tenantConfiguration/CustomerTenantConfiguration";
import { BriefcaseCaseTagNames } from "@Models/tag/BriefcaseCaseTagNames";

const { Text, Title } = Typography;

interface WelcomeViewProps {
    createGuestChat: (
        name: string,
        email: string,
        issueInformation: IssueInformationModel
    ) => Promise<CreateChatResponse>;
    createLoggedInChat: (
        name: string,
        email: string,
        issueInformation: IssueInformationModel
    ) => Promise<CreateChatResponse>;
    forceChatParam: boolean;
}

interface FormFields {
    name: string;
    email: string;
}

const userIndexParam = getQueryParam(QueryParams.userIndex);

const nameFieldId = "welcome-view__content__form__name";
const emailFieldId = "welcome-view__content__form__email";

const WelcomeView = ({
    createGuestChat,
    createLoggedInChat,
    forceChatParam,
}: WelcomeViewProps): React.JSX.Element => {
    const dispatch = useAppDispatch();

    const [form] = Form.useForm();
    const [isFormDisabled, setIsFormDisabled] = useState<boolean>(true);
    const [welcomeTitle, setWelcomeTitle] =
        useState<string>("Welcome to Chat!");
    const [issueSelected, setIssueSelected] = useState<WelcomeIssue | "">("");
    const [registeredName, setRegisteredName] = useState<string>();
    const startRef = useRef<InputRef>(null);

    const chatApi = useAppSelector((state) => state.customerApp.chatApi);
    const customerApi = useAppSelector(
        (state) => state.customerApp.customerApi
    );

    const selectIssueRef = useRef<SelectIssueAbstract>(null);

    // When the app first loads and the modal is shown, if there is a user index automatically start the chat with the details
    // from the API unless the API call fails.
    useEffect(() => {
        new Promise<void>((resolve) => {
            if (!userIndexParam) {
                resolve();
                return;
            }
            customerApi
                .getCustomerByUserIndexForCustomer(userIndexParam)
                .then((customerData) => {
                    if (customerData?.name && customerData?.email) {
                        setRegisteredName(customerData.name);
                        form.setFieldsValue({
                            name: customerData.name,
                            email: customerData.email,
                        });
                        setWelcomeTitle(
                            "Welcome to Chat, " + customerData.name + "!"
                        );
                    }
                })
                .finally(() => resolve());
        }).finally(() => {
            setIsFormDisabled(false);
            startRef.current?.focus();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUrlFromAccumulatedUrl = (
        accumulatedUrl: string,
        key: string
    ): string => {
        if (accumulatedUrl.indexOf(`${key}|`) < 0) {
            return "";
        }
        // find the appropriate url from the accumulated url string, formatted as key1|url1|key2|url2
        return accumulatedUrl.split(`${key}|`)[1].split("|")[0];
    };

    const handleSubmit = async (formValues: FormFields): Promise<void> => {
        setIsFormDisabled(true);

        const issueInfo: IssueInformationModel =
            selectIssueRef.current?.getIssueInformation() as IssueInformationModel;

        // Calls the child components validation function
        const validationResponse =
            await selectIssueRef.current?.handleValidation();

        issueInfo.itemUrl = getUrlFromAccumulatedUrl(
            validationResponse?.url ?? "",
            BriefcaseCaseTagNames.InternalNames.Item
        );
        issueInfo.orderUrl = getUrlFromAccumulatedUrl(
            validationResponse?.url ?? "",
            BriefcaseCaseTagNames.InternalNames.Order
        );

        const newChat = {
            name: formValues.name.trim(),
            email: formValues.email.trim(),
            issueInfo: issueInfo,
        };

        if (validationResponse?.isValid && newChat.name && newChat.email) {
            const isChatOnline = await chatApi.getChatOnlineStatus(
                forceChatParam
            );

            if (!isChatOnline) {
                dispatch(viewStateChanged(ViewState.ChatUnavailable));
                return;
            }

            try {
                const isValidTab = await customerApi.setConnection(getTabId());
                if (!isValidTab) {
                    dispatch(viewStateChanged(ViewState.HasExistingTab));
                    return;
                }
            } catch {
                dispatch(viewStateChanged(ViewState.Error));
                return;
            }

            userIndexParam
                ? createLoggedInChat(
                      newChat.name,
                      newChat.email,
                      newChat.issueInfo
                  )
                : createGuestChat(
                      newChat.name,
                      newChat.email,
                      newChat.issueInfo
                  );
        } else {
            setIsFormDisabled(false);
        }
    };

    const getTenantIssues = (): React.JSX.Element => {
        switch (tenantRoute) {
            case TenantRoute.rkw:
                return (
                    <SelectIssueRkw
                        isFormDisabled={isFormDisabled}
                        issueSelected={issueSelected}
                        setIssueSelected={setIssueSelected}
                        formInstance={form}
                        ref={selectIssueRef}
                    />
                );
            case TenantRoute.wss:
                return (
                    <SelectIssue
                        isFormDisabled={isFormDisabled}
                        issueSelected={issueSelected}
                        setIssueSelected={setIssueSelected}
                        formInstance={form}
                        ref={selectIssueRef}
                        issues={WssIssues}
                    />
                );
            default:
                return (
                    <SelectIssue
                        isFormDisabled={isFormDisabled}
                        issueSelected={issueSelected}
                        setIssueSelected={setIssueSelected}
                        formInstance={form}
                        ref={selectIssueRef}
                        issues={Issues}
                    />
                );
        }
    };

    const getTenantDisplayName = (): string => {
        let tenantDisplayName;

        switch (tenantRoute) {
            case TenantRoute.rkw:
                tenantDisplayName = "Warranty Service Team";
                break;
            case TenantRoute.wss:
                tenantDisplayName = "WebstaurantStore";
                break;
            case TenantRoute.trs:
                tenantDisplayName = "The Restaurant Store";
                break;
            case TenantRoute.tax:
                tenantDisplayName = "Tax Exemption Team";
                break;
            case TenantRoute.risk:
                tenantDisplayName = "Risk Team";
                break;
            case TenantRoute.cna:
                tenantDisplayName = "Account Support";
                break;
            default:
                tenantDisplayName = "";
        }

        return tenantDisplayName;
    };

    const getPrivacyPolicyLink = (): string => {
        return `https://www.${getEnvironmentPrefix()}webstaurantstore.com/policies.html#Privacy_Policy`;
    };

    const getTermsOfUseLink = (): string => {
        return `https://www.${getEnvironmentPrefix()}webstaurantstore.com/policies.html#Terms_of_Use`;
    };

    return (
        <>
            <h1 className="sr-only">{getTenantDisplayName()} Online Chat</h1>
            <div className="welcome-view">
                <div className="welcome-view__content">
                    <Space
                        direction="vertical"
                        className="welcome-view__content__header"
                    >
                        <Title level={2}>{welcomeTitle}</Title>
                        <Text>
                            Please fill out the information, so we can better
                            service your needs.
                        </Text>
                    </Space>
                    <Form
                        form={form}
                        onFinish={handleSubmit}
                        layout="vertical"
                        className="welcome-view__content__form"
                        initialValues={{
                            ["issue"]: undefined, // Set initial value to undefined so it will use Select placeholder prop
                        }}
                        validateTrigger={[]}
                    >
                        <Space
                            size={4}
                            className="welcome-view__content__form__main-text"
                        >
                            <Text type="danger" style={{ fontSize: "12px" }}>
                                *
                            </Text>
                            <Text className="welcome-view__content__required-text">
                                Required Fields
                            </Text>
                        </Space>
                        <Form.Item
                            name="name"
                            label={FormItemLabel("Name", nameFieldId)}
                            validateTrigger={[]}
                            rules={[
                                {
                                    required: true,
                                    message: "Please add your name.",
                                },
                                {
                                    validator: (
                                        _,
                                        formName
                                    ): Promise<Error | void> => {
                                        const hasAcceptableCharacters =
                                            new RegExp(
                                                /^[-a-zA-Z ]*([a-zA-Z]+)[-a-zA-Z ]*$/
                                            ).test(formName);

                                        if (
                                            hasAcceptableCharacters ||
                                            formName === registeredName ||
                                            formName === ""
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            new Error(
                                                "Please use only letters and dashes."
                                            )
                                        );
                                    },
                                },
                            ]}
                        >
                            <Input
                                disabled={isFormDisabled}
                                maxLength={100}
                                ref={startRef}
                                autoComplete="name"
                                id={nameFieldId}
                                onChange={function (): void {
                                    // Design team wants validation errors cleared when input changes
                                    form.setFields([
                                        {
                                            name: "name",
                                            errors: [],
                                        },
                                    ]);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label={FormItemLabel("Email", emailFieldId)}
                            validateTrigger={[]}
                            rules={[
                                {
                                    required: true,
                                    message: "Please add your email address.",
                                },
                                {
                                    type: "string",
                                    pattern: ValidEmailAddressRegex,
                                    message: "Must be a valid email address.",
                                },
                            ]}
                        >
                            <Input
                                disabled={isFormDisabled}
                                autoComplete="email"
                                id={emailFieldId}
                                onChange={function (): void {
                                    // Design team wants validation errors cleared when input changes
                                    form.setFields([
                                        {
                                            name: "email",
                                            errors: [],
                                        },
                                    ]);
                                }}
                            />
                        </Form.Item>
                        {getTenantIssues()}
                        {showLegalDisclaimerOnWelcomePage && (
                            <Space
                                size={0}
                                className="welcome-view__content__form__legal-message"
                            >
                                <Text className="welcome-view__content__form__legal-message__text">
                                    By using the chat, you agree to our{" "}
                                    <a
                                        href={getPrivacyPolicyLink()}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Privacy Policy
                                    </a>{" "}
                                    and{" "}
                                    <a
                                        href={getTermsOfUseLink()}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Terms of Use
                                    </a>
                                    .{"  "}
                                    All chats may be recorded, used, stored, and
                                    shared as described in our{" "}
                                    <a
                                        href={getPrivacyPolicyLink()}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Privacy Policy
                                    </a>
                                    .
                                </Text>
                            </Space>
                        )}
                        <Form.Item
                            className="welcome-view__content__form__buttons"
                            name="submit"
                        >
                            <Button
                                htmlType="submit"
                                type="primary"
                                disabled={isFormDisabled}
                                loading={isFormDisabled}
                            >
                                Start Chat
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    );
};

export default WelcomeView;
