import { ClientRole } from "@Hubs/chat/dtos/ClientRole";
import { ClientMessage } from "@Hubs/chat/dtos/ClientMessage";
import { ClientMessageType } from "@Hubs/chat/dtos/ClientMessageType";

export class ReconnectingMessage extends ClientMessage {
    constructor(
        chatId: string,
        senderName: string,
        senderUserId: string,
        clientRole: ClientRole
    ) {
        super(
            chatId,
            senderName,
            senderUserId,
            clientRole,
            ClientMessageType.Reconnecting
        );
    }
}
