import { UploadFile } from "antd/lib/upload/interface";

export const isValidFileNameLength = (fileName: string): boolean => {
    // Max file name length for min.io is 255, and we are prepending 37 characters to the file name
    return fileName.length > 0 && fileName.length <= 218;
};

const getNewSafeFileNameToTest = (
    fileName: string,
    counter: number,
    extension: string,
    extensionIndex: number
): string => {
    const counterString = counter.toString();
    return extensionIndex >= 0
        ? fileName
              .substring(0, 218 - 1 - counterString.length - extension.length)
              .concat("-")
              .concat(counterString)
              .concat(extension)
        : fileName.substring(0, 218 - counter - 1);
};

export const getSafeLengthFileName = (
    fileName: string,
    uploadFileList: UploadFile[]
): string => {
    if (isValidFileNameLength(fileName)) {
        return fileName;
    }
    let occurs = 1;
    const extensionIndex = fileName.lastIndexOf(".");
    const extension =
        extensionIndex >= 0 ? fileName.substring(extensionIndex) : "";
    let newFileName = getNewSafeFileNameToTest(
        fileName,
        occurs,
        extension,
        extensionIndex
    );
    let existingFile = uploadFileList.find(
        (i) => i.name === newFileName || i.fileName === newFileName
    );

    while (existingFile != undefined) {
        occurs++;
        newFileName = getNewSafeFileNameToTest(
            fileName,
            occurs,
            extension,
            extensionIndex
        );
        existingFile = uploadFileList.find(
            (i) => i.name === newFileName || i.fileName === newFileName
        );
    }
    return newFileName;
};
