export enum WelcomeIssue {
    // Default Issues
    Order = "Order Related",
    Item = "Item Related",
    Account = "Account Related",
    PaymentTax = "Payment/Tax",
    // RKW Specific Issues
    SubmitAClaim = "Submit a Claim",
    UpdateOnClaimStatus = "Update on Claim Status",
    VerifyWarrantyStatus = "Verify Warranty Status",
    PartsProductRelated = "Parts / Product Related",
    // WSS Specific Issues
    WebstaurantPlus = "WebstaurantPlus",
    General = "General Questions",
    Returns = "Returns",
}
