import "./LiveChatFeedback.less";
import { useState, useEffect, useRef } from "react";
import { Typography, Card, Space, Input, Button, Form } from "antd";
const { Text, Title } = Typography;
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ValidEmailAddressRegex } from "@Utilities/EmailRegex";
import { ValidateStatus } from "@Components/shared/Form/ValidateStatus";
import {
    selectSecondsSinceLastJoinable,
    viewStateChanged,
} from "../../customerAppSlice";
import { ViewState } from "../../viewState";
import { PersistedMessageType } from "@Hubs/chat/dtos/PersistedMessageType";
import { ClientMessageType } from "@Hubs/chat/dtos/ClientMessageType";
import RequiredSymbol from "../RequiredSymbol/RequiredSymbol";
import FeedbackView from "../FeedbackView/FeedbackView";
import FeedbackResults from "../FeedbackResult/FeedbackResults";
import HelpfulFormItems from "../HelpfulFormItems/HelpfulFormItems";

interface FeedbackFields {
    wasHelpful?: boolean;
    positiveFeedback: string;
    negativeFeedback: string;
}

const ChatFeedback: React.FC = () => {
    const [form] = Form.useForm();
    const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);
    const [transcriptRequested, setTranscriptRequested] =
        useState<boolean>(false);
    const [userEmail, setUserEmail] = useState<string>("");
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    const [wereAnyMessagesSent, setWereAnyMessagesSent] =
        useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const chatApi = useAppSelector((state) => state.customerApp.chatApi);
    const messages = useAppSelector((state) => state.customerApp.messages);
    const liveNotHelpfulReasons = useAppSelector(
        (state) => state.customerApp.liveChatNotHelpfulReasons
    );
    const totalFeedbackScreenVisibleDurationInSeconds = useAppSelector(
        (state) =>
            state.customerApp.applicationSettings
                ?.totalFeedbackScreenVisibleDurationInSeconds ?? 3600
    );
    const secondsSinceLastJoinable = useAppSelector(
        selectSecondsSinceLastJoinable
    );

    const feedbackTimeout = useRef<NodeJS.Timeout>();

    useEffect(() => {
        feedbackTimeout.current = setTimeout(function () {
            dispatch(viewStateChanged(ViewState.TimedOut));
        }, 1000 *
            Math.max(
                totalFeedbackScreenVisibleDurationInSeconds -
                    secondsSinceLastJoinable,
                0
            ));

        return (): void => {
            if (feedbackTimeout.current) {
                clearTimeout(feedbackTimeout.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect((): void => {
        for (const message of messages) {
            if (
                message.messageType === PersistedMessageType.Text ||
                message.messageType === PersistedMessageType.BotText ||
                message.messageType === PersistedMessageType.Image ||
                message.messageType === ClientMessageType.SmartInclude
            ) {
                setWereAnyMessagesSent(true);
                break;
            }
        }
    }, [messages]);

    useEffect(() => {
        chatApi.updateSawFeedbackScreen();
    }, [chatApi]);

    const onTranscriptRequested = (): void => {
        if (ValidEmailAddressRegex.test(userEmail)) {
            // If a failure occurred, don't present it to the customer. There's really
            // nothing that they can do at this point anyways. We at least console.error'd it,
            // and if it's a server-side error we logged it to Sentry.
            chatApi
                .sendTranscript({
                    email: userEmail,
                })
                .finally(() => {
                    setTranscriptRequested(true);
                });
        } else {
            setIsEmailValid(false);
        }
    };

    const onFeedbackSubmitted = (formValues: FeedbackFields): void => {
        let feedbackComments = undefined;

        if (formValues.positiveFeedback) {
            feedbackComments = formValues.positiveFeedback;
        } else if (formValues.negativeFeedback) {
            feedbackComments = formValues.negativeFeedback;
        }

        setIsSubmitting(true);

        // If a failure occurred, don't present it to the customer. There's really
        // nothing that they can do at this point anyways. We at least console.error'd it,
        // and if it's a server-side error we logged it to Sentry.
        chatApi
            .addFeedback({
                wasHelpful: formValues.wasHelpful,
                comments: feedbackComments,
            })
            .finally(() => {
                setIsSubmitting(false);
                setFeedbackSubmitted(true);

                if (feedbackTimeout.current) {
                    clearTimeout(feedbackTimeout.current);
                }
            });
    };

    const emailHelp = isEmailValid ? "" : "Please enter a valid email address.";

    const emailValidateStatus = isEmailValid
        ? ValidateStatus.none
        : ValidateStatus.error;

    const thankYouMessage = "Thank you, we appreciate your feedback!";

    return (
        <FeedbackView>
            <Space
                className="live-chat-feedback"
                direction="vertical"
                align="center"
                size={0}
            >
                {feedbackSubmitted ? (
                    <Space className="live-chat-feedback__submitted">
                        <Title
                            level={1}
                            data-testid="live-chat-feedback-thank-you-text"
                        >
                            {thankYouMessage}
                        </Title>
                    </Space>
                ) : (
                    <Card
                        title={
                            <h1 data-testid="live-chat-feedback-card-header-text">
                                We would love to hear from you!
                            </h1>
                        }
                        className="live-chat-feedback__card"
                    >
                        <Form
                            form={form}
                            onFinish={(formValues): void =>
                                onFeedbackSubmitted(formValues)
                            }
                            className="live-chat-feedback__feedback-form"
                            layout="vertical"
                            requiredMark={false}
                            initialValues={{
                                positiveFeedback: "",
                                negativeFeedback: "",
                            }}
                        >
                            <Form.Item className="live-chat-feedback__feedback-form____required-legend">
                                <span className="extra">
                                    <RequiredSymbol />
                                    Indicates a Required Field
                                </span>
                            </Form.Item>
                            <HelpfulFormItems
                                form={form}
                                notHelpfulReasons={liveNotHelpfulReasons}
                            />
                            <Form.Item>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={isSubmitting}
                                    block
                                >
                                    Submit Feedback
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                )}
                {transcriptRequested ? (
                    <FeedbackResults
                        results={[
                            {
                                flavor: "success",
                                message: {
                                    title: "Success, the transcript is on its way to",
                                    message: userEmail,
                                    dataTestId:
                                        "live-chat-feedback-transcript-requested-success",
                                },
                            },
                        ]}
                    />
                ) : (
                    wereAnyMessagesSent && (
                        <Space
                            direction="vertical"
                            className="live-chat-feedback__transcript"
                        >
                            <Text
                                strong
                                className="live-chat-feedback__action-text"
                            >
                                Request a transcript of this chat.
                            </Text>
                            <Text>
                                <RequiredSymbol />
                                Email Address
                            </Text>
                            <Form
                                className="live-chat-feedback__transcript__form"
                                layout="inline"
                            >
                                <Form.Item
                                    className="live-chat-feedback__transcript__email"
                                    colon={false}
                                    validateStatus={emailValidateStatus}
                                    help={emailHelp}
                                >
                                    <Input
                                        type="email"
                                        autoComplete="email"
                                        placeholder="Enter email address"
                                        onChange={(e): void =>
                                            setUserEmail(e.target.value)
                                        }
                                    />
                                </Form.Item>
                                <Form.Item className="live-chat-feedback__transcript__submit">
                                    <Button
                                        type="primary"
                                        onClick={onTranscriptRequested}
                                        block
                                    >
                                        Request Transcript
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Space>
                    )
                )}
            </Space>
        </FeedbackView>
    );
};

export default ChatFeedback;
