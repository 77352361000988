import { LiveChatViewCustomActivityBubbleWrapper } from "../../LiveChatViewCustomActivityBubbleWrapper";
import { ItemFoodQualityIssueBaseProps } from "./FoodQualityIssueTypes";
import ItemFoodQualityIssue from "./ItemFoodQualityIssue";
import "./LiveChatItemFoodQualityIssue.less";

interface LiveChatItemFoodQualityIssueProps
    extends ItemFoodQualityIssueBaseProps {
    isCanceled: boolean;
}

const LiveChatItemFoodQualityIssue = ({
    chatId,
    orderInfo,
    messageId,
    resolutionsInfo,
    eligibleFoodQualityItemIssueNames,
    isCanceled,
}: LiveChatItemFoodQualityIssueProps): React.JSX.Element => {
    return (
        <LiveChatViewCustomActivityBubbleWrapper>
            <ItemFoodQualityIssue
                resolutionsInfo={resolutionsInfo}
                isReadOnly={true}
                isCancelClicked={isCanceled}
                chatId={chatId}
                orderInfo={orderInfo}
                messageId={messageId}
                eligibleFoodQualityItemIssueNames={
                    eligibleFoodQualityItemIssueNames
                }
                data-testid="live-chat-item-food-quality-issue"
            />
        </LiveChatViewCustomActivityBubbleWrapper>
    );
};

export default LiveChatItemFoodQualityIssue;
