enum CustomDialogCommandType {
    RefundReshipCancelCommand = "RefundReshipCancelItemResolution",
    RefundReshipSubmitCommand = "RefundReshipSubmitItemResolution",
    ReturnCancelCommand = "ReturnsCancelItemResolution",
    ReturnItemNotListedCommand = "ReturnsItemNotListedItemResolution",
    ReturnSubmitCommand = "ReturnsSubmitItemResolution",
    FoodQualityIssueSubmitCommand = "FoodQualityIssueSubmitItemResolution",
    FoodQualityIssueCancelCommand = "FoodQualityIssueCancelItemResolution",
}

export const CustomDialogCommand = {
    CustomDialogCommandType,
};
