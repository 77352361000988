import "./LiveChatItemRefundReship.less";
import React from "react";
import ItemRefundReship from "./ItemRefundReship";
import { LiveChatViewCustomActivityBubbleWrapper } from "../../LiveChatViewCustomActivityBubbleWrapper";

const LiveChatItemRefundReship = ({
    chatId,
    orderInfo,
    messageId,
    resolutionsInfo,
    isCanceled,
}: any): React.JSX.Element => {
    return (
        <LiveChatViewCustomActivityBubbleWrapper>
            <ItemRefundReship
                resolutionsInfo={resolutionsInfo}
                isReadOnly={true}
                chatId={chatId}
                orderInfo={orderInfo}
                messageId={messageId}
                isCancelClicked={isCanceled}
            />
        </LiveChatViewCustomActivityBubbleWrapper>
    );
};

export default LiveChatItemRefundReship;
