import useEventListener from "@Src/hooks/useEventListener";
import useInterval from "@Src/hooks/useInterval";
import { documentTitle } from "@Src/tenantConfiguration/SharedTenantConfiguration";
import { useEffect, useRef, useState } from "react";
import { windowVisibilityChanged } from "../customerAppSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { ViewState } from "../viewState";

const NewMessageWindowWrapper: React.FC = (props) => {
    const dispatch = useAppDispatch();

    // State hooks
    const viewState = useAppSelector((state) => state.customerApp.viewState);
    const isWindowVisible = useAppSelector(
        (state) => state.customerApp.isWindowVisible
    );
    const newMessagesReceivedWhileNotVisible = useAppSelector(
        (state) => state.customerApp.newMessagesReceivedWhileNotVisible
    );

    // Component hooks
    const documentRef = useRef<Document>(document);
    const [shouldFlashNewMessage, setShouldFlashNewMessage] =
        useState<boolean>(false);

    // Track the user navigating off the page to show notifications
    const onVisibilityChange = (): void => {
        const isVisible = !document.hidden;
        dispatch(windowVisibilityChanged(isVisible));
    };

    // Toggles the title between the tenant config and 'New Message!'
    const flashNewMessageTitle = (): void => {
        let title = documentTitle;
        if (
            newMessagesReceivedWhileNotVisible > 0 &&
            viewState === ViewState.TalkingToRep &&
            document.title === documentTitle
        ) {
            title =
                newMessagesReceivedWhileNotVisible === 1
                    ? "New Message!"
                    : `(${newMessagesReceivedWhileNotVisible}) New Messages!`;
        }

        document.title = title;
    };

    useEventListener("visibilitychange", onVisibilityChange, documentRef);
    useInterval(flashNewMessageTitle, shouldFlashNewMessage ? 1000 : null);

    // Watch the message count or visibility to determine if the title should flash
    useEffect(() => {
        if (isWindowVisible) {
            setShouldFlashNewMessage(false);
            document.title = documentTitle;
        }

        if (!isWindowVisible && newMessagesReceivedWhileNotVisible > 0) {
            setShouldFlashNewMessage(true);
        }
    }, [isWindowVisible, newMessagesReceivedWhileNotVisible]);

    return <>{props.children}</>;
};

export default NewMessageWindowWrapper;
