import "./SmartIncludeButton.less";
import { Button } from "antd";

interface SmartIncludeButtonProps {
    buttonText: string;
    isMineOrOperatorSeeingBot: boolean;
    href: string;
}

const SmartIncludeButton = ({
    buttonText,
    isMineOrOperatorSeeingBot,
    href,
}: SmartIncludeButtonProps): JSX.Element => {
    const buttonClassName =
        "smartIncludeBtn smartIncludeBtn__button-" +
        (isMineOrOperatorSeeingBot ? "outlined" : "primary");

    const buttonType = isMineOrOperatorSeeingBot ? undefined : "primary";

    return (
        <Button
            className={buttonClassName}
            type={buttonType}
            href={href}
            target="_blank"
            rel="noreferrer"
        >
            {buttonText}
        </Button>
    );
};

export default SmartIncludeButton;
