import "./TrackOrderTextBubble.less";
import "../BotConversationView.less";
import {
    Card,
    Typography,
    Space,
    Steps,
    Collapse,
    Button,
    List,
    Image,
    Divider,
} from "antd";
import dayjs from "dayjs";
import { TrackOrderData } from "./TrackOrderData";
import { TrackOrderDataGroupedByStatus } from "./TrackOrderDataGroupedByStatus";

const { Text, Link } = Typography;
const { Panel } = Collapse;

export interface TrackOrderTextBubbleProps {
    data: TrackOrderDataGroupedByStatus;
}

const statusStepIndexMapping: Record<string, number> = {
    Processing: 0,
    Shipped: 1,
    Delivered: 2,
};

export function getStatusStepIndex(status: string): number {
    return statusStepIndexMapping[status] ?? 0;
}

export function formatEstimatedDate(date: string): string {
    // Date parameter could be a date range.
    const dates = date.split(" - ");

    const formattedDates = dates
        .filter((date) => date)
        .map((date) => {
            return dayjs(date).format("ddd, MMM DD");
        });
    return formattedDates.join(" - ");
}

const getItemLabel = (data: TrackOrderData): string => {
    let itemLabel = `${data.Items.length} items.`;

    if (data.IsLimitingItemsShown) {
        itemLabel = `Only ${data.Items.length} items shown.`;
    } else {
        if (data.Items.length === 1) {
            itemLabel = "1 item.";
        }
    }

    return itemLabel;
};

const getTrackingNumberLabel = (data: TrackOrderData): string => {
    let trackingLabel = `${data.ShippingDetails?.TrackingNumbers?.length} tracking numbers.`;

    if (data.ShippingDetails?.IsLimitingTrackingNumbersShown) {
        trackingLabel = `Only ${data.ShippingDetails?.TrackingNumbers?.length} of ${data.ShippingDetails?.TotalTrackingNumbers} tracking numbers shown.`;
    } else {
        if (data.ShippingDetails?.TrackingNumbers?.length === 1) {
            trackingLabel = "1 tracking number.";
        }
    }

    return trackingLabel;
};

const getTrackingPanelHeading = (data: TrackOrderData): string => {
    let panelHeading = "Tracking";

    if (data.ShippingDetails?.TotalTrackingNumbers === 0) {
        panelHeading += data.ShippingDetails?.Carrier
            ? ` unavailable for ${data.ShippingDetails?.Carrier}`
            : " unavailable";
    }

    return panelHeading;
};

const renderStepTitle = (
    stepTitle: string,
    stepIndex: number,
    status: number
): JSX.Element => (
    <>
        <span aria-hidden={status < stepIndex}>{stepTitle}</span>
        {status < stepIndex && (
            <span className="visually-hidden">Not {stepTitle}</span>
        )}
    </>
);

const getShippedState = (data: TrackOrderData): string => {
    let shipState = data.ShippingDetails?.State ?? "";

    if (data.ShippingDetails?.StateAbbreviated === "Manufacturer") {
        shipState = "From Manufacturer";
    } else if (shipState === "" || shipState === "Unavailable") {
        shipState = "State Unavailable";
    }

    return shipState;
};

const getDeliveredCity = (data: TrackOrderData): string => {
    return data.DeliveryDetails?.City ? `${data.DeliveryDetails?.City}, ` : "";
};

const getShippedDate = (data: TrackOrderData): string => {
    return data.ShippingDetails?.ShippedDate
        ? dayjs(data.ShippingDetails?.ShippedDate).format("ddd, MMM DD")
        : "Date Unavailable";
};

const getDeliveredDate = (data: TrackOrderData): string => {
    return data.DeliveryDetails?.DeliveredDate
        ? dayjs(data.DeliveryDetails?.DeliveredDate).format("ddd, MMM DD")
        : "Date Unavailable";
};

const TrackOrderTextBubble = ({
    data,
}: TrackOrderTextBubbleProps): JSX.Element => {
    const trackingTabAnchor = "#orderTracking";

    return (
        <Card
            title={
                <aside aria-roledescription="Title">
                    <Space>
                        <Text strong>
                            Status for Order #
                            {
                                data.StatusGroups[0]?.ShipmentGroups[0]
                                    ?.OrderNumber
                            }
                        </Text>
                    </Space>
                </aside>
            }
            size="small"
            className="track-order-message"
        >
            <aside aria-roledescription="Shipments grouped by status type">
                {data.StatusGroups.map((statusGroup) => (
                    <>
                        <Collapse
                            className="track-order-message__collapse__collapse-status"
                            collapsible={"header"}
                            defaultActiveKey={
                                data.StatusGroups.length === 1 ? ["1"] : ["0"]
                            }
                        >
                            <Panel header={statusGroup.Status} key="1">
                                {statusGroup.ShipmentGroups.map(
                                    (shipmentGroup) => (
                                        <>
                                            <Space
                                                size={0}
                                                className="track-order-message__status"
                                            >
                                                <Text
                                                    strong
                                                    className="track-order-message__status__heading-text"
                                                >
                                                    {statusGroup.Status} -{" "}
                                                    {
                                                        shipmentGroup.TotalItemsInGroup
                                                    }{" "}
                                                    {shipmentGroup.TotalItemsInGroup >
                                                    1
                                                        ? "Items"
                                                        : "Item"}
                                                </Text>
                                                <aside aria-roledescription="Progress of item grouping">
                                                    <Steps
                                                        items={[
                                                            {
                                                                title: renderStepTitle(
                                                                    "Processing",
                                                                    0,
                                                                    getStatusStepIndex(
                                                                        statusGroup.Status
                                                                    )
                                                                ),
                                                                description:
                                                                    getStatusStepIndex(
                                                                        statusGroup.Status
                                                                    ) >= 0 ? (
                                                                        <Text>
                                                                            {dayjs(
                                                                                shipmentGroup.CreatedDate
                                                                            ).format(
                                                                                "ddd, MMM DD"
                                                                            )}
                                                                        </Text>
                                                                    ) : null,
                                                            },
                                                            {
                                                                title: renderStepTitle(
                                                                    "Shipped",
                                                                    1,
                                                                    getStatusStepIndex(
                                                                        statusGroup.Status
                                                                    )
                                                                ),
                                                                description:
                                                                    getStatusStepIndex(
                                                                        statusGroup.Status
                                                                    ) >= 1 ? (
                                                                        <Space
                                                                            className="track-order-message__status__info"
                                                                            size={
                                                                                0
                                                                            }
                                                                        >
                                                                            <Text>
                                                                                {getShippedDate(
                                                                                    shipmentGroup
                                                                                )}
                                                                            </Text>
                                                                            <Text
                                                                                style={{
                                                                                    color: "#595959",
                                                                                }}
                                                                            >
                                                                                {getShippedState(
                                                                                    shipmentGroup
                                                                                )}
                                                                            </Text>
                                                                        </Space>
                                                                    ) : null,
                                                            },
                                                            {
                                                                title: renderStepTitle(
                                                                    "Delivered",
                                                                    2,
                                                                    getStatusStepIndex(
                                                                        statusGroup.Status
                                                                    )
                                                                ),
                                                                description:
                                                                    getStatusStepIndex(
                                                                        statusGroup.Status
                                                                    ) === 2 ? (
                                                                        <Space
                                                                            className="track-order-message__status__info"
                                                                            size={
                                                                                0
                                                                            }
                                                                        >
                                                                            <Text>
                                                                                {getDeliveredDate(
                                                                                    shipmentGroup
                                                                                )}
                                                                            </Text>
                                                                            <Text
                                                                                style={{
                                                                                    color: "#595959",
                                                                                }}
                                                                            >
                                                                                {getDeliveredCity(
                                                                                    shipmentGroup
                                                                                )}
                                                                                {
                                                                                    shipmentGroup
                                                                                        .DeliveryDetails
                                                                                        ?.StateAbbreviated
                                                                                }
                                                                            </Text>
                                                                        </Space>
                                                                    ) : null,
                                                            },
                                                        ]}
                                                        progressDot
                                                        current={getStatusStepIndex(
                                                            statusGroup.Status
                                                        )}
                                                    />
                                                </aside>
                                            </Space>
                                            <aside aria-roledescription="Tracking information">
                                                {statusGroup.Status !==
                                                    "Processing" && (
                                                    <>
                                                        <Collapse
                                                            className="track-order-message__collapse collapse-child-tracking-numbers"
                                                            collapsible={
                                                                shipmentGroup
                                                                    .ShippingDetails
                                                                    ?.TotalTrackingNumbers ===
                                                                0
                                                                    ? "disabled"
                                                                    : "header"
                                                            }
                                                            defaultActiveKey={
                                                                shipmentGroup
                                                                    .ShippingDetails
                                                                    ?.TotalTrackingNumbers ===
                                                                0
                                                                    ? ["0"]
                                                                    : ["1"]
                                                            }
                                                        >
                                                            <Panel
                                                                header={getTrackingPanelHeading(
                                                                    shipmentGroup
                                                                )}
                                                                key="1"
                                                            >
                                                                {shipmentGroup
                                                                    .ShippingDetails
                                                                    ?.TrackingNumbers && (
                                                                    <>
                                                                        {shipmentGroup.ShippingDetails?.TrackingNumbers?.map(
                                                                            (
                                                                                trackingNumber,
                                                                                index
                                                                            ) => (
                                                                                <>
                                                                                    <div className="track-order-message__tracking">
                                                                                        <Button
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            type="link"
                                                                                            href={
                                                                                                trackingNumber.TrackingUrl
                                                                                            }
                                                                                            target="_blank"
                                                                                            className={
                                                                                                "track-order-message__tracking-button"
                                                                                            }
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    paddingTop:
                                                                                                        "5px",
                                                                                                }}
                                                                                            >
                                                                                                View
                                                                                                Tracking
                                                                                                -{" "}
                                                                                                {
                                                                                                    shipmentGroup
                                                                                                        .ShippingDetails
                                                                                                        ?.Carrier
                                                                                                }
                                                                                                {
                                                                                                    " #"
                                                                                                }
                                                                                                {
                                                                                                    trackingNumber.TrackingNumber
                                                                                                }
                                                                                            </span>
                                                                                        </Button>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        )}
                                                                        <Text
                                                                            style={{
                                                                                marginRight:
                                                                                    "4px",
                                                                                fontSize:
                                                                                    "12px",
                                                                            }}
                                                                        >
                                                                            {getTrackingNumberLabel(
                                                                                shipmentGroup
                                                                            )}
                                                                            <br />
                                                                        </Text>
                                                                        <Link
                                                                            href={`${shipmentGroup.OrderDetailsUrl}${trackingTabAnchor}`}
                                                                            target="_blank"
                                                                            underline
                                                                            strong
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize:
                                                                                        "12px",
                                                                                    fontWeight:
                                                                                        "bold",
                                                                                }}
                                                                            >
                                                                                View
                                                                                account
                                                                                to
                                                                                see
                                                                                all
                                                                                tracking
                                                                                numbers
                                                                                for
                                                                                the
                                                                                order.
                                                                            </span>
                                                                        </Link>
                                                                    </>
                                                                )}
                                                            </Panel>
                                                        </Collapse>
                                                    </>
                                                )}
                                            </aside>

                                            <aside aria-roledescription="Item grouping">
                                                <Collapse
                                                    defaultActiveKey={
                                                        data.StatusGroups
                                                            .length === 1 &&
                                                        shipmentGroup.TotalGroups ===
                                                            1
                                                            ? ["1"]
                                                            : ["0"]
                                                    }
                                                    className="track-order-message__collapse collapse-child-items"
                                                >
                                                    <Panel
                                                        header="Items"
                                                        key="1"
                                                    >
                                                        <List
                                                            dataSource={[
                                                                ...shipmentGroup.Items,
                                                            ]}
                                                            renderItem={(
                                                                item
                                                            ): JSX.Element => (
                                                                <List.Item
                                                                    className="track-order-message__list-item"
                                                                    key={`${item.ItemNumber}_${item.Price}`}
                                                                >
                                                                    <Space className="track-order-message__list-item-row">
                                                                        <Image
                                                                            src={
                                                                                item.ItemThumbnailUrl
                                                                            }
                                                                            preview={
                                                                                false
                                                                            }
                                                                            aria-hidden={
                                                                                true
                                                                            }
                                                                        />
                                                                        <Space className="track-order-message__description">
                                                                            <Link
                                                                                href={
                                                                                    item.StoreUrl
                                                                                }
                                                                                underline
                                                                                target="_blank"
                                                                                style={{
                                                                                    fontWeight:
                                                                                        "bold",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item.ItemDescription
                                                                                }
                                                                            </Link>
                                                                            <Space
                                                                                className="track-order-message__number"
                                                                                size={
                                                                                    0
                                                                                }
                                                                            >
                                                                                <Text
                                                                                    style={{
                                                                                        width: "150px",
                                                                                    }}
                                                                                >
                                                                                    #
                                                                                    {
                                                                                        item.ItemNumber
                                                                                    }
                                                                                </Text>
                                                                                <Text>{`${item.Quantity} Qty`}</Text>
                                                                                <Text>
                                                                                    {new Intl.NumberFormat(
                                                                                        "en-US",
                                                                                        {
                                                                                            style: "currency",
                                                                                            currency:
                                                                                                "USD",
                                                                                        }
                                                                                    ).format(
                                                                                        item.Price
                                                                                    )}
                                                                                </Text>
                                                                            </Space>
                                                                        </Space>
                                                                    </Space>
                                                                    {statusGroup.Status ==
                                                                        "Processing" &&
                                                                        (item.EstimatedShipDate ||
                                                                            item.EstimatedDeliveryDate) && (
                                                                            <Space className="track-order-message__list-item-row">
                                                                                <Space className="track-order-message__estimated-ship-delivery-dates">
                                                                                    {item.EstimatedShipDate && (
                                                                                        <>
                                                                                            <div>
                                                                                                <span className="track-order-message__estimated-ship-delivery-dates-label">
                                                                                                    Estimated
                                                                                                    Ship
                                                                                                    Date
                                                                                                </span>
                                                                                                <span className="track-order-message__estimated-ship-delivery-dates-value">
                                                                                                    {formatEstimatedDate(
                                                                                                        item.EstimatedShipDate
                                                                                                    )}
                                                                                                </span>
                                                                                            </div>
                                                                                            {item.EstimatedDeliveryDate && (
                                                                                                <Divider
                                                                                                    type="vertical"
                                                                                                    style={{
                                                                                                        margin: "0px 16px 0px",
                                                                                                        height: "37px",
                                                                                                        background:
                                                                                                            "#D9D9D9",
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                    {item.EstimatedDeliveryDate && (
                                                                                        <div>
                                                                                            <span className="track-order-message__estimated-ship-delivery-dates-label">
                                                                                                Estimated
                                                                                                Delivery
                                                                                                Date
                                                                                            </span>
                                                                                            <span className="track-order-message__estimated-ship-delivery-dates-value">
                                                                                                {formatEstimatedDate(
                                                                                                    item.EstimatedDeliveryDate
                                                                                                )}
                                                                                            </span>
                                                                                        </div>
                                                                                    )}
                                                                                </Space>
                                                                            </Space>
                                                                        )}
                                                                </List.Item>
                                                            )}
                                                        />
                                                        <Text
                                                            style={{
                                                                marginRight:
                                                                    "4px",
                                                                fontSize:
                                                                    "12px",
                                                            }}
                                                        >
                                                            {getItemLabel(
                                                                shipmentGroup
                                                            )}
                                                        </Text>
                                                        <Link
                                                            href={
                                                                shipmentGroup.OrderDetailsUrl
                                                            }
                                                            target="_blank"
                                                            underline
                                                            strong
                                                        >
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        "12px",
                                                                    fontWeight:
                                                                        "bold",
                                                                }}
                                                            >
                                                                View account to
                                                                see all items in
                                                                the order.
                                                            </span>
                                                        </Link>
                                                    </Panel>
                                                </Collapse>
                                            </aside>
                                        </>
                                    )
                                )}
                            </Panel>
                        </Collapse>
                    </>
                ))}
            </aside>
        </Card>
    );
};

export default TrackOrderTextBubble;
