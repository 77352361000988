import { TextOrLinkData } from "@Src/hubs/chat/dtos/TextOrLinkData";
import { Space, Typography } from "antd";
const { Text } = Typography;
import { getTextAndLinks } from "../TextBubbleHelper";
import { ClientRole } from "@Src/hubs/chat/dtos/ClientRole";

interface TextChatBubbleProps {
    text: string;
    textOrLinkArray?: Array<TextOrLinkData>;
    senderRole: ClientRole;
    smartOrderLink?: JSX.Element;
    smartZipCodeLookup?: JSX.Element;
}

const TextChatBubble = ({
    text,
    textOrLinkArray,
    senderRole,
    smartOrderLink,
    smartZipCodeLookup,
}: TextChatBubbleProps): JSX.Element => {
    return (
        <Space className="chat-bubble" direction="vertical" size={0}>
            <Text>
                {getTextAndLinks(senderRole, textOrLinkArray, text)}
                {smartOrderLink && <> {smartOrderLink}</>}
                {smartZipCodeLookup && <> {smartZipCodeLookup}</>}
            </Text>
        </Space>
    );
};

export default TextChatBubble;
