import { Environments } from "@Models/Environments";
import { TenantRoute } from "../models/TenantRoute";
import { tenantRoute } from "./SharedTenantConfiguration";

export const getEnvironmentPrefix = (): string => {
    if (
        window.location.hostname.indexOf("dev") >= 0 ||
        window.location.hostname.indexOf("loc") >= 0
    ) {
        return Environments.Development + ".";
    }

    if (window.location.hostname.indexOf("test") >= 0) {
        return Environments.Test + ".";
    }

    return Environments.Prod;
};

export interface CustomerTenantConfiguration {
    faqLink: string;
    helpEmail: string;
    applicationId: string;
    type: string;
    faqButtonText: string;
    showHelpEmailButton: boolean;
    showLegalDisclaimerOnWelcomePage: boolean;
}

function getTenantConfiguration(): CustomerTenantConfiguration {
    const makeWebstaurantStyleConfig = (
        customOverrides: Partial<CustomerTenantConfiguration>
    ): CustomerTenantConfiguration => {
        return {
            faqLink: `https://www.${getEnvironmentPrefix()}webstaurantstore.com/ask.html`,
            helpEmail: "help@webstaurantstore.com",
            type: "wss",
            faqButtonText: "Help Center",
            showHelpEmailButton: false,
            showLegalDisclaimerOnWelcomePage: true,
            ...customOverrides,
        } as CustomerTenantConfiguration;
    };
    switch (tenantRoute) {
        case TenantRoute.trs:
            return {
                faqLink: `https://www.${getEnvironmentPrefix()}therestaurantstore.com/faqs`,
                helpEmail: "help@therestaurantstore.com",
                applicationId: "TRS9d66D",
                type: "trs",
                faqButtonText: "View FAQs",
                showHelpEmailButton: true,
                showLegalDisclaimerOnWelcomePage: false,
            };
        case TenantRoute.cna:
            return {
                faqLink: `https://www.${getEnvironmentPrefix()}quicksupply.com/faq`,
                helpEmail: "accountsupport@clarkinc.biz",
                applicationId: "CN4ch4t1",
                type: "cna",
                faqButtonText: "View FAQs",
                showHelpEmailButton: true,
                showLegalDisclaimerOnWelcomePage: false,
            };
        case TenantRoute.clarkPro:
            return {
                faqLink: `https://pro.${getEnvironmentPrefix()}cfse.biz/faq`,
                helpEmail: "accountsupport@clarkinc.biz",
                applicationId: "CL4RKpr0",
                type: "clark-pro",
                faqButtonText: "View FAQs",
                showHelpEmailButton: true,
                showLegalDisclaimerOnWelcomePage: false,
            };
        case TenantRoute.risk:
            return makeWebstaurantStyleConfig({
                applicationId: "L64GHdeQ",
                helpEmail: "ecommercerisk@webstaurantstore.com",
                faqButtonText: "Help Center",
                showHelpEmailButton: true,
            });
        case TenantRoute.tax:
            return makeWebstaurantStyleConfig({
                applicationId: "T4Xch4t1",
                helpEmail: "taxexempt@webstaurantstore.com",
                faqButtonText: "Help Center",
                showHelpEmailButton: true,
            });
        case TenantRoute.rkw:
            return {
                applicationId: "RKW9Uh5z",
                type: "rkw",
                faqLink: "",
                helpEmail: "help@readykitchenwarranty.com",
                faqButtonText: "",
                showHelpEmailButton: true,
                showLegalDisclaimerOnWelcomePage: false,
            };
        default:
            return makeWebstaurantStyleConfig({ applicationId: "eWyEdSGs" });
    }
}

const tenantConfiguration = getTenantConfiguration();

export const faqLink = tenantConfiguration.faqLink;
export const helpEmail = tenantConfiguration.helpEmail;
export const applicationId = tenantConfiguration.applicationId;
export const type = tenantConfiguration.type;
export const faqButtonText = tenantConfiguration.faqButtonText;
export const showHelpEmailButton = tenantConfiguration.showHelpEmailButton;
export const showLegalDisclaimerOnWelcomePage =
    tenantConfiguration.showLegalDisclaimerOnWelcomePage;
