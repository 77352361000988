import { PersistedMessage } from "./PersistedMessage";
import { TextOrLinkData } from "./TextOrLinkData";
import { IsUrlRegex } from "@Utilities/UrlRegex";
import { IsApprovedDomainRegex } from "@Utilities/ApprovedDomainRegex";

interface IncomingMessage extends Omit<TextMessage, "timestamp"> {
    timestamp: string;
}

export class TextMessage extends PersistedMessage {
    text: string;
    textOrLinkArray: Array<TextOrLinkData>;

    protected getTextAndLinkArray = (
        message: string
    ): Array<TextOrLinkData> => {
        if (!message) {
            return [];
        }

        const messageTokens = message.split(/(\s+)/);

        return messageTokens.map((tokenText) => {
            const tokenData: TextOrLinkData = {
                isLink: IsUrlRegex.test(tokenText),
                isApprovedDomain: IsApprovedDomainRegex.test(tokenText),
                text: tokenText,
            };

            if (!tokenData.isLink && tokenData.isApprovedDomain) {
                tokenData.isLink = true;
                tokenData.text = "https://" + tokenData.text;
            }

            return tokenData;
        });
    };

    constructor(message: IncomingMessage) {
        super(message);
        this.text = message.text;
        this.textOrLinkArray = this.getTextAndLinkArray(message.text);
    }
}
