import { Space, Typography } from "antd";
import { TypingIndicator } from "../../../models/typingIndicator/TypingIndicator";
const { Text } = Typography;
import classNames from "classnames";

import "./TypingIndicatorBubble.less";

export interface TypingIndicatorProps {
    typingIndicators: TypingIndicator[];
}

const TypingIndicatorBubble = ({
    typingIndicators,
}: TypingIndicatorProps): JSX.Element => {
    const getTypingIndicatorAriaLabelValue = (): string => {
        return (typingIndicators.length ?? 0) > 0
            ? "The specialist is typing"
            : "";
    };

    return (
        <div
            className={classNames({
                message: true,
                content: true,
                "message-from-them": true,
                "last-consecutive": true,
            })}
        >
            <Space className="chat-bubble" direction="vertical" size={0}>
                <Text aria-label={getTypingIndicatorAriaLabelValue()}>
                    <span aria-hidden="true" className="circle"></span>
                    <span aria-hidden="true" className="circle"></span>
                    <span aria-hidden="true" className="circle"></span>
                </Text>
            </Space>
            <div className="envelope-info">
                {typingIndicators.length === 1 && (
                    <>
                        <span className="sender-name">
                            {typingIndicators[0].name}
                        </span>
                    </>
                )}
                {typingIndicators.length > 1 && (
                    <span className="sender-name">
                        Multiple people are typing...
                    </span>
                )}
            </div>
        </div>
    );
};

export default TypingIndicatorBubble;
