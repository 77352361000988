import { ResolutionsInfo } from "../FoodQualityIssue/FoodQualityIssueTypes";

interface ResolutionTypes {
    refund: boolean;
    storeCredit: boolean;
    originalPayment: boolean;
    reshipment: boolean;
}

interface ResolutionTypeState {
    resolutionTypes: ResolutionTypes;
    resolutionTypeSelected: boolean;
}

enum ResolutionTypeReducerActionEnum {
    RESET,
    SELECT_RESOLUTION_TYPE,
}

interface ResolutionTypePayload {
    resolutionType: string | undefined;
    isPaymentCreditValid: boolean;
    isStoreCreditValid: boolean;
    isReshipValid: boolean;
}

interface ResolutionTypeReducerAction {
    type: ResolutionTypeReducerActionEnum;
    payload?: ResolutionTypePayload;
}

enum ResolutionTypesEnum {
    REFUND = "Refund",
    STORE_CREDIT = "Store Credit",
    PAYMENT_CREDIT = "Payment Credit",
    RESHIPMENT = "Reshipment",
}

const initialResolutionTypeState: Readonly<ResolutionTypeState> = {
    resolutionTypes: {
        refund: false,
        storeCredit: false,
        originalPayment: false,
        reshipment: false,
    },
    resolutionTypeSelected: false,
};

// Use this reducer to manage the state of the resolution type radio buttons

function resolutionTypeRadioButtonReducer(
    state: ResolutionTypeState,
    action: ResolutionTypeReducerAction
): ResolutionTypeState {
    const {
        resolutionType,
        isPaymentCreditValid,
        isStoreCreditValid,
        isReshipValid,
    } = action.payload || {};

    const newResolutionTypes: ResolutionTypes = {
        ...initialResolutionTypeState.resolutionTypes,
    };

    const checkStoreCredit =
        resolutionType === ResolutionTypesEnum.STORE_CREDIT &&
        isStoreCreditValid;

    const checkPaymentCredit =
        resolutionType === ResolutionTypesEnum.PAYMENT_CREDIT &&
        isPaymentCreditValid;

    const checkReshipment =
        resolutionType === ResolutionTypesEnum.RESHIPMENT && isReshipValid;

    const checkRefund = resolutionType === ResolutionTypesEnum.REFUND;

    switch (action.type) {
        case ResolutionTypeReducerActionEnum.RESET:
            return { ...initialResolutionTypeState };
        case ResolutionTypeReducerActionEnum.SELECT_RESOLUTION_TYPE:
            if (checkStoreCredit) {
                newResolutionTypes.storeCredit = true;
                newResolutionTypes.refund = true;
            } else if (checkPaymentCredit) {
                newResolutionTypes.originalPayment = true;
                newResolutionTypes.refund = true;
            } else if (checkReshipment) {
                newResolutionTypes.reshipment = true;
            } else if (checkRefund) {
                newResolutionTypes.refund = true;
            }

            return {
                resolutionTypes: { ...newResolutionTypes },
                resolutionTypeSelected: Object.values(newResolutionTypes).some(
                    (val) => val
                ),
            };
        default:
            return state;
    }
}

function configureInitialResolutionTypeState(
    resolutionsInfo: ResolutionsInfo
): ResolutionTypeState {
    if (resolutionsInfo.resolutionItems.length > 0) {
        const newInitialState: ResolutionTypeState = {
            ...initialResolutionTypeState,
            resolutionTypes: { ...initialResolutionTypeState.resolutionTypes },
        };

        newInitialState.resolutionTypeSelected = true;

        switch (resolutionsInfo.resolutionTypeName) {
            case ResolutionTypesEnum.RESHIPMENT:
                newInitialState.resolutionTypes.reshipment = true;
                break;
            case ResolutionTypesEnum.STORE_CREDIT:
                newInitialState.resolutionTypes.storeCredit = true;
                newInitialState.resolutionTypes.refund = true;
                break;
            case ResolutionTypesEnum.PAYMENT_CREDIT:
                newInitialState.resolutionTypes.originalPayment = true;
                newInitialState.resolutionTypes.refund = true;
                break;
            default:
                newInitialState.resolutionTypeSelected = false;
                break;
        }

        return newInitialState;
    }

    return initialResolutionTypeState;
}

export {
    resolutionTypeRadioButtonReducer,
    ResolutionTypeReducerActionEnum,
    initialResolutionTypeState,
    ResolutionTypesEnum,
    configureInitialResolutionTypeState,
};

export type { ResolutionTypeState };
