import React from "react";
import { Button, Popconfirm, Space, Typography } from "antd";
import "./ClearItemsButtonWithPopconfirm.less";
const { Text } = Typography;

const ClearItemsButtonWithPopconfirm = (props: any): React.JSX.Element => {
    const {
        isSubmitting,
        clearItemsButtonPopconfirmOnConfirmHandler,
        clearItemsButtonOnClickHandler,
        itemList,
        isReadOnly,
        "data-testid": dataTestId,
    } = props;

    return (
        <Popconfirm
            title={
                <Space
                    size={0}
                    className="clear-items-popconfirm"
                    data-testid={`${dataTestId}-popconfirm-title-container`}
                >
                    <Text data-testid={`${dataTestId}-popconfirm-title-text-1`}>
                        Are you sure you want to start over?
                    </Text>
                    <Text data-testid={`${dataTestId}-popconfirm-title-text-2`}>
                        Items selected will be cleared.
                    </Text>
                </Space>
            }
            okText="Yes, Clear Items"
            cancelText="No, Keep Items"
            onConfirm={clearItemsButtonPopconfirmOnConfirmHandler}
            disabled={
                isSubmitting ||
                !itemList.some((item: any) => item.isChecked === true)
            }
            data-testid={`${dataTestId}-popconfirm`}
        >
            {!isReadOnly && (
                <Button
                    onClick={clearItemsButtonOnClickHandler}
                    disabled={isSubmitting}
                    data-testid={`${dataTestId}-button`}
                >
                    Clear Selected Items
                </Button>
            )}
        </Popconfirm>
    );
};

export default ClearItemsButtonWithPopconfirm;
