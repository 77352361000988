import "./TaxFormBubbleWrapper.less";

import { Space } from "antd";
import React from "react";

export const TaxFormBubbleWrapper: React.FC = (props) => {
    return (
        <Space
            className="chat-bubble tax-form-bubble-wrapper"
            direction="vertical"
            size={0}
        >
            {props.children}
        </Space>
    );
};
