import { WelcomeIssue } from "@Models/chat/WelcomeIssue";
import { QnAKey } from "@Models/chatBot/QnAKey";

export const Issues: Array<{ value: WelcomeIssue; qnaKey: QnAKey }> = [
    {
        value: WelcomeIssue.Order,
        qnaKey: QnAKey.OrderRelated,
    },
    {
        value: WelcomeIssue.Item,
        qnaKey: QnAKey.ItemRelated,
    },
    {
        value: WelcomeIssue.Account,
        qnaKey: QnAKey.AccountRelated,
    },
    {
        value: WelcomeIssue.PaymentTax,
        qnaKey: QnAKey.PaymentTax,
    },
];

export const RkwIssues: Array<{ value: WelcomeIssue }> = [
    {
        value: WelcomeIssue.SubmitAClaim,
    },
    {
        value: WelcomeIssue.UpdateOnClaimStatus,
    },
    {
        value: WelcomeIssue.VerifyWarrantyStatus,
    },
    {
        value: WelcomeIssue.PartsProductRelated,
    },
];

const getWssIssuesInOrder = (): Array<{
    value: WelcomeIssue;
    qnaKey: QnAKey;
}> => {
    // Expected Order ->
    // Order Related, Item Related, WebstaurantPlus, Returns, Account Related, Payment/Tax, General Questions
    const issues = [...Issues];
    issues.splice(2, 0, {
        value: WelcomeIssue.WebstaurantPlus,
        qnaKey: QnAKey.WebstaurantPlus,
    });
    issues.splice(3, 0, {
        value: WelcomeIssue.Returns,
        qnaKey: QnAKey.Returns,
    });
    issues.push({
        value: WelcomeIssue.General,
        qnaKey: QnAKey.General,
    });
    return issues;
};

export const WssIssues: Array<{ value: WelcomeIssue; qnaKey: QnAKey }> =
    getWssIssuesInOrder();
