enum Keys {
    SkipConfirmation = "skipConfirmation",
}

enum Values {
    SkipConfirmationTrue = "true",
}

export const MessageBackValueProperties = {
    Keys,
    Values,
};
