/*
  Hook to easily add an interval that can be started and stopped dynamically with cleanup.
  Pass in null as the delay to stop the interval.
  Source: https://usehooks-ts.com/react-hook/use-interval
*/
import { useEffect, useRef } from "react";

function useInterval(callback: () => void, delay: number | null): void {
    const savedCallback = useRef(callback);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        // Note: 0 is a valid value for delay.
        if (!delay && delay !== 0) {
            return;
        }

        const id = setInterval(() => savedCallback.current(), delay);

        return (): void => clearInterval(id);
    }, [delay]);
}

export default useInterval;
