import React from "react";
import { Button, Popconfirm, Space, Typography } from "antd";
import "./CancelButtonWithPopconfirm.less";
const { Text } = Typography;

const CancelButtonWithPopconfirm = (props: any): React.JSX.Element => {
    const {
        isSubmitting,
        cancelButtonPopconfirmOnConfirmHandler,
        "data-testid": dataTestId,
        styleMargin = "12px 0",
    } = props;

    return (
        <Popconfirm
            title={
                <Space
                    size={0}
                    data-testid={`${dataTestId}-popconfirm-title-container`}
                >
                    <Text data-testid={`${dataTestId}-popconfirm-title-text`}>
                        Are you sure you want to cancel this resolution request?
                    </Text>
                </Space>
            }
            okText="Yes, Cancel"
            cancelText="No, Keep Items"
            onConfirm={cancelButtonPopconfirmOnConfirmHandler}
            disabled={isSubmitting}
            data-testid={`${dataTestId}-popconfirm`}
        >
            <Button
                style={{ margin: styleMargin }}
                disabled={isSubmitting}
                data-testid={`${dataTestId}-button`}
            >
                Cancel
            </Button>
        </Popconfirm>
    );
};

export default CancelButtonWithPopconfirm;
