/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionType } from "@Models/chatBot/ActionType";
import { ActivityName } from "@Models/chatBot/ActivityName";
import { TextMessage } from "@Src/hubs/chat/dtos/TextMessage";

// This code is intended for use during WIP development of new UI that will be
// displayed from Pepper while in a bot chat. Use triggerMessages to add a trigger
// phrase and the corresponding attachment data in the shape that you would expect
// ChatBot to send down in the to-be-developed custom attachment activity.
interface Attachment {
    ContentType: string;
    Content?: {
        // Add Test Content Types Here
    };
}

// Add the trigger messages here
// Refer to backstage documentation for examples
export const triggerMessages: Record<string, Attachment> = {};

let webchatDispatch: any;

export function setTriggerCheckerDispatch(dispatch: any): void {
    webchatDispatch = dispatch;
}

export function checkTriggerMessage(textMessage: TextMessage): boolean {
    if (textMessage?.text && triggerMessages[textMessage.text]) {
        webchatDispatch({
            type: ActionType.WebChat.SendEvent,
            payload: {
                name: ActivityName.SendAttachmentMessage,
                value: triggerMessages[textMessage.text],
            },
        });

        return true;
    }

    return false;
}
