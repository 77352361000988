import "./ImageChatBubble.less";
import { Space, Image, Button, Popconfirm, Typography, Alert } from "antd";
const { Text } = Typography;
import {
    DownloadFile,
    LoadBlobUrl,
    NavigateToFile,
} from "@Utilities/AssetDownloader";
import {
    isOperator,
    tenantRoute,
} from "@Src/tenantConfiguration/SharedTenantConfiguration";
import { MessageApiShared } from "@Api/MessageApiShared";
import { MessageToDelete } from "@Src/api/dtos/message/MessageToDelete";
import genericFileImage from "@Images/generic-file-placeholder.jpg";
import { fileUploadDefinitions } from "@Utilities/FileUploadDefinitions";
import { useEffect, useMemo, useRef, useState } from "react";

interface ImageChatBubbleProps {
    imageUrl: string;
    fileName?: string;
    chatId: string;
    messageId: string;
    messageApi: MessageApiShared;
    baseImageRoute: string;
    isContentInappropriate?: boolean;
    onImageLoaded?: () => void;
}

const ImageChatBubble = ({
    imageUrl,
    fileName,
    chatId,
    messageId,
    messageApi,
    baseImageRoute,
    isContentInappropriate,
    onImageLoaded,
}: ImageChatBubbleProps): JSX.Element => {
    const [loadedImageBlobUrl, setImageBlobUrl] = useState<string>();
    const [isImageCensored, setIsImageCensored] = useState<boolean>(
        isContentInappropriate ?? false
    );

    // Ensures that if this component is re-used, or the parent is re-rendered, that we
    // only notify the parent once when a particular image is loaded
    const urlNotified = useRef<string>("");

    // Determine if the file is in valid image types
    const validImageType =
        fileUploadDefinitions.allowedFileExtensionsForCustomers.some((x) => {
            return fileName?.endsWith(x);
        });

    const assetImageUrl = `/api/${tenantRoute}/${baseImageRoute}/${imageUrl}`;

    const previewSrcUrl = useMemo(
        (): string => (validImageType ? assetImageUrl : genericFileImage),
        [assetImageUrl, validImageType]
    );

    const downloadButtonHandler = (): void => {
        if (imageUrl && fileName) {
            if (validImageType && loadedImageBlobUrl) {
                NavigateToFile(loadedImageBlobUrl, fileName);
            } else {
                DownloadFile(assetImageUrl, fileName);
            }
        }
    };

    const deleteButtonHandler = async (): Promise<void> => {
        await messageApi.deleteMessage({
            chatId: chatId,
            messageId: messageId,
            fileName: fileName,
        } as MessageToDelete);
    };

    // Trims the GUID from the beginning of the file name when its uploaded
    const actualFileName = fileName?.substring(fileName.indexOf("_") + 1);

    const viewButtonHandler = (imageUrl: string): void => {
        const newWindow = window.open(imageUrl, actualFileName);

        if (newWindow) {
            newWindow.onload = function (): void {
                newWindow.document.title = actualFileName ?? "imageUrl";
                const img = newWindow.document.querySelector("img");
                if (img) {
                    img.setAttribute("alt", actualFileName ?? "");
                }
            };
        }
    };

    const unblurButtonHandler = (): void => {
        setIsImageCensored(false);
    };

    useEffect(() => {
        LoadBlobUrl(previewSrcUrl).then((fileBlobUrl) => {
            setImageBlobUrl(fileBlobUrl);
        });
    }, [previewSrcUrl, onImageLoaded]);

    useEffect(() => {
        if (
            loadedImageBlobUrl &&
            onImageLoaded &&
            urlNotified.current != assetImageUrl
        ) {
            urlNotified.current = assetImageUrl;
            onImageLoaded();
        }
    }, [assetImageUrl, loadedImageBlobUrl, onImageLoaded]);

    const imageToRender = (): JSX.Element => {
        let height = undefined;
        let preview = true;
        const shouldCensorImage = isImageCensored && isOperator;

        if (!validImageType) {
            height = 100;
            preview = false;
        }

        if (shouldCensorImage) {
            preview = false;
        }

        return (
            <>
                <Image
                    src={loadedImageBlobUrl}
                    height={height}
                    preview={preview}
                    alt={actualFileName}
                />
                {!validImageType && (
                    <Text className="generic-file-name">{actualFileName}</Text>
                )}
                {shouldCensorImage && (
                    <Alert
                        showIcon
                        type="warning"
                        message="Potential NSFW Image"
                        className="chat-bubble--censored__alert"
                    />
                )}
            </>
        );
    };

    const wrapperCssClass = (): string => {
        let cssClass = "chat-bubble chat-bubble--extra-padding";
        if (!validImageType) {
            cssClass += " chat-bubble-generic-file";
        }

        if (isImageCensored && isOperator) {
            cssClass += " chat-bubble--censored";
        }

        return cssClass;
    };

    const viewButtonElement = (): JSX.Element => {
        if (isOperator && isImageCensored) {
            return (
                <Button
                    className="image-chat-bubble__view__button-outlined"
                    onClick={(): void => unblurButtonHandler()}
                >
                    Unblur
                </Button>
            );
        } else {
            return (
                <Button
                    className="image-chat-bubble__view__button-outlined"
                    onClick={(): void => viewButtonHandler(assetImageUrl)}
                >
                    View
                </Button>
            );
        }
    };

    return (
        <Space className={wrapperCssClass()} direction="vertical" size={0}>
            {imageToRender()}
            <Space direction="horizontal" className="chat-bubble__button-group">
                {viewButtonElement()}
                <Button
                    className="image-chat-bubble__download__button-outlined"
                    onClick={downloadButtonHandler}
                    disabled={isImageCensored}
                >
                    Download
                </Button>
                {isOperator ? (
                    <Popconfirm
                        title={"Are you sure you want to delete this file?"}
                        onConfirm={deleteButtonHandler}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button className="image-chat-bubble__delete__button-danger-outlined">
                            Delete
                        </Button>
                    </Popconfirm>
                ) : null}
            </Space>
        </Space>
    );
};

export default ImageChatBubble;
