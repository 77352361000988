export const FormItemLabel = (
    labelContent: string,
    htmlFor: string
): React.JSX.Element => {
    return (
        <label htmlFor={htmlFor} className={`${htmlFor}__form-item-label`}>
            {labelContent}
        </label>
    );
};
