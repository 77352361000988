import { Space, Typography } from "antd";

const { Text } = Typography;

interface FailedChatBubbleProps {
    text: string;
}

const FailedChatBubble = ({ text }: FailedChatBubbleProps): JSX.Element => {
    return (
        <Space className="chat-bubble" direction="vertical" size={0}>
            <Text>{text}</Text>
        </Space>
    );
};

export default FailedChatBubble;
