import { ClientRole } from "./ClientRole";
import { ClientMessageType } from "./ClientMessageType";
import { PersistedMessageType } from "./PersistedMessageType";

export class BaseMessage {
    id: string;
    chatId: string;
    timestamp: Date;
    senderName: string;
    senderUserId: string;
    clientRole: ClientRole;
    messageType: ClientMessageType | PersistedMessageType;

    constructor(
        id: string,
        chatId: string,
        timestamp: Date | string,
        senderName: string,
        senderUserId: string,
        clientRole: ClientRole,
        messageType: ClientMessageType | PersistedMessageType
    ) {
        this.id = id;
        this.chatId = chatId;
        this.timestamp = new Date(timestamp);
        this.senderName = senderName;
        this.senderUserId = senderUserId;
        this.clientRole = clientRole;
        this.messageType = messageType;
    }
}
