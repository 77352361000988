import "./LiveChatViewCustomActivityBubbleWrapper.less";
import { Space } from "antd";
import React from "react";

export const LiveChatViewCustomActivityBubbleWrapper: React.FC = (props) => {
    return (
        <Space
            className="chat-bubble live-chat-view-custom-activity-bubble-wrapper"
            direction="vertical"
            size={0}
        >
            {props.children}
        </Space>
    );
};
