import { ClientRole } from "@Hubs/chat/dtos/ClientRole";
import { ClientMessage } from "@Hubs/chat/dtos/ClientMessage";
import { ClientMessageType } from "@Hubs/chat/dtos/ClientMessageType";

export class FailedTextMessage extends ClientMessage {
    text: string;

    constructor(
        text: string,
        chatId: string,
        senderName: string,
        senderUserId: string,
        clientRole: ClientRole
    ) {
        super(
            chatId,
            senderName,
            senderUserId,
            clientRole,
            ClientMessageType.FailedText
        );

        this.text = text;
    }
}
