import { ScrollValues } from "@Models/chat/ScrollValues";

export function isScrolledToBottom(
    scrollRef: HTMLDivElement | ScrollValues,
    acceptableScrollDistanceFromBottom: number
): boolean {
    return (
        Math.abs(
            scrollRef.scrollHeight -
                scrollRef.clientHeight -
                scrollRef.scrollTop
        ) < acceptableScrollDistanceFromBottom
    );
}
