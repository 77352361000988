export async function LoadBlobUrl(url: string): Promise<string> {
    const blob = await fetch(url).then((response) => response.blob());
    return window.URL.createObjectURL(blob);
}

export async function DownloadFile(
    imageUrl: string,
    fileName: string
): Promise<void> {
    // Create blob link to download
    const blobUrl = await LoadBlobUrl(imageUrl);

    NavigateToFile(blobUrl, fileName);
}

export function NavigateToFile(blobUrl: string, fileName: string): void {
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
}
