import { TextMessage } from "./TextMessage";

interface IncomingMessage extends Omit<SmartIncludeMessage, "timestamp"> {
    timestamp: string;
}

export class SmartIncludeMessage extends TextMessage {
    pageUrl: string;
    pageImage: string;
    pageTitle: string;
    pageDescription: string;

    constructor(message: IncomingMessage) {
        super(message);
        this.pageUrl = message.pageUrl;
        this.pageImage = message.pageImage;
        this.pageTitle = message.pageTitle;
        this.pageDescription = message.pageDescription;
    }
}
