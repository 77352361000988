import { useEffect } from "react";
import { useAppSelector } from "../hooks";
import { FailureType } from "@Models/customer/FailureType";

interface WebChatErrorViewSwitcherProps {
    fallbackToRep: (
        chatId: string | undefined,
        failureType: FailureType
    ) => void;
}

const WebChatErrorViewSwitcher = ({
    fallbackToRep,
}: WebChatErrorViewSwitcherProps): JSX.Element => {
    const chat = useAppSelector((state) => state.customerApp.chat);
    useEffect(() => {
        fallbackToRep(chat?.id, FailureType.ReactWebChatComponent);
    }, [fallbackToRep, chat?.id]);
    return <></>;
};
export default WebChatErrorViewSwitcher;
