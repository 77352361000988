import { PersistedMessage } from "./PersistedMessage";

interface IncomingMessage extends Omit<CustomActivityMessage, "timestamp"> {
    timestamp: string;
}

export class CustomActivityMessage extends PersistedMessage {
    jsonData: string;

    constructor(message: IncomingMessage) {
        super(message);
        this.jsonData = message.jsonData;
    }
}
