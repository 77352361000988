import { Typography } from "antd";
const { Link } = Typography;
import { PersistedMessage } from "@Hubs/chat/dtos/PersistedMessage";
import { PersistedMessageType } from "@Src/hubs/chat/dtos/PersistedMessageType";

interface ResendButtonProps {
    message: PersistedMessage;
    sendMessageAsUser(message: PersistedMessage): Promise<string>;
    setIsResending: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResendButton = ({
    message,
    sendMessageAsUser,
    setIsResending,
}: ResendButtonProps): JSX.Element => (
    <Link
        className="resend-button"
        style={{ fontSize: "1.1em" }}
        underline
        onClick={(): void => {
            setIsResending(true);
            message = { ...message, messageType: PersistedMessageType.Text };
            sendMessageAsUser(message).finally(() => setIsResending(false));
        }}
    >
        Click to try again.
    </Link>
);

export default ResendButton;
