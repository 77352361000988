enum DirectLine {
    ConnectFulfilled = "DIRECT_LINE/CONNECT_FULFILLED",
    IncomingActivity = "DIRECT_LINE/INCOMING_ACTIVITY",
    ConnectionRejected = "DIRECT_LINE/CONNECT_REJECTED",
}

enum WebChat {
    SendEvent = "WEB_CHAT/SEND_EVENT",
    SendMessage = "WEB_CHAT/SEND_MESSAGE",
    SendMessageBack = "WEB_CHAT/SEND_MESSAGE_BACK",
}

export const ActionType = {
    DirectLine,
    WebChat,
};
