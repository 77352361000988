import { PersistedMessage } from "./PersistedMessage";

interface IncomingMessage extends Omit<PersistedMessage, "timestamp"> {
    timestamp: string;
    avatar: string;
    shouldHide: boolean;
}

export class UserJoinedChatMessage extends PersistedMessage {
    avatar: string | null;
    shouldHide: boolean;
    constructor(message: IncomingMessage) {
        super(message);
        this.avatar = message.avatar;
        this.shouldHide = message.shouldHide;
    }
}
