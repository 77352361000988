import { SwitchboardConfig } from "@Models/SwitchboardConfig";
import { AxiosInstance, AxiosResponse } from "axios";
import { SwitchboardApiBase } from "./SwitchboardApiBase";
import { PreviousChat } from "@Models/chat/PreviousChat";
import { FeedbackReasonsModel } from "./dtos/feedbackReasons/FeedbackReasonsModel";

export class ChatApiShared extends SwitchboardApiBase {
    constructor(config: SwitchboardConfig, axios: AxiosInstance) {
        super(config, axios);
    }

    public async getPreviousChat(
        dboChatId: number,
        userIndex: number
    ): Promise<PreviousChat> {
        const response = await this.axios
            .get(
                this.buildSwitchboardApiUrl(
                    `Chat/previousChat/${dboChatId}?userIndex=${userIndex}`
                )
            )
            .then((result) => result.data);
        if (response) {
            const previousChat = response as PreviousChat;
            previousChat.startDate = new Date(previousChat.startDate);
            if (previousChat?.messages) {
                previousChat.messages.forEach((message) => {
                    message.timestamp = new Date(
                        message.timestamp as unknown as string
                    );
                });
            }
        }
        return response;
    }

    public getFeedbackReasons(): Promise<FeedbackReasonsModel> {
        return this.axios
            .get(this.buildSwitchboardApiUrl("Chat/feedbackReasons"))
            .then(
                (response: AxiosResponse<FeedbackReasonsModel>) => response.data
            );
    }
}
