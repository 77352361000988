import { TextOrLinkData } from "@Src/hubs/chat/dtos/TextOrLinkData";
import Link from "antd/lib/typography/Link";
import { ClientRole } from "@Src/hubs/chat/dtos/ClientRole";

export const getTextAndLinks = (
    senderRole: ClientRole,
    textOrLinkArray?: Array<TextOrLinkData>,
    textDefault = ""
): React.ReactNode[] => {
    if (!textOrLinkArray || textOrLinkArray.length == 0) {
        return [textDefault];
    }

    return textOrLinkArray.map((token) => {
        if (
            token.isLink &&
            (senderRole === ClientRole.Operator || token.isApprovedDomain)
        ) {
            return (
                <Link
                    href={token.text}
                    target="_blank"
                    underline
                    key={token.text}
                    data-testid="chat-bubble-link"
                >
                    {token.text}
                </Link>
            );
        } else {
            return token.text;
        }
    });
};
