import "./FeedbackResultElement.less";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Space, Typography } from "antd";
const { Text } = Typography;
import { ResultFlavor } from "./ResultFlavor";
import { ResultMessage } from "./ResultMessage";

export interface FeedbackResultElementProps {
    flavor: ResultFlavor;
    message: ResultMessage;
}

const FeedbackResultElement: React.FC<FeedbackResultElementProps> = ({
    flavor,
    message,
}) => {
    return (
        <Space
            className={`feedback-result ${flavor}`}
            aria-live="polite"
            data-testId={message.dataTestId}
        >
            {flavor == "success" ? (
                <CheckCircleFilled className="feedback-result__icon-check positive" />
            ) : (
                <CloseCircleFilled className="feedback-result__icon-x" />
            )}
            <Space direction="vertical" size={0}>
                <Text strong>{message.title}</Text>
                <Text>{message.message}</Text>
            </Space>
        </Space>
    );
};

export default FeedbackResultElement;
