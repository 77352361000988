import "./BotFeedbackDecorator.less";
import React, { useEffect, useState } from "react";
import { LikeOutlined, DislikeOutlined } from "@ant-design/icons";
import { useAppSelector } from "../hooks";
import { AddMessageFeedbackRequest } from "@Src/hubs/chat/dtos/AddMessageFeedbackRequest";
import ChatIcon from "@Images/chat-specialist.svg";

interface BotFeedbackDecoratorProps {
    messageId: string;
    qnaNumber: number;
    knowledgeBaseId: number;
    onChatWithSpecialistClick: () => void;
}

const BotFeedbackDecorator: React.FC<BotFeedbackDecoratorProps> = (props) => {
    const chatBotApi = useAppSelector((state) => state.customerApp.chatBotApi);
    const chat = useAppSelector((state) => state.customerApp.chat);
    const [isLiked, setIsLiked] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const { messageId, qnaNumber, knowledgeBaseId } = props;

    const operatorsOnline = useAppSelector(
        (state) => state.customerApp.operatorsOnline
    );

    useEffect(() => {
        if (isInitialLoad && chat?.dboChatId) {
            chatBotApi
                .getMessageFeedback({
                    chatId: chat?.dboChatId,
                    messageId: messageId,
                })
                .then((messageFeedback) => {
                    if (messageFeedback != null) {
                        setIsLiked(messageFeedback);
                        setIsDisliked(!messageFeedback);
                    }
                })
                .finally(() => {
                    setIsInitialLoad(false);
                });
        }
    }, [chatBotApi, isInitialLoad, messageId, chat?.dboChatId]);

    // Preload the chat with specialist image so there is no delay when not helpful is clicked
    useEffect(() => {
        const img = new Image();
        img.src = ChatIcon;
    }, []);

    const addMessageLikeReaction = (): void => {
        const newValue = !isLiked;
        setIsLiked(newValue);

        if (newValue && chat?.dboChatId) {
            setIsDisliked(false);

            const addFeedbackRequest: AddMessageFeedbackRequest = {
                messageId: messageId,
                chatId: chat.dboChatId,
                qnaNumber: qnaNumber,
                knowledgeBaseId: knowledgeBaseId,
                wasHelpful: true,
                dateRated: new Date(),
            };
            chatBotApi.addMessageFeedback(addFeedbackRequest);
        } else if (chat?.dboChatId) {
            chatBotApi.removeMessageFeedback({
                chatId: chat?.dboChatId,
                messageId: messageId,
            });
        }
    };

    const addMessageDislikeReaction = (): void => {
        const newValue = !isDisliked;
        setIsDisliked(newValue);

        if (newValue && chat?.dboChatId) {
            setIsLiked(false);

            const addFeedbackRequest: AddMessageFeedbackRequest = {
                messageId: messageId,
                chatId: chat.dboChatId,
                qnaNumber: qnaNumber,
                knowledgeBaseId: knowledgeBaseId,
                wasHelpful: false,
                dateRated: new Date(),
            };
            chatBotApi.addMessageFeedback(addFeedbackRequest);
        } else if (chat?.dboChatId) {
            chatBotApi.removeMessageFeedback({
                chatId: chat?.dboChatId,
                messageId: messageId,
            });
        }
    };

    return (
        <div className="bot-feedback-decorator">
            <div className="bot-feedback-decorator__content">
                {props.children}
            </div>
            <div className="bot-feedback-decorator__grouping">
                <div className="bot-feedback-decorator__question">
                    Was this helpful?
                </div>
                <ul className="bot-feedback-decorator__buttons">
                    <li>
                        <button
                            className={`bot-feedback-decorator__buttons__like-button${
                                isLiked ? "--liked" : ""
                            }`}
                            onClick={addMessageLikeReaction}
                        >
                            <LikeOutlined
                                className={`bot-feedback-decorator__buttons__like-button__icon${
                                    isLiked ? "--liked" : ""
                                }`}
                            />
                            Helpful
                        </button>
                    </li>
                    <li>
                        <button
                            className={`bot-feedback-decorator__buttons__dislike-button${
                                isDisliked ? "--disliked" : ""
                            }`}
                            onClick={addMessageDislikeReaction}
                        >
                            <DislikeOutlined
                                className={`bot-feedback-decorator__buttons__dislike-button__icon${
                                    isDisliked ? "--disliked" : ""
                                }`}
                            />
                            Not Helpful
                        </button>
                    </li>
                </ul>
                {operatorsOnline && isDisliked && (
                    <button
                        className="bot-feedback-decorator__buttons__chat-button"
                        onClick={props.onChatWithSpecialistClick}
                    >
                        <img
                            src={ChatIcon}
                            alt="chat-with-specialist"
                            className="bot-feedback-decorator__buttons__chat-button__icon"
                        />
                        Chat with Specialist
                    </button>
                )}
            </div>
        </div>
    );
};

export default BotFeedbackDecorator;
