import "./FeedbackView.less";
import React, { useEffect, useState } from "react";
import { Space, Collapse } from "antd";
const { Panel } = Collapse;
import { useAppSelector } from "../../hooks";
import ConversationView from "@Components/shared/ConversationView/ConversationView";
import { ClientRole } from "@Src/hubs/chat/dtos/ClientRole";
import { voidFunction, voidPromise } from "@Utilities/Void";
import { baseImageRoute } from "../../CustomerImageRoute";
import { PersistedMessageType } from "@Src/hubs/chat/dtos/PersistedMessageType";
import { ClientMessageType } from "@Src/hubs/chat/dtos/ClientMessageType";
import withChatHubDisconnect from "../../WithChatHubDisconnect/WithChatHubDisconnect";

const FeedbackView: React.FC = ({ children }) => {
    const [isChatWindowOpen, setIsChatWindowOpen] = useState<boolean>(false);
    const isFeedbackTimeoutBannerVisible = useAppSelector(
        (state) => state.customerApp.isFeedbackTimeoutBannerVisible
    );
    const chatApi = useAppSelector((state) => state.customerApp.chatApi);
    const messages = useAppSelector((state) => state.customerApp.messages);
    const messageApi = useAppSelector((state) => state.customerApp.messageApi);
    const chatUser = useAppSelector((state) => state.customerApp.chatUser);

    let wereAnyMessagesSent = false;

    for (const message of messages) {
        if (
            message.messageType === PersistedMessageType.Text ||
            message.messageType === PersistedMessageType.BotText ||
            message.messageType === PersistedMessageType.Image ||
            message.messageType === ClientMessageType.SmartInclude ||
            message.messageType === PersistedMessageType.OrderTracking ||
            message.messageType === PersistedMessageType.TaxExemptionStatus ||
            message.messageType === PersistedMessageType.RefundReship ||
            message.messageType === PersistedMessageType.Returns ||
            message.messageType === PersistedMessageType.FoodQualityIssue
        ) {
            wereAnyMessagesSent = true;
            break;
        }
    }

    useEffect(() => {
        if (isFeedbackTimeoutBannerVisible) {
            chatApi.endChat();
        }
    }, []);

    return (
        <Space
            size={0}
            className={
                isChatWindowOpen
                    ? "feedback-view no-form-scroll"
                    : "feedback-view"
            }
            direction="vertical"
            aria-live="polite"
        >
            {isFeedbackTimeoutBannerVisible && (
                <div className="feedback-view__banner-danger">
                    Sorry, you were timed out due to inactivity.
                    <br />
                    Please refresh the window if you still need assistance.
                </div>
            )}
            {wereAnyMessagesSent && (
                <Collapse
                    className={`feedback-view__collapse ${
                        isChatWindowOpen
                            ? "feedback-view__collapse--pulsate"
                            : ""
                    }`}
                    onChange={(): void =>
                        setIsChatWindowOpen(!isChatWindowOpen)
                    }
                >
                    <Panel
                        header={
                            isChatWindowOpen
                                ? "Hide Completed Chat"
                                : "View Completed Chat"
                        }
                        key="1"
                    >
                        <ConversationView
                            chatId={""}
                            messages={messages}
                            myUserId={chatUser?.userId as string}
                            clientRole={ClientRole.Customer}
                            chatApi={chatApi}
                            messageApi={messageApi}
                            smartIncludesEnabled={false}
                            fileUploadsEnabled={false}
                            sendFileAsUser={voidPromise}
                            sendTypingIndicator={voidFunction}
                            sendStopTypingIndicator={voidFunction}
                            isMessageBarDisabled={true}
                            isChatHubConnected={false}
                            isMessageBarHidden={true}
                            typingIndicators={[]}
                            typingNotificationTimeoutTimeInSeconds={5}
                            baseImageRoute={baseImageRoute}
                            unreadMessageCountWhileScrolledUp={0}
                        />
                    </Panel>
                </Collapse>
            )}
            <Space direction="vertical" className="feedback-view__content">
                {children}
            </Space>
        </Space>
    );
};

export default withChatHubDisconnect(FeedbackView);
