import React from "react";
import { Button, Popconfirm, Space, Typography } from "antd";
import "./SubmitButtonWithPopconfirm.less";
const { Text } = Typography;

const SubmitButtonWithPopconfirm = (props: any): React.JSX.Element => {
    const {
        isSubmitting,
        isSubmitButtonPopconfirmDisabled,
        submitButtonPopconfirmOnConfirmHandler,
        submitButtonOnClickHandler,
        "data-testid": dataTestId,
    } = props;

    return (
        <Popconfirm
            title={
                <Space
                    size={0}
                    data-testid={`${dataTestId}-popconfirm-title-container`}
                >
                    <Text data-testid={`${dataTestId}-popconfirm-title-text`}>
                        Are you sure you are ready to submit?
                    </Text>
                </Space>
            }
            okText="Yes, Submit"
            cancelText="Cancel"
            disabled={isSubmitButtonPopconfirmDisabled}
            onConfirm={submitButtonPopconfirmOnConfirmHandler}
            data-testid={`${dataTestId}-popconfirm`}
        >
            <Button
                type="primary"
                loading={isSubmitting}
                onClick={submitButtonOnClickHandler}
                data-testid={`${dataTestId}-button`}
            >
                {isSubmitting ? "Submitting Items" : "Submit Items"}
            </Button>
        </Popconfirm>
    );
};

export default SubmitButtonWithPopconfirm;
