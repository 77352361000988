import { PersistedMessage } from "./PersistedMessage";

interface IncomingMessage extends Omit<ImageMessage, "timestamp"> {
    timestamp: string;
}

export class ImageMessage extends PersistedMessage {
    url: string;
    fileName: string;
    isContentInappropriate?: boolean;

    constructor(message: IncomingMessage) {
        super(message);

        this.url = message.url;
        this.fileName = message.fileName;
        this.isContentInappropriate = message.isContentInappropriate;
    }
}
