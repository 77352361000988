import { debounce } from "lodash";
import { ElementIds } from "./ElementIds";

const debouncedResetWindowScroll = debounce(() => window.scrollTo(0, 0), 400, {
    leading: false,
    trailing: true,
});

function dispatchConversationScrollEvent(): void {
    const conversationElement = document.querySelector(".conversation-view");
    if (!conversationElement) {
        console.warn("No conversation view was found.");
        return;
    }

    conversationElement.dispatchEvent(
        new CustomEvent("scroll", {
            detail: { dispatchedByViewport: true },
        })
    );
}

function setFractionalHeightInPixels(fractionalHeightValue: number): void {
    document.documentElement.style.setProperty(
        "--fractionalHeightProperty",
        fractionalHeightValue.toString() + "px"
    );
}

export function setFractionalHeightToDefault(): void {
    const defaultFractionalHeight = "1vh";

    document.documentElement.style.setProperty(
        "--fractionalHeightProperty",
        defaultFractionalHeight
    );
}

export function onWindowChange(): void {
    setFractionalHeightInPixels(window.innerHeight * 0.01);
    debouncedResetWindowScroll();
    dispatchConversationScrollEvent();
}

export function onVisualViewportChange(): void {
    if (window.visualViewport) {
        setFractionalHeightInPixels(window.visualViewport.height * 0.01);
    }
    debouncedResetWindowScroll();
    dispatchConversationScrollEvent();
}

export function handleToolbarMargin(): void {
    const keyboardRatioThreshold = 1;
    const windowAspectRatio = window.innerWidth / window.innerHeight;

    const messageInputHasFocus =
        document.activeElement ===
        document.getElementById(ElementIds.messageInput);

    const messageBarElement = document.querySelector(".message-bar");
    messageBarElement?.classList.remove("chrome-ipad-keyboard-margin");

    if (windowAspectRatio > keyboardRatioThreshold && messageInputHasFocus) {
        messageBarElement?.classList.add("chrome-ipad-keyboard-margin");
    }
}
