import receivedMessageSound from "@Audio/pop-receive.mp3";
import sentMessageSound from "@Audio/pop-send.mp3";
import newChatSound from "@Audio/bell.mp3";

const audioConfiguration: {
    [soundFile: string]: { overlapTime: number; volume: number };
} = {};
audioConfiguration[receivedMessageSound] = { overlapTime: 300, volume: 0.7 };
audioConfiguration[sentMessageSound] = { overlapTime: 300, volume: 0.7 };
audioConfiguration[newChatSound] = { overlapTime: 1000, volume: 0.7 };

const soundLastPlayedTimestamps: { [soundFile: string]: number } = {};
soundLastPlayedTimestamps[receivedMessageSound] = Date.now();
soundLastPlayedTimestamps[sentMessageSound] = Date.now();
soundLastPlayedTimestamps[newChatSound] = Date.now();

let receivedMessageSoundAudio: HTMLAudioElement;
let sentMessageSoundAudio: HTMLAudioElement;
let newChatSoundAudio: HTMLAudioElement;

const playSound = (soundFile: string, audio: HTMLAudioElement): void => {
    const currentTime = Date.now();
    const configuration = audioConfiguration[soundFile];

    if (!audio) {
        loadNotificationSoundFiles();
    }

    if (
        currentTime - soundLastPlayedTimestamps[soundFile] >
        configuration.overlapTime
    ) {
        audio.volume = configuration.volume;
        audio.play().catch(() => {
            console.log("Error playing sound.");
        });
        soundLastPlayedTimestamps[soundFile] = currentTime;
    }
};

export const playReceivedMessageSound = (): void => {
    playSound(receivedMessageSound, receivedMessageSoundAudio);
};

export const playSentMessageSound = (): void => {
    playSound(sentMessageSound, sentMessageSoundAudio);
};

export const playNewChatSound = (): void => {
    playSound(newChatSound, newChatSoundAudio);
};

export const loadNotificationSoundFiles = (): void => {
    // This is a function to load the sound files so that they can be played later.
    receivedMessageSoundAudio = new Audio(receivedMessageSound);
    receivedMessageSoundAudio.load();

    sentMessageSoundAudio = new Audio(sentMessageSound);
    sentMessageSoundAudio.load();

    newChatSoundAudio = new Audio(newChatSound);
    newChatSoundAudio.load();
};
