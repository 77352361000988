import { closeChatHubConnection } from "@Src/hubs/chat/ChatHub";
import { useEffect } from "react";

const withChatHubDisconnect = <PropType,>(
    WrappedComponent: React.ComponentType<PropType>
): React.FC<PropType> => {
    const ChatHubDisconnect: React.FC<PropType> = (props) => {
        useEffect(() => {
            closeChatHubConnection();
        }, []);
        return <WrappedComponent {...props} />;
    };
    return ChatHubDisconnect;
};

export default withChatHubDisconnect;
