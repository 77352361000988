import { AxiosInstance, AxiosResponse } from "axios";
import { SwitchboardConfig } from "@Models/SwitchboardConfig";
import { CustomerApplicationSettings } from "./../dtos/applicationSettings/CustomerApplicationSettings";
import { ApplicationSettingsSharedApi } from "@Api/ApplicationSettingsSharedApi";

export class ApplicationSettingsApi extends ApplicationSettingsSharedApi {
    constructor(config: SwitchboardConfig, axios: AxiosInstance) {
        super(config, axios);
    }

    public async getCustomerApplicationSettings(): Promise<CustomerApplicationSettings> {
        return this.axios
            .get(this.buildSwitchboardApiUrl("ApplicationSettings/forCustomer"))
            .then(
                (response: AxiosResponse<CustomerApplicationSettings>) =>
                    response.data
            );
    }
}
