import { ClientMessageType } from "./ClientMessageType";
import { PersistedMessageType } from "./PersistedMessageType";

export type RenderedMessageType = PersistedMessageType & ClientMessageType;

const conversationContentTypes = [
    PersistedMessageType.Text,
    ClientMessageType.FailedText,
    PersistedMessageType.BotText,
    PersistedMessageType.Image,
    ClientMessageType.SmartInclude,
    PersistedMessageType.OrderTracking,
    PersistedMessageType.TaxExemptionStatus,
    PersistedMessageType.RefundReship,
    PersistedMessageType.Returns,
    PersistedMessageType.FoodQualityIssue,
];

export const isContentType = (value: string): boolean => {
    return conversationContentTypes.findIndex((t) => t == value) > -1;
};

const conversationEventTypes = [
    ClientMessageType.ChatCreated,
    PersistedMessageType.UserJoinedChat,
    PersistedMessageType.UserLeftChat,
    PersistedMessageType.UserTransferredFromBotToRep,
    ClientMessageType.Reconnecting,
    PersistedMessageType.UserTimedOut,
];

export const isEventType = (value: string): boolean => {
    return conversationEventTypes.findIndex((t) => t == value) > -1;
};
