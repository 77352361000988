// Web worker wrapper for setInterval
// This circumvents the issue of setInterval not working in background tabs
// Source: https://stackoverflow.com/a/75828547/6849167
export class WorkerInterval {
    worker: Worker | null = null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(callback: any, interval: number) {
        const blob = new Blob([
            `setInterval(() => postMessage(0), ${interval});`,
        ]);
        const workerScript = URL.createObjectURL(blob);
        this.worker = new Worker(workerScript);
        this.worker.onmessage = callback;
    }

    stop(): void {
        this.worker?.terminate();
    }
}
