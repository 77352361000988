import { useAppSelector } from "@Components/CustomerApp/hooks";
import { CustomActivityMessage } from "@Src/hubs/chat/dtos/CustomActivityMessage";
import { hooks } from "botframework-webchat";
import ItemFoodQualityIssue from "./ItemFoodQualityIssue";
import {
    ItemFoodQualityIssueBaseProps,
    ItemFoodQualityIssueJsonData,
} from "./FoodQualityIssueTypes";
const { useSendMessageBack } = hooks;

interface CustomerItemFoodQualityIssueProps
    extends ItemFoodQualityIssueBaseProps {
    isCanceled: boolean;
    dboChatId?: number;
}

const CustomerItemFoodQualityIssue = ({
    chatId,
    orderInfo,
    messageId,
    resolutionsInfo,
    isCanceled,
    dboChatId,
    eligibleFoodQualityItemIssueNames,
}: CustomerItemFoodQualityIssueProps): React.JSX.Element => {
    const customerApp = useAppSelector((state) => state.customerApp);

    const message = customerApp.messages.find(
        (x) => x.id === messageId && x.chatId === chatId
    ) as CustomActivityMessage;

    // check if message exists in message state array and if so, use it since we can update it and cannot update the activities array in botframework-webchat-core
    const json: ItemFoodQualityIssueJsonData | undefined = message
        ? JSON.parse(message.jsonData)
        : undefined;

    const webchatSendMessageBack = useSendMessageBack();

    return (
        <ItemFoodQualityIssue
            chatId={chatId}
            orderInfo={json?.itemFoodQualityIssue ?? orderInfo}
            isReadOnly={
                (json?.itemFoodQualityIssueResolution?.resolutionItems
                    ?.length ?? 0) > 0
            }
            resolutionsInfo={
                json?.itemFoodQualityIssueResolution ?? resolutionsInfo
            }
            messageId={messageId}
            isCancelClicked={json?.isCanceled ?? isCanceled}
            webchatSendMessageBack={webchatSendMessageBack}
            dboChatId={dboChatId}
            eligibleFoodQualityItemIssueNames={
                eligibleFoodQualityItemIssueNames
            }
            data-testid="customer-chat-item-food-quality-issue"
        />
    );
};

export default CustomerItemFoodQualityIssue;
