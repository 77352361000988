import { BaseMessage } from "./BaseMessage";

interface IncomingMessage extends Omit<PersistedMessage, "timestamp"> {
    timestamp: string;
}

export class PersistedMessage extends BaseMessage {
    constructor(message: IncomingMessage) {
        super(
            message.id,
            message.chatId,
            message.timestamp,
            message.senderName,
            message.senderUserId,
            message.clientRole,
            message.messageType
        );
    }
}
