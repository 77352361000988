import "./PlatinumUnavailableView.less";
import { Button, Typography, Space } from "antd";
const { Text } = Typography;
import withChatHubDisconnect from "../WithChatHubDisconnect/WithChatHubDisconnect";
import { customerServiceDeptName } from "@Src/tenantConfiguration/SharedTenantConfiguration";

const PlatinumUnavailableView: React.FC = () => {
    return (
        <div
            className="platinum-unavailable-view"
            data-testid="platinum-unavailable-view"
        >
            <div
                className="platinum-unavailable-view__content"
                data-testid="platinum-unavailable-view__content"
            >
                <Text data-testid="platinum-offline-text">
                    <span
                        className="ant-typography"
                        style={{ fontWeight: "bold" }}
                    >
                        Our {customerServiceDeptName} department is currently
                        offline
                    </span>
                </Text>
                <br style={{ height: "0.5rem" }} />
                <Text>
                    <span className="ant-typography">Our hours are:</span>
                    <br />
                    <span className="ant-typography">
                        Monday - Thursday 5AM - 12AM EST
                        <br />
                        Friday 5AM - 8PM EST
                        <br />
                        Saturday & Sunday 9AM - 4PM EST
                    </span>
                </Text>
                <Space
                    className="platinum-unavailable-view__content__buttons"
                    data-testid="platinum-buttton-space"
                >
                    <Button
                        type="primary"
                        href={"mailto:platinum@webstaurantstore.com"}
                        target="_blank"
                        data-testid="platinum-email-button"
                    >
                        Email {customerServiceDeptName}
                    </Button>
                </Space>
            </div>
        </div>
    );
};

export default withChatHubDisconnect(PlatinumUnavailableView);
