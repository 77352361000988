import { useAppSelector } from "@Components/OperatorApp/hooks";
import SmartIncludeButton from "../SmartIncludeButton/SmartIncludeButton";

interface SmartOrderLinkProps {
    orderNumber: string;
    isMineOrOperatorSeeingBot: boolean;
}

const SmartOrderLink = ({
    orderNumber,
    isMineOrOperatorSeeingBot,
}: SmartOrderLinkProps): JSX.Element => {
    const webstaurantStoreBaseUrl = useAppSelector(
        (state) => state.operatorApp.webstaurantStoreBaseUrl
    );

    return (
        <SmartIncludeButton
            buttonText="Look Up Order"
            href={`${webstaurantStoreBaseUrl}/newwebadmin/order.cfm?order=${orderNumber}`}
            isMineOrOperatorSeeingBot={isMineOrOperatorSeeingBot}
        />
    );
};

export default SmartOrderLink;
