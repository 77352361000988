import dayjs from "dayjs";
import { TrackOrderDataGroupedByStatus } from "../TrackOrderDataGroupedByStatus";
import {
    getStatusStepIndex,
    formatEstimatedDate,
} from "../TrackOrderTextBubble";

interface TrackOrderTextBubbleProps {
    data: TrackOrderDataGroupedByStatus;
}

const TrackOrderTextBubbleForScreenReader = ({
    data,
}: TrackOrderTextBubbleProps): JSX.Element => {
    const trackingTabAnchor = "#orderTracking";

    const formatEstimatedDeliveryDate = (date: string): string => {
        const formattedDate = formatEstimatedDate(date);
        if (formattedDate.includes("-")) {
            return formattedDate.replace("-", "to");
        }
        return formattedDate;
    };

    return (
        <article>
            <p>
                Status for Order #
                {data.StatusGroups[0].ShipmentGroups[0].OrderNumber}
            </p>
            {data.StatusGroups.map((statusGroup) => (
                <>
                    <p>
                        {statusGroup.ShipmentGroups.length} shipment groups are
                        in status {statusGroup.Status}.
                    </p>
                    {statusGroup.ShipmentGroups.map((shipmentGroup) => (
                        <>
                            {getStatusStepIndex(shipmentGroup.Status) >= 0 && (
                                <p>
                                    Order was placed and began processing on{" "}
                                    {dayjs(shipmentGroup.CreatedDate).format(
                                        "ddd, MMM DD"
                                    )}
                                    .
                                </p>
                            )}
                            {getStatusStepIndex(shipmentGroup.Status) >= 1 && (
                                <p>
                                    Items in this shipment group were shipped on{" "}
                                    {shipmentGroup.ShippingDetails?.ShippedDate
                                        ? dayjs(
                                              shipmentGroup.ShippingDetails
                                                  ?.ShippedDate
                                          ).format("ddd, MMM DD")
                                        : "Date Unavailable"}
                                    .
                                </p>
                            )}
                            {getStatusStepIndex(shipmentGroup.Status) >= 2 && (
                                <p>
                                    Items in this shipment group were delivered
                                    on{" "}
                                    {shipmentGroup.DeliveryDetails
                                        ?.DeliveredDate
                                        ? dayjs(
                                              shipmentGroup.DeliveryDetails
                                                  ?.DeliveredDate
                                          ).format("ddd, MMM DD")
                                        : "Date Unavailable"}
                                    .
                                </p>
                            )}
                            <ul
                                role="group"
                                aria-label={
                                    shipmentGroup.ShippingDetails
                                        ?.TrackingNumbers?.length ?? 0 >= 1
                                        ? "Collapsible list of tracking numbers for this grouping."
                                        : "No tracking numbers available for this grouping."
                                }
                            >
                                {shipmentGroup.ShippingDetails?.TrackingNumbers?.map(
                                    (trackingNumber, index) => (
                                        <li key={index}>
                                            {trackingNumber && (
                                                <a
                                                    href={
                                                        trackingNumber.TrackingUrl
                                                    }
                                                >
                                                    Tracking #{" "}
                                                    {
                                                        trackingNumber.TrackingNumber
                                                    }
                                                    .
                                                </a>
                                            )}
                                        </li>
                                    )
                                )}
                            </ul>
                            {shipmentGroup.ShippingDetails
                                ?.IsLimitingTrackingNumbersShown && (
                                <p>{`Only ${shipmentGroup.ShippingDetails?.TrackingNumbers?.length} tracking numbers shown`}</p>
                            )}
                            <a
                                href={`data.OrderDetailsUrl${trackingTabAnchor}`}
                            >
                                Login to your account to see all tracking
                                numbers for the order.
                            </a>
                            <ul
                                role="group"
                                aria-label="Collapsible list of items with quantities contained in this group."
                            >
                                <p>
                                    {shipmentGroup.TotalItemsInGroup} items are
                                    in this shipment group.
                                </p>
                                {shipmentGroup.Items.map((item, index) => (
                                    <li key={index}>
                                        Quantity {item.Quantity} of{" "}
                                        {item.ItemDescription}, item number{" "}
                                        {item.ItemNumber}, price{" "}
                                        {new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                        }).format(item.Price)}
                                        {statusGroup.Status == "Processing" &&
                                            item.EstimatedShipDate &&
                                            `, Estimated ship date of 
                                                    ${formatEstimatedDate(
                                                        item.EstimatedShipDate
                                                    )}`}
                                        {statusGroup.Status == "Processing" &&
                                            item.EstimatedDeliveryDate &&
                                            `, Estimated delivery date of 
                                                    ${formatEstimatedDeliveryDate(
                                                        item.EstimatedDeliveryDate
                                                    )}`}
                                        .
                                    </li>
                                ))}
                            </ul>
                            {shipmentGroup.IsLimitingItemsShown && (
                                <p>{`Only ${shipmentGroup.Items.length} items shown`}</p>
                            )}
                            <a href={shipmentGroup.OrderDetailsUrl}>
                                Login to your account to see all items in the
                                order.
                            </a>
                        </>
                    ))}
                </>
            ))}
        </article>
    );
};

export default TrackOrderTextBubbleForScreenReader;
