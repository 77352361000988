import { PersistedMessage } from "./PersistedMessage";

interface IncomingMessage extends Omit<PersistedMessage, "timestamp"> {
    timestamp: string;
    leaveButtonPressed: boolean;
    endChatButtonPressed: boolean;
    shouldHide: boolean;
}

export class UserLeftChatMessage extends PersistedMessage {
    leaveButtonPressed?: boolean;
    endChatButtonPressed?: boolean;
    shouldHide?: boolean;
    constructor(message: IncomingMessage) {
        super(message);
        this.leaveButtonPressed = message.leaveButtonPressed;
        this.endChatButtonPressed = message.endChatButtonPressed;
        this.shouldHide = message.shouldHide;
    }
}
