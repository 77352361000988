import "./ParkedView.less";
import { useState, useEffect } from "react";
import { Spin, Space, Typography, Button, Layout } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import MessageBar from "@Components/shared/MessageBar/MessageBar";
import { useAppSelector } from "@Components/CustomerApp/hooks";
import {
    faqLink,
    helpEmail,
    faqButtonText,
    showHelpEmailButton,
} from "@Src/tenantConfiguration/CustomerTenantConfiguration";
import { customerServiceDeptName } from "@Src/tenantConfiguration/SharedTenantConfiguration";

const { Text } = Typography;
const { Footer, Content } = Layout;
interface ParkedViewProps {
    fileUploadsEnabled: boolean;
}

const ParkedView = ({ fileUploadsEnabled }: ParkedViewProps): JSX.Element => {
    const [defaultResponses] = useState<string[]>([
        "It's almost your turn...",
        "Please wait while we connect you to our next available customer service specialist.",
        "Thanks for waiting.",
        "We'll be with you shortly.",
    ]);
    const [responseCounter, setResponseCounter] = useState<number>(0);
    const [
        shouldShowMobileNavigationMessage,
        setShouldShowMobileNavigationMessage,
    ] = useState<boolean>(false);
    const isMobile = useAppSelector(
        (state) => state.customerApp?.chat?.isMobile ?? false
    );
    useEffect(() => {
        if (isMobile) {
            window.setTimeout(() => {
                setShouldShowMobileNavigationMessage(true);
            }, 15000);
        }
        const responseInterval = window.setInterval(() => {
            if (responseCounter < defaultResponses.length - 1) {
                setResponseCounter(responseCounter + 1);
            } else {
                setResponseCounter(0);
            }
        }, 5000);

        return (): void => clearInterval(responseInterval);
    });

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const returnEmptyPromise = (): Promise<void> => {
        return new Promise<void>(() => {
            return;
        });
    };

    return (
        <Layout className="parked-view">
            <Content className="parked-view__content">
                <Spin indicator={antIcon} />
                <Text>{defaultResponses[responseCounter]}</Text>
                {shouldShowMobileNavigationMessage && (
                    <>
                        <Text className="parked-view__spaced-text">
                            Please stay on this page while waiting for the next
                            representative.
                        </Text>
                        <Text>Navigating away may extend your wait time.</Text>
                    </>
                )}
                <Space className="parked-view__buttons">
                    {showHelpEmailButton && (
                        <Button
                            type="primary"
                            href={`mailto:${helpEmail}`}
                            target="_blank"
                        >
                            Email {customerServiceDeptName}
                        </Button>
                    )}
                    {faqLink && (
                        <Button type="primary" href={faqLink} target="_blank">
                            {faqButtonText}
                        </Button>
                    )}
                </Space>
            </Content>
            <Footer className="parked-view__footer">
                <MessageBar
                    chatId=""
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    sendMessageAsUser={(message): void => {
                        return;
                    }}
                    sendFileAsUser={returnEmptyPromise}
                    sendTypingIndicator={returnEmptyPromise}
                    sendStopTypingIndicator={returnEmptyPromise}
                    isDisabled={true}
                    fileUploadsEnabled={fileUploadsEnabled}
                    typingNotificationTimeoutTimeInSeconds={5}
                    isOperator={false}
                    isSoftDisabled={false}
                    softDisabledErrorMessage=""
                    isBotMessageBar={false}
                />
            </Footer>
        </Layout>
    );
};

export default ParkedView;
