import "./ExistingTabView.less";
import withChatHubDisconnect from "../WithChatHubDisconnect/WithChatHubDisconnect";

const ExistingTabView = (): JSX.Element => {
    return (
        <div className="existing-tab">
            <p>
                Sorry, we&apos;re only allowing one chat per customer at this
                moment.
            </p>
        </div>
    );
};

export default withChatHubDisconnect(ExistingTabView);
