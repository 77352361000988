import { UserLeftChatMessage } from "@Src/hubs/chat/dtos/UserLeftChatMessage";
import { UserJoinedChatMessage } from "@Src/hubs/chat/dtos/UserJoinedChatMessage";
import { store } from "@Components/CustomerApp/store";
import { leaveMessageTimedOut } from "@Components/CustomerApp/customerAppSlice";
import { PersistedMessageType } from "@Src/hubs/chat/dtos/PersistedMessageType";
import { ClientRole } from "@Src/hubs/chat/dtos/ClientRole";

/**
 * This service is responsible determining whether a new join/leave event should be hidden until
 * enough time has passed since the left event occurred, then it will trigger the message to be shown.
 *
 * This prevents customers from seeing immediately when an operator has left the chat.
 */

const shouldIgnoreNextJoinMessageLookup: { [senderUserId: string]: boolean } =
    {};

const pendingOperatorLeftChatMessages: {
    [senderUserId: string]: UserLeftChatMessage[];
} = {};

const showMessageTimeouts: { [senderUserId: string]: number } = {};

let LeaveMessageDelayInSeconds = 60;
let leaveMessageDelayInMilliseconds = LeaveMessageDelayInSeconds * 1000;

export function setMessageDelay(delay: number): void {
    if (delay > 0) {
        LeaveMessageDelayInSeconds = delay;
        leaveMessageDelayInMilliseconds = LeaveMessageDelayInSeconds * 1000;
    }
}

export function getMessageDelayInSeconds(): number {
    return LeaveMessageDelayInSeconds;
}

/**
 * All new operator left messages are ignored customer-side, start the timeouts to be shown later.
 */
export function onNewOperatorLeftMessage(message: UserLeftChatMessage): void {
    if (message.clientRole === ClientRole.Customer) {
        return;
    }

    if (message.leaveButtonPressed) {
        store.dispatch(leaveMessageTimedOut(message));
        return;
    }

    const userId = message.senderUserId;

    if (!pendingOperatorLeftChatMessages[userId]) {
        pendingOperatorLeftChatMessages[userId] = [];
    }

    pendingOperatorLeftChatMessages[userId].push(message);

    if (!showMessageTimeouts[userId]) {
        shouldIgnoreNextJoinMessageLookup[userId] = false;

        showMessageTimeouts[userId] = window.setTimeout(() => {
            const leftMessages = pendingOperatorLeftChatMessages[userId];

            shouldIgnoreNextJoinMessageLookup[userId] = leftMessages.length > 1;

            pendingOperatorLeftChatMessages[userId] = [];

            delete showMessageTimeouts[userId];

            store.dispatch(
                leaveMessageTimedOut(leftMessages[leftMessages.length - 1])
            );
        }, getNonNullDelayMilliseconds(store.getState()?.customerApp?.applicationSettings?.operatorJoinLeftDelaySeconds) - (Date.now() - message.timestamp.getTime()));
    }
}

export function getNonNullDelayMilliseconds(
    applicationSettingsDelaySeconds: number | undefined
): number {
    const isDelaySecondsNullOrZero =
        applicationSettingsDelaySeconds == undefined ||
        applicationSettingsDelaySeconds <= 0;

    return isDelaySecondsNullOrZero
        ? leaveMessageDelayInMilliseconds
        : applicationSettingsDelaySeconds * 1000;
}

/**
 * Check each new operator join message that comes in not via the backfill.
 * Returns false if we should not show this new operator join message.
 * So true means fine to show
 */
export function onNewOperatorJoinMessage(
    joinMessage: UserJoinedChatMessage
): boolean {
    if (joinMessage.clientRole === ClientRole.Customer) {
        return true;
    }

    const userId = joinMessage.senderUserId;

    const shouldIgnoreNextMessage =
        shouldIgnoreNextJoinMessageLookup[userId] ?? false;

    if (shouldIgnoreNextMessage) {
        return false;
    }

    const leftChatMessages = pendingOperatorLeftChatMessages[userId] ?? [];

    if (
        leftChatMessages.findIndex(
            (leftMessage) =>
                joinMessage.timestamp.getTime() -
                    leftMessage.timestamp.getTime() <
                getNonNullDelayMilliseconds(
                    store.getState()?.customerApp?.applicationSettings
                        ?.operatorJoinLeftDelaySeconds
                )
        ) > -1
    ) {
        if (showMessageTimeouts[userId]) {
            pendingOperatorLeftChatMessages[userId] = [];
            window.clearTimeout(showMessageTimeouts[userId]);
            delete showMessageTimeouts[userId];
        }
        // Ignore it
        return false;
    } else {
        const messages = store.getState().customerApp.messages;

        for (let i = messages.length - 1; i >= 0; i--) {
            const message = messages[i];

            if (message.senderUserId === userId) {
                if (
                    message.messageType === PersistedMessageType.UserJoinedChat
                ) {
                    // Consecutive join message
                    return false;
                } else if (
                    message.messageType === PersistedMessageType.UserLeftChat
                ) {
                    // We've already shown the leave message, show the re-join message too
                    return true;
                }
            }
        }
    }
    return true;
}
