import React, { useState } from "react";
import { Space, Typography, Collapse, Card, Radio, Image } from "antd";
import { CustomDialogCommand } from "@Models/chatBot/CustomDialogCommand";
import { ListItemText } from "../Shared/ItemIssuesTypes";
import SelectableListItem from "../Shared/List/ListItem/SelectableListItem";
import CancelledRequestMessage from "../Shared/CancelledRequestMessage";
import SelectableList from "../Shared/List/SelectableList";
import ResolutionList from "../Shared/List/ResolutionList";
import ResolutionListItem from "../Shared/List/ListItem/ResolutionListItem";
import SubmitButtonWithPopconfirm from "../Shared/Buttons/SubmitButtonWithPopconfirm";
import ClearItemsButtonWithPopconfirm from "../Shared/Buttons/ClearItemsButtonWithPopconfirm";
import CancelButtonWithPopconfirm from "../Shared/Buttons/CancelButtonWithPopconfirm";
import MyItemIsNotListedButtonWithPopconfirm from "../Shared/Buttons/MyItemIsNotListedButtonWithPopconfirm";
import EstimatedRefundStoreCredit from "../Shared/Footers/EstimatedRefundStoreCredit";
import EstimatedRefundOriginalPayment from "../Shared/Footers/EstimatedRefundOriginalPayment";

import "./ItemReturns.less";
import DropDownSelectBox from "../Shared/DropDownSelectBox/DropDownSelectBox";
import TextArea from "antd/lib/input/TextArea";
import RestockingFeeToolTip from "../Shared/ToolTips/RestockingFeeToolTip";

import IconKeep from "@Images/icon-keep.svg";
import IconReturn from "@Images/icon-return.svg";
import IconRep from "@Images/icon-addt-info.svg";

const { Text } = Typography;
const { Panel } = Collapse;

const ItemSelectedWithZeroQuantityAlert = (): React.JSX.Element => {
    return (
        <Card
            className="ant-card-error"
            data-testid="item-return-zero-quantity-alert-card"
        >
            <Text data-testid="item-return-zero-quantity-alert-text">
                Please enter the quantity of the item above
            </Text>
        </Card>
    );
};

const ItemSelectedWithInvalidReturnReasonAlert = (): React.JSX.Element => {
    return (
        <Card
            className="ant-card-error"
            data-testid="item-return-invalid-return-reason-alert-card"
        >
            <Text data-testid="item-return-invalid-return-reason-alert-text">
                Please select return reason and enter comments for all items.
            </Text>
        </Card>
    );
};

interface ReturnReason {
    returnReasonIdentifier: number;
    reason: string;
}

interface ItemInfo {
    itemNumber: string;
    clarkitbizItemNumberId: number;
    clarkitbizOrderItemId: number;
    itemDescription: string;
    itemThumbnailUrl: string;
    storeUrl: string;
    quantity: number;
    price: number;
    resolutions: string[];
    unitOfMeasure: string;
    isKeepItemEligible: boolean;
}

interface ResolutionItemInfo extends ItemInfo {
    resolutionQuantity: number;
    returnReasonIdentifier: number;
    returnComments: string;
}
interface OrderInfo {
    orderNumber: string;
    items: ItemInfo[];
    issue: string;
}

interface ResolutionInfo {
    orderNumber: string;
    items: ResolutionItemInfo[];
    issue: string;
    totalPrice: number;
    resolutionTypeName: string;
}

interface ItemReturnsProps {
    chatId: string | undefined;
    messageId: string | undefined;
    dboChatId: number | undefined;
    orderInfo: OrderInfo;
    isReadOnly: boolean;
    resolutionsInfo: ResolutionInfo[] | undefined;
    isCancelClicked: boolean;
    isItemNotListedClicked: boolean;
    webchatSendMessageBack: any;
    validReturnReasons: ReturnReason[];
    restockingFeePercentage: number;
}

const ItemReturns = ({
    chatId,
    messageId,
    dboChatId,
    orderInfo,
    isReadOnly,
    resolutionsInfo,
    isCancelClicked,
    isItemNotListedClicked,
    webchatSendMessageBack,
    validReturnReasons,
    restockingFeePercentage,
}: ItemReturnsProps): React.JSX.Element => {
    const [readOnlyResolutionList, setReadOnlyResolutionList] = useState<
        ResolutionInfo[]
    >(
        resolutionsInfo && Array.isArray(resolutionsInfo)
            ? resolutionsInfo.map((resolution: ResolutionInfo) => ({
                  ...resolution,
                  items: resolution.items.map((item: ResolutionItemInfo) => ({
                      ...item,
                      unitsPerPackaging: 1,
                  })),
              }))
            : new Array<ResolutionInfo>()
    );

    const [resolutionList, setResolutionList] = useState<ResolutionItemInfo[]>(
        []
    );

    initializeResolutionList(
        resolutionsInfo,
        readOnlyResolutionList,
        setReadOnlyResolutionList
    );

    const [itemList, setItemList] = useState([
        ...orderInfo.items.map((item: ItemInfo) => ({
            ...item,
            unitsPerPackaging: 1,
            isChecked: false,
            resolutionQuantity: 0,
            returnType: null,
            returnReasonIdentifier: null,
            returnComments: "",
        })),
    ]);
    const [resolutionTypes, setResolutionTypes] = useState<any>({
        storeCredit: true,
        originalPayment: false,
    });
    const [formButtonClicked, setFormButtonClicked] = useState<string | null>(
        null
    );
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    let totalPrice = 0;
    const costPerItem = (item: ItemInfo | ResolutionItemInfo): number =>
        item?.price;

    const returnReasons = validReturnReasons ? [...validReturnReasons] : [];

    resolutionList
        .map((item) => {
            return item.resolutionQuantity * item.price;
        })
        .forEach((num) => (totalPrice += num));

    calculateResolutionTotalPrice(readOnlyResolutionList);

    const PaymentCredit = "Payment Credit";
    const StoreCredit = "Store Credit";

    const CancelCommand =
        CustomDialogCommand.CustomDialogCommandType.ReturnCancelCommand;
    const SubmitCommand =
        CustomDialogCommand.CustomDialogCommandType.ReturnSubmitCommand;
    const ItemNotListedCommand =
        CustomDialogCommand.CustomDialogCommandType.ReturnItemNotListedCommand;

    const isRequestedResolutionValid = (resolution: string): boolean => {
        return itemList.some(
            (x) =>
                x.resolutions.some((y: string) => y === resolution) &&
                x.quantity > 0
        );
    };

    const isPaymentCreditValid = isRequestedResolutionValid(PaymentCredit);

    const isStoreCreditValid = isRequestedResolutionValid(StoreCredit);

    const resolutionTypeName = (): string | undefined => {
        switch (true) {
            case resolutionTypes.storeCredit:
                return StoreCredit;
            case resolutionTypes.originalPayment:
                return PaymentCredit;
            default:
                return undefined;
        }
    };

    initializeResolutionTypesFromResolutionInfo(
        resolutionsInfo,
        resolutionTypeName,
        PaymentCredit,
        setResolutionTypes,
        StoreCredit
    );

    const restockingFeeElement = (): React.JSX.Element | undefined => {
        return (
            <Space size={0} style={{ paddingTop: 0 }}>
                <RestockingFeeToolTip />
                <Text className="estimated-header-padding">Restocking Fee</Text>
                <Text strong>{restockingFeePercentage}%</Text>
            </Space>
        );
    };

    const readOnlyFooterDetail = (
        totalPrice: number
    ): React.JSX.Element | undefined => {
        switch (resolutionTypeName()) {
            case PaymentCredit:
                return (
                    <EstimatedRefundOriginalPayment
                        totalPrice={totalPrice}
                        test-dataid="item-return-dialog-estimated-refund-original-payment"
                    />
                );
            case StoreCredit:
                return (
                    <EstimatedRefundStoreCredit
                        totalPrice={totalPrice}
                        test-dataid="item-return-dialog-estimated-refund-store-credit"
                    />
                );
            default:
                return undefined;
        }
    };

    const hasCheckedItemWithZeroQuantity = (): boolean => {
        return itemList.some(
            (item) => item.isChecked && item.resolutionQuantity === 0
        );
    };

    const hasInvalidReturnReason = (): boolean => {
        return resolutionList.some(
            (item) =>
                item.returnReasonIdentifier === 0 ||
                item.returnReasonIdentifier === null ||
                item.returnComments === "" ||
                item.returnComments === null
        );
    };

    const getListItemText = (): ListItemText => {
        return {
            QuantityHeader: (
                <>
                    Quantity to Return{" "}
                    <span className="parenthetical-text">(Required)</span>
                </>
            ),
            QuantityWarningUnitsPerPackagingEqualOne:
                "Please enter the quantity of item",
            QuantityWarningUnitsPerPackagingGreaterThanOne:
                "Please enter the quantity of item",
        };
    };

    const listItemText = getListItemText();

    const itemListRenderItem = (item: any): React.JSX.Element => (
        <SelectableListItem
            item={item}
            resolutionList={resolutionList}
            setResolutionList={setResolutionList}
            itemList={itemList}
            setItemList={setItemList}
            setFormButtonClicked={setFormButtonClicked}
            readOnly={isReadOnly}
            isSubmitting={isSubmitting}
            quantityHeader={listItemText.QuantityHeader}
            quantityWarningTextUnitsPerPackagingEqualsOne={
                listItemText.QuantityWarningUnitsPerPackagingEqualOne
            }
            quantityWarningTextUnitsPerPackagingGreaterThanOne={
                listItemText.QuantityWarningUnitsPerPackagingGreaterThanOne
            }
            allowIndividualItems={false}
            test-dataid="item-return-dialog-selectable-list-item"
            additionalCheckBoxHandlerFunction={(): void => {
                item.returnReasonIdentifier = null;
                item.returnComments = "";
                setItemList([...itemList]);
            }}
            orderInfo={orderInfo}
        />
    );

    const commentMaxLength = 100;

    const screenReaderOnlySpan = (
        currentLength: number,
        maxLength: number
    ): JSX.Element => {
        return (
            <span className="sr-only" aria-live="polite" aria-atomic="true">
                {currentLength} of {maxLength} characters used
            </span>
        );
    };

    const resolutionListRenderItem = (
        item: any,
        index: number
    ): React.JSX.Element => {
        return sharedResolutionListRenderItem(
            item,
            index,
            "resolutionItem",
            resolutionList,
            setResolutionList
        );
    };

    let activeResolutionList: ResolutionItemInfo[] = [];
    let activeResolutionIssue = "";
    const readOnlyResolutionListRenderItem = (
        item: any,
        index: number
    ): React.JSX.Element => {
        return sharedResolutionListRenderItem(
            item,
            index,
            activeResolutionIssue,
            activeResolutionList,
            null
        );
    };

    const sharedResolutionListRenderItem = (
        item: any,
        index: number,
        keyPrefix: string,
        myResolutionList: ResolutionInfo[] | ResolutionItemInfo[],
        setMyResolutionList: React.Dispatch<
            React.SetStateAction<ResolutionItemInfo[]>
        > | null
    ): React.JSX.Element => {
        return (
            <ResolutionListItem
                resolutionList={myResolutionList}
                itemList={itemList}
                item={item}
                keyValue={`${keyPrefix}-${index}`}
                index={index}
                costPerItem={costPerItem}
                isReadOnly={isReadOnly}
                isSubmitting={isSubmitting}
                setResolutionList={setMyResolutionList}
                data-testid="item-return-dialog-resolution-list-item"
                additionalCloseButtonHandlerFunction={(): void => {
                    item.returnReasonIdentifier = null;
                    item.returnComments = "";
                    setItemList([...itemList]);
                }}
                additionalItemSpaceEntry={
                    isReadOnly && (
                        <Text data-testid="item-returns-dialog-resolution-list-item-return-reason-comments-text">
                            {
                                returnReasons.find(
                                    (returnReason) =>
                                        returnReason.returnReasonIdentifier ===
                                        item.returnReasonIdentifier
                                )?.reason
                            }{" "}
                            - {item.returnComments}
                        </Text>
                    )
                }
            >
                {!isReadOnly && (
                    <DropDownSelectBox
                        className="item-resolution-list__item__return-reason"
                        label={
                            <>
                                Return Reason{" "}
                                <span className="parenthetical-text">
                                    (Required)
                                </span>
                            </>
                        }
                        options={returnReasons.map((reason: any) => ({
                            value: reason.returnReasonIdentifier,
                            label: reason.reason,
                        }))}
                        value={item.returnReasonIdentifier}
                        onChange={(value: string): void => {
                            const updatedList = [...resolutionList];
                            const valueNumber: number = +value;
                            updatedList[index].returnReasonIdentifier =
                                valueNumber;
                            setResolutionList(updatedList);
                        }}
                        placeholder="Select a return reason"
                        data-testid="item-return-dialog-resolution-list-item-return-reason"
                        isReadOnly={isReadOnly}
                        isSubmitting={isSubmitting}
                    />
                )}
                {!isReadOnly && (
                    <div data-testid="item-returns-dialog-resolution-list-item-comments-container">
                        <Text data-testid="item-returns-dialog-resolution-list-item-comments-text">
                            Comments{" "}
                            <span className="parenthetical-text">
                                (Required)
                            </span>
                        </Text>
                        <TextArea
                            placeholder="Please provide a reason for the return"
                            maxLength={commentMaxLength}
                            data-testid="item-returns-dialog-resolution-list-item-comments-input"
                            showCount
                            value={item.returnComments}
                            onChange={(e): void => {
                                const updatedList = [...resolutionList];
                                updatedList[index].returnComments =
                                    e.target.value;
                                setResolutionList(updatedList);
                            }}
                            disabled={isSubmitting}
                        />
                        {screenReaderOnlySpan(
                            item.returnComments.length,
                            commentMaxLength
                        )}
                    </div>
                )}
            </ResolutionListItem>
        );
    };

    const storeCreditOnClickHandler = (): void => {
        setResolutionTypes({
            storeCredit: isStoreCreditValid,
            originalPayment: false,
        });
    };

    const originalPaymentOnClickHandler = (): void => {
        setResolutionTypes({
            storeCredit: false,
            originalPayment: isPaymentCreditValid,
        });
    };

    const originalPaymentRadioButton = (
        <Radio
            value="originalPayment"
            onClick={originalPaymentOnClickHandler}
            checked={resolutionTypes.originalPayment}
            disabled={isSubmitting}
            data-testid="original-payment-radio-button"
        >
            Original Payment
        </Radio>
    );

    const storeCreditRadioButton = (
        <Radio
            value="storeCredit"
            onClick={storeCreditOnClickHandler}
            checked={resolutionTypes.storeCredit}
            disabled={isSubmitting}
            data-testid="store-credit-radio-button"
        >
            Store Credit{" "}
            <span className="parenthetical-text">(No Restocking Fee)</span>
        </Radio>
    );

    const clearItemsButtonPopconfirmOnConfirmHandler = (): void => {
        const updatedList = itemList.map((listItem) => {
            return {
                ...listItem,
                isChecked: false,
                resolutionQuantity: 0,
            };
        });

        setResolutionTypes({
            storeCredit: true,
            originalPayment: false,
        });
        setFormButtonClicked(null);

        setItemList([...updatedList]);
        setResolutionList([]);
    };

    const submitButtonOnClickHandler = (): void => {
        if (
            resolutionList.length === 0 ||
            (resolutionTypes.storeCredit === false &&
                resolutionTypes.originalPayment === false) ||
            hasCheckedItemWithZeroQuantity() ||
            hasInvalidReturnReason()
        ) {
            setFormButtonClicked("submit");
        }
    };

    const clearItemsButtonOnClickHandler = (): void => {
        if (
            resolutionList.length === 0 &&
            !itemList.find((item) => item.isChecked === true)
        ) {
            setFormButtonClicked("clear");
        }
    };

    const cancelButtonPopconfirmOnConfirmHandler = (): void => {
        webchatSendMessageBack(
            {
                command: CancelCommand,
                payload: {
                    messageId: messageId,
                    chatId: chatId,
                },
            },
            ""
        );
    };

    const myItemIsNotListedButtonPopconfirmOnConfirmHandler = (): void => {
        webchatSendMessageBack(
            {
                command: ItemNotListedCommand,
                payload: {
                    messageId: messageId,
                    chatId: chatId,
                },
            },
            ""
        );
    };

    const submitButtonPopconfirmOnConfirmHandler = (): void => {
        setIsSubmitting(true);
        setFormButtonClicked(null);
        webchatSendMessageBack(
            {
                command: SubmitCommand,
                payload: {
                    messageId: messageId,
                    chatId: chatId,
                    dboChatId: dboChatId,
                    resolutions: [
                        {
                            orderNumber: orderInfo.orderNumber,
                            itemIssueName: orderInfo.issue,
                            resolutionTypeName: resolutionTypeName(),
                            resolutionItems: resolutionList.map(
                                (item: any) => ({
                                    clarkitbizItemNumberId:
                                        item.clarkitbizItemNumberId,
                                    quantity: item.resolutionQuantity,
                                    clarkitbizOrderItemId:
                                        item.clarkitbizOrderItemId,
                                    resolutionPrice: item.price,
                                    returnReasonIdentifier:
                                        item.returnReasonIdentifier,
                                    returnComments: item.returnComments,
                                    isKeepItemEligible: item.isKeepItemEligible,
                                })
                            ),
                            relatedChatId: dboChatId,
                        },
                    ],
                },
            },
            ""
        );
    };

    const isSubmitButtonPopconfirmDisabled =
        isSubmitting ||
        resolutionList.length === 0 ||
        (!resolutionTypes.storeCredit && !resolutionTypes.originalPayment) ||
        hasCheckedItemWithZeroQuantity() ||
        hasInvalidReturnReason();

    const shouldShowZeroQuantityAlert =
        !isReadOnly &&
        hasCheckedItemWithZeroQuantity() &&
        resolutionList.length > 0;

    const shouldShowInvalidReturnReasonAlert =
        !isReadOnly &&
        hasInvalidReturnReason() &&
        resolutionList.length > 0 &&
        formButtonClicked === "submit";

    const showRefundTypeRadioButtons = (
        <Space
            direction="vertical"
            size={4}
            style={{ marginLeft: "16px" }}
            data-testid="refund-radio-buttons-container"
        >
            {isStoreCreditValid && storeCreditRadioButton}
            {isPaymentCreditValid && originalPaymentRadioButton}
        </Space>
    );

    const showSelectResolutionItemsErrorCard = (
        <Card
            className={`${
                resolutionList.length === 0 &&
                formButtonClicked !== null &&
                "ant-card-error"
            }`}
            data-testid="select-resolution-items-error-card"
        >
            {!isReadOnly && (
                <Text data-testid="select-resolution-items-error-card-text">
                    Please select items above to enter your preferred resolution
                    for your return.
                </Text>
            )}
        </Card>
    );

    const showResolutionRadioButtons = (
        <Space
            direction="vertical"
            size={4}
            style={{ margin: "8px 0 12px" }}
            data-testid="resolution-radio-buttons-container"
        >
            {(isPaymentCreditValid || isStoreCreditValid) &&
                showRefundTypeRadioButtons}
        </Space>
    );

    const shouldShowResolutionSelectionMessage =
        !isReadOnly &&
        formButtonClicked === "submit" &&
        resolutionList.length > 0 &&
        resolutionTypeName() === undefined;

    const getResolutionIdNameFromIssue = (issue: string): string => {
        return issue ? issue.toLowerCase().replaceAll(" ", "-") : issue;
    };

    const keepItem = "Keep Item";
    const standardReturn = "Standard Return";

    const resolutionHeader = (
        resolution: ResolutionInfo
    ): React.JSX.Element => {
        let src = IconRep;
        let title =
            "A Customer Solutions Specialist will need to assist you with this return";
        let message =
            "We are transferring you to a Customer Solutions Specialist to work with you to find the best resolution for these items.";

        if (resolution.issue === keepItem) {
            src = IconKeep;
            title = "Keep, donate or discard the following items";
            message =
                "You will be refunded for these items in full, please do not ship them back to us.";
        } else if (resolution.issue === standardReturn) {
            src = IconReturn;
            title = "Return the following items to us";
            message =
                "These items will need to be shipped back to us. You will be refunded after they have been received and inspected.";
        }

        return (
            <div
                className="item-resolution-list__item__resolution-header"
                data-testid={`resolution-header-${getResolutionIdNameFromIssue(
                    resolution.issue
                )}`}
            >
                <Image src={src} preview={false} />
                <div className="item-resolution-list__item__resolution-header__text">
                    <Text
                        strong
                        data-testid="item-return-resolution-header-title-text"
                        className="item-resolution-list__item__resolution-header__title-text"
                    >
                        {title}
                    </Text>
                    <Text
                        data-testid="item-return-resolution-header-message-text"
                        className="item-resolution-list__item__resolution-header__message-text"
                    >
                        {message}
                    </Text>
                </div>
            </div>
        );
    };

    const returnsUI = (
        <Space
            size={0}
            className="item-return"
            data-testid="item-return-container"
        >
            {!isReadOnly && (
                <Text>
                    Below is the list of items from your order. Please check the
                    item(s) below, select the reason for return, and submit your
                    request for a return.
                </Text>
            )}
            <Text
                strong
                style={{ margin: "16px 0", display: "inline-block" }}
                data-testid="item-return-order-number"
            >
                Order # {orderInfo.orderNumber}
            </Text>
            {!isReadOnly && (
                <Collapse
                    defaultActiveKey={["1"]}
                    className="item-return__collapse"
                >
                    <Panel
                        header={`${itemList.length} Item(s) in Order`}
                        key="1"
                    >
                        <SelectableList
                            itemList={itemList}
                            itemListRenderItem={itemListRenderItem}
                            test-dataid="item-return-dialog-selectable-list"
                        />
                    </Panel>
                </Collapse>
            )}
            {!isReadOnly && (
                <Card
                    className="selected-items"
                    data-testid="selected-items-card"
                    key="selected-items-card"
                >
                    <Text strong data-testid="selected-items-card-title">
                        Items & Preferred Resolution
                    </Text>
                    {resolutionList.length < 1 ? (
                        showSelectResolutionItemsErrorCard
                    ) : (
                        <ResolutionList
                            resolutionList={resolutionList}
                            totalPrice={totalPrice}
                            isReadOnly={isReadOnly}
                            readOnlyFooterDetail={readOnlyFooterDetail(
                                totalPrice
                            )}
                            resolutionListRenderItem={resolutionListRenderItem}
                            test-dataid="item-return-dialog-resolution-list"
                            additionalFooter={restockingFeeElement}
                            keyValue="resolution-list-entry"
                        />
                    )}
                    {shouldShowZeroQuantityAlert && (
                        <ItemSelectedWithZeroQuantityAlert />
                    )}
                    {shouldShowInvalidReturnReasonAlert && (
                        <ItemSelectedWithInvalidReturnReasonAlert />
                    )}
                    <Text
                        style={{ display: "block" }}
                        data-testid="item-return-resolution-preference-text"
                    >
                        Select your resolution preference.{" "}
                        <span className="parenthetical-text">(Required)</span>
                    </Text>

                    {showResolutionRadioButtons}
                    {shouldShowResolutionSelectionMessage && (
                        <Text
                            type="danger"
                            style={{ marginBottom: "12px" }}
                            data-testid="item-return-resolution-preference-warning-text"
                        >
                            Please select your resolution preference.
                        </Text>
                    )}
                    <ClearItemsButtonWithPopconfirm
                        isSubmitting={isSubmitting}
                        clearItemsButtonPopconfirmOnConfirmHandler={
                            clearItemsButtonPopconfirmOnConfirmHandler
                        }
                        clearItemsButtonOnClickHandler={
                            clearItemsButtonOnClickHandler
                        }
                        itemList={itemList}
                        isReadOnly={isReadOnly}
                        data-testid="item-return-dialog-clear-items-button"
                    />
                    <CancelButtonWithPopconfirm
                        isSubmitting={isSubmitting}
                        cancelButtonPopconfirmOnConfirmHandler={
                            cancelButtonPopconfirmOnConfirmHandler
                        }
                        data-testid="item-return-dialog-cancel-button"
                        className="item-return-dialog-cancel"
                    />
                    <MyItemIsNotListedButtonWithPopconfirm
                        isSubmitting={isSubmitting}
                        myItemIsNotListedButtonPopconfirmOnConfirmHandler={
                            myItemIsNotListedButtonPopconfirmOnConfirmHandler
                        }
                        data-testid="item-return-dialog-item-not-listed-button"
                        styleMargin="0 0 12px"
                    />
                    <SubmitButtonWithPopconfirm
                        isSubmitting={isSubmitting}
                        isSubmitButtonPopconfirmDisabled={
                            isSubmitButtonPopconfirmDisabled
                        }
                        submitButtonPopconfirmOnConfirmHandler={
                            submitButtonPopconfirmOnConfirmHandler
                        }
                        submitButtonOnClickHandler={submitButtonOnClickHandler}
                        data-testid="item-return-dialog-submit-button"
                    />
                </Card>
            )}
            {isReadOnly &&
                readOnlyResolutionList.length > 0 &&
                readOnlyResolutionList[0].items?.length > 0 && (
                    <Card
                        className="resolution-items-wrapper"
                        data-testid="resolution-wrapper-card"
                        key="resolution-wrapper-card"
                    >
                        {readOnlyResolutionList.map(
                            (resolution: ResolutionInfo, index: number) => {
                                activeResolutionIssue = resolution.issue
                                    .replaceAll(" ", "-")
                                    .toLowerCase();
                                activeResolutionList = [...resolution.items];
                                totalPrice = resolution.totalPrice;
                                return (
                                    <>
                                        <Card
                                            className="resolution-items"
                                            data-testid={`resolution-${getResolutionIdNameFromIssue(
                                                resolution.issue
                                            )}items-card`}
                                            key={`resolution-${getResolutionIdNameFromIssue(
                                                resolution.issue
                                            )}`}
                                        >
                                            {resolutionHeader(resolution)}
                                            <ResolutionList
                                                resolutionList={
                                                    activeResolutionList
                                                }
                                                totalPrice={totalPrice}
                                                isReadOnly={isReadOnly}
                                                readOnlyFooterDetail={readOnlyFooterDetail(
                                                    totalPrice
                                                )}
                                                resolutionListRenderItem={
                                                    readOnlyResolutionListRenderItem
                                                }
                                                test-dataid="item-return-dialog-resolution-list"
                                                additionalFooter={
                                                    resolution.issue !==
                                                        keepItem &&
                                                    !(
                                                        resolution.issue ===
                                                            standardReturn &&
                                                        resolution.resolutionTypeName ===
                                                            StoreCredit
                                                    )
                                                        ? restockingFeeElement
                                                        : undefined
                                                }
                                                allowCollapse={
                                                    activeResolutionList.length >
                                                    1
                                                }
                                                collapsePanelHeader={`${activeResolutionList.length} Items`}
                                                keyValue={`resolution-${getResolutionIdNameFromIssue(
                                                    resolution.issue
                                                )}-readonly`}
                                            />
                                        </Card>
                                        {index <
                                            activeResolutionList.length - 1 && (
                                            <div
                                                className="resolution-divider"
                                                data-testid="resolution-divider"
                                            />
                                        )}
                                    </>
                                );
                            }
                        )}
                    </Card>
                )}
        </Space>
    );

    return (
        <div
            className="item-issue-return item-issue-dialog"
            data-testid="item-return-dialog"
        >
            {isCancelClicked && !isItemNotListedClicked && (
                <CancelledRequestMessage data-testid="item-return-dialog-cancelled-request-message" />
            )}
            {isCancelClicked && isItemNotListedClicked && (
                <Space
                    size={0}
                    className="item-issue"
                    data-testid="item-return-dialog-item-not-listed-request-message-space"
                >
                    <Text data-testid="item-return-dialog-item-not-listed-request-message-text">
                        My Item Is Not Listed
                    </Text>
                </Space>
            )}
            {!isCancelClicked && returnsUI}
        </div>
    );
};

function initializeResolutionTypesFromResolutionInfo(
    resolutionsInfo: ResolutionInfo[] | undefined,
    resolutionTypeName: () => string | undefined,
    PaymentCredit: string,
    setResolutionTypes: React.Dispatch<any>,
    StoreCredit: string
): void {
    if (
        resolutionsInfo &&
        resolutionsInfo.length > 0 &&
        resolutionsInfo[0].resolutionTypeName !== "" &&
        resolutionsInfo[0].resolutionTypeName !== resolutionTypeName()
    ) {
        switch (resolutionsInfo[0].resolutionTypeName) {
            case PaymentCredit:
                setResolutionTypes({
                    storeCredit: false,
                    originalPayment: true,
                });
                break;
            case StoreCredit:
                setResolutionTypes({
                    storeCredit: true,
                    originalPayment: false,
                });
                break;
            default:
                break;
        }
    }
}

function calculateResolutionTotalPrice(resolutionList: ResolutionInfo[]): void {
    if (
        resolutionList.some(
            (resolution: ResolutionInfo) => resolution.totalPrice !== undefined
        )
    ) {
        resolutionList.map((resolution) => {
            resolution.totalPrice = 0;
            resolution.items
                .map((item: { resolutionQuantity: number; price: number }) => {
                    return item.resolutionQuantity * item.price;
                })
                .forEach((num: number) => (resolution.totalPrice += num));
        });
    }
}

function initializeResolutionList(
    resolutionsInfo: ResolutionInfo[] | undefined,
    resolutionList: ResolutionInfo[],
    setResolutionList: React.Dispatch<React.SetStateAction<ResolutionInfo[]>>
): void {
    if (
        resolutionsInfo?.some(
            (resolution: ResolutionInfo) => resolution.items?.length > 0
        ) &&
        (!resolutionList.some(
            (resolution: ResolutionInfo) => resolution.totalPrice !== undefined
        ) ||
            resolutionList.length !== resolutionsInfo.length ||
            resolutionsInfo.some(
                (resolution: ResolutionInfo, index: number) =>
                    resolution.issue !== resolutionList[index]?.issue
            ))
    ) {
        setResolutionList(
            resolutionsInfo.map((resolution: ResolutionInfo) => ({
                ...resolution,
                totalPrice: 0,
                items: resolution.items.map((item: ResolutionItemInfo) => ({
                    ...item,
                    unitsPerPackaging: 1,
                })),
            }))
        );
    }
}

export default ItemReturns;
